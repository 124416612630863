import { getEnvironment } from '@chris-crap-packages/browser-utilities';
import { environmentNames, isNullOrEmptyObject, isNullOrUndefined } from '@chris-crap-packages/utilities';
import { domCookie } from 'cookie-muncher';
import { StatusCodes } from 'http-status-codes';

import { apiUri, paulBunyan } from '../Globals';

export const requestType = {
	DELETE: 'DELETE',
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
};
export const unauthenticateddMessage = 'User is not authenticated';

let _authHeader = '';
export const setAuth = (token) => {
	_authHeader = `Bearer ${token}`;
	if (getEnvironment() === environmentNames.dev) {
		const now = new Date();
		domCookie.set({ name: 'authToken', value: token }, { expires: new Date(now.getTime() + 1000 * 60 * 60) }, { path: '/' });
	}
};

let abortController = null;
export function cancelFetch() {
	if (abortController != null) {
		abortController.abort();
		abortController = null;
	}
}

export function createOnServer(uri, data, cancellable) {
	const fullUri = apiUri + uri;
	return talkToServer(requestType.POST, fullUri, cancellable, data);
}

export function deleteFromServer(uri, cancellable) {
	const fullUri = apiUri + uri;
	return talkToServer(requestType.DELETE, fullUri, cancellable);
}

export function retrieveFromServer(uri, cancellable) {
	const fullUri = apiUri + uri;
	return talkToServer(requestType.GET, fullUri, cancellable);
}

export function updateOnServer(uri, data, cancellable) {
	const fullUri = apiUri + uri;
	return talkToServer(requestType.PUT, fullUri, cancellable, data);
}

function talkToServer(method, location, cancellable, data) {
	return new Promise((resolve, reject) => {
		const fetchinOptions = { headers: new Headers({ Accept: 'application/json', authorization: _authHeader }), method };
		if (!isNullOrEmptyObject(data)) {
			fetchinOptions.headers.append('Content-type', 'application/json');
			fetchinOptions.body = JSON.stringify(data);
		}
		if (cancellable) {
			//This ends up assuming there will only be one cancellable fetch call of any method at any time - that could be bad
			//It's also a little difficult to solve, so kick it down the road until we have a use case that exposes the bad
			//What if I change cancellable=bool to abortKey and use that to store abortControllers?
			cancelFetch();
			abortController = new AbortController();
			fetchinOptions.signal = abortController.signal;
		}

		fetch(location, fetchinOptions)
			.then((response) => {
				if (response.status === StatusCodes.UNAUTHORIZED) {
					reject(unauthenticateddMessage);
				} else {
					response.json().then((result) => {
						resolve(result);
					});
				}
			})
			.catch((error) => {
				if (!isNullOrUndefined(error) && error.toString().search(/abort/i) === -1) {
					paulBunyan.logError('Failed server request', error, true);
				}
				reject();
			});
	});
}
