import { copy } from '@chris-crap-packages/utilities';
import { isNil, sortBy } from 'lodash';

import { createOnServer, deleteFromServer, retrieveFromServer, updateOnServer } from './AwCrudService';
import { baseAddWatcher, baseFireWatchers, baseRemoveWatcher } from './WatcherService';

const WATCHER_GROUP_KEY = 'accounts';

const _accounts = {};
const _getAccountsPromises = {};

function _updateAccount(updatedAccount, removedFromList, oldAccountGroupId) {
	let accountFound = false;
	const accountGroupId = oldAccountGroupId ? oldAccountGroupId : updatedAccount.accountGroupId;
	const accountList = copy(_accounts[accountGroupId] || []);
	let accountIndex = -1;

	for (let i = 0; i < accountList.length; i++) {
		if (accountList[i]._id === updatedAccount._id) {
			accountIndex = i;
			accountList[i] = updatedAccount;
			accountFound = true;
			break;
		}
	}

	if (!accountFound && !removedFromList) {
		accountList.push(updatedAccount);
	}

	if (removedFromList && accountIndex > -1) {
		accountList.splice(accountIndex, 1);
	}

	return _updateAccountList(accountGroupId, accountList);
}

function _updateAccountList(accountGroupId, accountList) {
	const originalAccounts = copy(_accounts[accountGroupId] || []);

	accountList = sortBy(accountList, ['order', 'name']);
	_accounts[accountGroupId] = accountList;

	if (JSON.stringify(originalAccounts) !== JSON.stringify(_accounts[accountGroupId])) {
		baseFireWatchers(WATCHER_GROUP_KEY);
	}

	return _accounts[accountGroupId];
}

export function addAccount(account) {
	return createOnServer('/account', account).then((accountFromServer) => {
		return _updateAccount(accountFromServer);
	});
}

export function getAccounts(accountGroupId, forceRefresh) {
	if (isNil(_accounts[accountGroupId]) || _accounts[accountGroupId].length === 0 || forceRefresh) {
		_accounts[accountGroupId] = [];
		if (isNil(_getAccountsPromises[accountGroupId])) {
			_getAccountsPromises[accountGroupId] = retrieveFromServer(`/account/${accountGroupId}`).then((serverAccounts) => {
				_accounts[accountGroupId] = serverAccounts;
				setTimeout(() => {
					_getAccountsPromises[accountGroupId] = null;
				}, 100);

				return _accounts[accountGroupId];
			});
		}

		return _getAccountsPromises[accountGroupId];
	}

	return Promise.resolve(_accounts[accountGroupId]);
}

export function getAccountInfo(accountGroupId, accountId) {
	return getAccounts(accountGroupId, true).then((retrievedAccounts) => {
		return retrievedAccounts.find((retrievedAccount) => retrievedAccount._id === accountId);
	});
}

export function updateAccounts(updatedAccounts) {
	return updateOnServer('/account/updateAll', { accounts: updatedAccounts }).then((savedAccounts) => {
		return _updateAccountList(savedAccounts[0].accountGroupId, savedAccounts);
	});
}

export async function deleteAccount(account) {
	const serverAccounts = await deleteFromServer(`/account/${account._id}`);
	const updatedAccounts = _updateAccountList(account.accountGroupId, serverAccounts);
	return Promise.resolve(updatedAccounts);
}

export function updateAccount(updatedAccount) {
	return updateOnServer('/account', updatedAccount).then((serverAccount) => {
		const updatedAccountList = _updateAccount(serverAccount, updatedAccount.accountGroupId !== updatedAccount.oldAccountGroupId, updatedAccount.oldAccountGroupId);
		return Promise.resolve(updatedAccountList);
	});
}

export function addWatcher(watcherFunction) {
	baseAddWatcher(WATCHER_GROUP_KEY, watcherFunction);
}

export function removeWatcher(key) {
	baseRemoveWatcher(WATCHER_GROUP_KEY, key);
}
