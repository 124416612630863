import { useEffect, useState } from 'react';
import PageTemplate from '../PageTemplate';
import { forceLocalHost, getBaseUri } from '@chris-crap-packages/utilities';
import { getDomain, getEnvironment } from '@chris-crap-packages/browser-utilities';

const NoSmallScreens = () => {
	const [mobileUri, setMobileUri] = useState('');

	useEffect(() => {
		const baseUri = forceLocalHost(getBaseUri('cash-flow-tool-mobile', getEnvironment(), getDomain()));
		setMobileUri(baseUri);
	}, []);

	return (
		<PageTemplate>
			<div className="text-center no-small-screens">
				<p>Rivulet Finance was designed for full-width computer screens.</p>
				<p>If you are viewing on a computer or laptop, please widen your screen beyond 1035px so that you can view the full site.</p>
				<p>
					If you are viewing from a mobile phone, please visit the <a href={mobileUri}>mobile site: {mobileUri}</a>.
				</p>
			</div>
		</PageTemplate>
	);
};

export default NoSmallScreens;
