import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

class TextWithIconComponent extends React.Component {
	static get iconPositions() {
		return {
			left: 0,
			right: 1
		};
	}

	static get iconStyles() {
		return {
			regular: 'r',
			solid: 's'
		};
	}

	render() {
		if (isNil(this.props.children)) {
			return <span className={`fa${this.props.iconStyle || 's'} fa-${this.props.iconName} with-spacing`}></span>;
		}

		if (this.props.iconPosition === TextWithIconComponent.iconPositions.right) {
			return (
				<span className='with-spacing'>
					{this.props.children} <span className={`fa${this.props.iconStyle || 's'} fa-${this.props.iconName} with-spacing`}></span>
				</span>
			);
		}

		return (
			<span className='with-spacing'>
				<span className={`fa${this.props.iconStyle || 's'} fa-${this.props.iconName} with-spacing`}></span> {this.props.children}
			</span>
		);
	}
}

TextWithIconComponent.propTypes = {
	children: PropTypes.any,
	iconName: PropTypes.string.isRequired,
	iconPosition: PropTypes.number,
	iconStyle: PropTypes.string
};

export default TextWithIconComponent;
