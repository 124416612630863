import PropTypes from 'prop-types';
import React from 'react';

import { pagingDirections } from '../../Globals';
import TextWithIconComponent from './TextWithIconComponent';

class PagingButtonsComponent extends React.Component {
	render() {
		if (this.props.direction === pagingDirections.backward) {
			if (this.props.pageNumber === 1) {
				return (
					<span className="disabled">
						<TextWithIconComponent iconName={`fast-${this.props.direction}`} />
						&nbsp;
						<TextWithIconComponent iconName={`step-${this.props.direction}`} />
					</span>
				);
			}

			const previousPage = Math.max(1, this.props.pageNumber - 1);
			return (
				<span>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							this.props.onNavigate(1);
						}}>
						{' '}
						<TextWithIconComponent iconName={`fast-${this.props.direction}`} />
					</a>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							this.props.onNavigate(previousPage);
						}}>
						{' '}
						<TextWithIconComponent iconName={`step-${this.props.direction}`} />
					</a>
				</span>
			);
		}

		if (this.props.direction === pagingDirections.forward) {
			if (this.props.pageNumber === this.props.pageCount) {
				return (
					<span className="disabled">
						<TextWithIconComponent iconName={`step-${this.props.direction}`} />
						&nbsp;
						<TextWithIconComponent iconName={`fast-${this.props.direction}`} />
					</span>
				);
			}

			const nextPage = Math.min(this.props.pageCount, this.props.pageNumber + 1);
			return (
				<span>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							this.props.onNavigate(nextPage);
						}}>
						{' '}
						<TextWithIconComponent iconName={`step-${this.props.direction}`} />
					</a>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							this.props.onNavigate(this.props.pageCount);
						}}>
						{' '}
						<TextWithIconComponent iconName={`fast-${this.props.direction}`} />
					</a>
				</span>
			);
		}
	}
}

PagingButtonsComponent.propTypes = {
	direction: PropTypes.string.isRequired,
	onNavigate: PropTypes.func.isRequired,
	pageCount: PropTypes.number.isRequired,
	pageNumber: PropTypes.number.isRequired,
};

export default PagingButtonsComponent;
