import PropTypes from 'prop-types';
import React from 'react';

import { articleSlugs } from '../TableOfContents/TableOfContents';

const SettingsAccountGroups = ({ onClickArticle }) => {
	return (
		<div>
			<h1>Account Groups</h1>
			<p>
				Account groups provide a way for you to organize your{' '}
				<a
					href="/"
					onClick={(e) => {
						e.preventDefault();
						onClickArticle(articleSlugs.settingsAccounts);
					}}
				>
					accounts
				</a>{' '}
				in a way that makes sense to you. You may have some personal accounts and some joint accounts that you want to keep separated. You may
				have fun-money accounts and bill-paying accounts that you want to keep separated. It's really up to you how to organize them.
			</p>
			<p> When deciding on how to separate your accounts into different account groups, keep in mind:</p>
			<ul>
				{/* TODO: make dashboard a link when there is a help article for it */}
				<li>Each account group will get its own tab with its own dashboard</li>
				<li>
					You can share account groups, but not individual{' '}
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							onClickArticle(articleSlugs.settingsAccounts);
						}}
					>
						accounts
					</a>
				</li>
				{/* TODO: make categories a link when there is a help article for it */}
				<li>
					Categories are associated with account groups, not individual{' '}
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							onClickArticle(articleSlugs.settingsAccounts);
						}}
					>
						accounts
					</a>
				</li>
			</ul>
		</div>
	);
};

SettingsAccountGroups.propTypes = {
	onClickArticle: PropTypes.func.isRequired,
};

export default SettingsAccountGroups;
