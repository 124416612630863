import { createSlice } from '@reduxjs/toolkit';

const activeItemsSlice = createSlice({
	name: 'activeItems',
	initialState: { accountId: null, accountGroupId: null },
	reducers: {
		accountActivated(state, action) {
			state.accountId = action.payload;
		},
		accountGroupActivated(state, action) {
			state.accountGroupId = action.payload;
		},
	},
});

export const selectActiveAccountId = (state) => {
	return state.activeItems.accountId;
};

export const selectActiveAccountGroupId = (state) => {
	return state.activeItems.accountGroupId;
};

export const { accountActivated, accountGroupActivated } = activeItemsSlice.actions;

export const activeItemsReducer = activeItemsSlice.reducer;
