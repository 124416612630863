import { copy, isNullOrEmptyObject } from '@chris-crap-packages/utilities';
import { categoryFields } from '@xenomite-packages/cash-flow-globals';
import { validateCategory } from '@xenomite-packages/cash-flow-validator';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { addCategory, deleteCategory, getCategories, updateCategory } from '../../services/CategoryService';
import { clearMessages, messageType, setMessages } from '../../services/FeedbackMessageService';
import { selectAppUser } from '../../state/userSlice';
import EditableListComponent from '../common/EditableListComponent';
import WaitingMessageComponent from '../common/WaitingMessageComponent';
import { listTypes } from '../user/settings/UserFormsSwitcherComponent';

const AdminCategoriesComponent = () => {
	const [categories, setCategories] = useState([]);
	const [editingCategory, setEditingCategory] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const user = useSelector(selectAppUser);

	const handleAddCategory = (category, onFinish) => {
		clearMessages();
		category.isDefault = true;
		const errors = validateCategory(category, user, true, true);
		if (!isNullOrEmptyObject(errors)) {
			setMessages(errors, messageType.error);
			return;
		}

		const categoryInfo = { category: category };
		addCategory(categoryFields.defaultCategories, categoryInfo, true)
			.then((savedCategory) => {
				let updatedCategories = copy(categories);
				updatedCategories.push(savedCategory);
				updatedCategories = sortBy(updatedCategories, ['name']);
				setCategories(updatedCategories);
				onFinish();
			})
			.catch((error) => {
				setMessages(error, messageType.error);
			});
	};

	const handleDeleteCategory = (categoryId) => {
		deleteCategory(categoryId).then(() => {
			let updatedCategories = categories.filter((c) => c._id !== categoryId);
			updatedCategories = sortBy(updatedCategories, ['name']);
			setCategories(updatedCategories);
			setEditingCategory(null);
		});
	};

	const handleUpdateCategory = (category) => {
		clearMessages();
		delete category.oldName;
		const errors = validateCategory(category, user, true);
		if (!isNullOrEmptyObject(errors)) {
			setMessages(errors, messageType.error);
			return;
		}

		const categoryInfo = { category: category };
		updateCategory(categoryFields.defaultCategories, categoryInfo, true)
			.then((savedCategory) => {
				let updatedCategories = copy(categories);
				const categoryIndex = updatedCategories.findIndex((c) => c._id === savedCategory._id);
				updatedCategories.splice(categoryIndex, 1, savedCategory);
				updatedCategories = sortBy(updatedCategories, ['name']);
				setCategories(updatedCategories);
				setEditingCategory(null);
				setSelectedCategory(updatedCategories[0]);
			})
			.catch((error) => {
				setMessages(error, messageType.error);
			});
	};

	useEffect(() => {
		getCategories(categoryFields.defaultCategories, null, true).then((c) => {
			setCategories(c);
			setIsLoading(false);
		});
	}, []);

	useEffect(() => {
		setSelectedCategory(categories[0]?._id);
	}, [categories]);

	if (isLoading) {
		return <WaitingMessageComponent message="Gathering categories..." />;
	}

	return (
		<div className="row">
			<div className="col-xs-12">
				<div className="panel panel-primary">
					<div className="panel-heading">Edit Categories</div>
					<div className="panel-body">
						<EditableListComponent
							itemFriendlyName="Category"
							items={categories}
							isSortable={false}
							selectedItem={selectedCategory}
							editing={editingCategory}
							listType={listTypes.category}
							onSelectItem={setSelectedCategory}
							onAddItem={handleAddCategory}
							onDeleteItem={handleDeleteCategory}
							onEditItem={setEditingCategory}
							onUpdateItem={handleUpdateCategory}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminCategoriesComponent;
