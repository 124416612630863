import { useEffect, useState } from 'react';

import { retrieveUserStats } from '../../../services/UserService';
import WaitingMessageComponent from '../../common/WaitingMessageComponent';

const UserStats = () => {
	const [userStats, setUserStats] = useState();

	useEffect(() => {
		retrieveUserStats().then((stats) => {
			setUserStats(stats);
		});
	}, []);

	if (!userStats) {
		return <WaitingMessageComponent />;
	}

	return (
		<table className="table table-striped">
			<tbody>
				<tr title="Last login <= 30 days ago">
					<td>Active:</td>
					<td>{userStats.active}</td>
				</tr>
				<tr title="Last login between 31 and 90 days ago">
					<td>Inactive:</td>
					<td>{userStats.inactive}</td>
				</tr>
				<tr title="Last login > 90 days ago (will be deleted after 365 days)">
					<td>Dormant:</td>
					<td>{userStats.dormant}</td>
				</tr>
				<tr title="All the users">
					<td className="font-italic">
						<em>Total:</em>
					</td>
					<td>
						<em>{userStats.total}</em>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

export default UserStats;
