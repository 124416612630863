import PropTypes from 'prop-types';
import React from 'react';
import { NumericFormat } from 'react-number-format';

import { accountSectionHeaderTypes } from '../../Globals';
import PagingComponent from '../common/PagingComponent';

export default function AccountSectionHeaderComponent({ balance, hasRunningBalance, headerType, onNavigate, isSearchActive, onSearch, pageCount, pageNumber }) {
	const feedbackClass = balance > 0 ? 'bg-primary' : 'bg-danger text-danger';
	const balanceType = headerType === accountSectionHeaderTypes.cleared ? 'Bank Balance:' : 'Spendable Balance:';

	return (
		<thead className={feedbackClass}>
			<tr>
				<th>
					<div className={`row`}>
						<div className="col-xs-6">{`${headerType} Transactions`}</div>
						<div className="col-xs-6 text-right">
							{balanceType}
							&nbsp;
							<NumericFormat value={balance} thousandSeparator="," decimalScale={2} fixedDecimalScale={true} prefix="$" displayType="text" />
						</div>
					</div>
					<div className="row">
						<div className="col-xs-1">Date</div>
						<div className="col-xs-3">Payee</div>
						<div className="col-xs-2">Category</div>
						<div className="col-xs-2"></div>
						<div className="col-xs-1 text-right">Amount</div>
						<div className="col-xs-2 text-right">{hasRunningBalance ? 'Balance After' : ''}</div>
						<div className="col-xs-1 text-right"></div>
					</div>
					<div className="row">
						<PagingComponent pageCount={pageCount} pageNumber={pageNumber} onNavigate={onNavigate} onSearch={onSearch} isSearchActive={isSearchActive} />
					</div>
				</th>
			</tr>
		</thead>
	);
}

AccountSectionHeaderComponent.propTypes = {
	balance: PropTypes.number.isRequired,
	hasRunningBalance: PropTypes.bool.isRequired,
	headerType: PropTypes.string.isRequired,
	isSearchActive: PropTypes.bool.isRequired,
	onNavigate: PropTypes.func.isRequired,
	onSearch: PropTypes.func.isRequired,
	pageCount: PropTypes.number.isRequired,
	pageNumber: PropTypes.number.isRequired,
};
