import { blowUpNumber, copy, implodeNumber, isNullOrEmptyString } from '@chris-crap-packages/utilities';
import { newTxId, transactionTypes } from '@xenomite-packages/cash-flow-globals';
import PropTypes from 'prop-types';
import React from 'react';

import { getAccountGroupIdFromAccountId, getAccountOptions } from '../../../../services/AccountGroupService';
import { getCategoryOptions } from '../../../../services/CategoryService';
import FormActionButtonsComponent from '../../../common/forms/FormActionButtonsComponent';
import RecurrenceInputsComponent from '../../../common/forms/RecurrenceInputsComponent';
import TransactionInputsComponent from '../../../common/forms/TransactionInputsComponent';
import WaitingMessageComponent from '../../../common/WaitingMessageComponent';

class RecurrenceEditComponent extends React.Component {
	constructor(props) {
		super(props);

		const recurrence = copy(props.recurrence);
		recurrence.transaction.amount = implodeNumber(recurrence.transaction.explodedAmount);
		this.state = {
			accountOptions: [],
			categoryOptions: [],
			datesTied: true,
			recurrence: recurrence,
			transactionType: transactionTypes.debit,
		};

		this.handleFieldChange = handleFieldChange.bind(this);
		this.handleTransactionTypeChange = handleTransactionTypeChange.bind(this);
		this.saveRecurrence = saveRecurrence.bind(this);
	}

	componentDidMount() {
		getAccountOptions().then((accountOptions) => {
			getAccountGroupIdFromAccountId(this.state.recurrence.transaction.accountId).then((accountGroupId) => {
				getCategoryOptions(accountGroupId).then((categoryOptions) => {
					this.setState({
						categoryOptions,
					});
				});
			});
			this.setState({
				accountOptions,
			});
		});
		const datesTied = this.state.recurrence.transaction.date === this.state.recurrence.recordingDate;
		if (this.state.recurrence.transaction.amount) {
			let txType = transactionTypes.debit;
			const recurrence = copy(this.state.recurrence);
			if (recurrence.transaction.amount < 0) {
				recurrence.transaction.amount = Math.abs(recurrence.transaction.amount);
			} else {
				txType = transactionTypes.credit;
			}
			this.setState({
				datesTied: datesTied,
				recurrence: recurrence,
				transactionType: txType,
			});
		}
	}

	render() {
		return (
			<form onSubmit={this.saveRecurrence} id="recurrence-form">
				<div className="row">
					<div className="col-xs-12 col-md-6 col-md-offset-3">
						<div className={`panel panel-info text-info`}>
							<div className="panel-heading">{this.props.recurrence._id === newTxId ? 'Add Recurring Transaction' : 'Edit Recurring Transaction'}</div>
							<div className="panel-body">
								{this.props.isSaving ? (
									<WaitingMessageComponent message="Saving..." />
								) : (
									<div>
										<TransactionInputsComponent
											transaction={this.state.recurrence.transaction}
											transactionType={this.state.transactionType}
											accountOptions={this.state.accountOptions}
											categoryOptions={this.state.categoryOptions}
											formErrors={this.props.formErrors}
											onFieldChange={this.handleFieldChange}
										/>
										<RecurrenceInputsComponent recurrence={this.state.recurrence} formErrors={this.props.formErrors} onFieldChange={this.handleFieldChange} />
									</div>
								)}
							</div>
							<div className="panel-footer text-right">
								{this.props.isSaving ? '' : <FormActionButtonsComponent formName="recurrence" onCancel={this.props.onCancel} />}
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

function handleFieldChange(event, field, formObjectType) {
	if (field === 'transactionType') {
		return this.handleTransactionTypeChange(event);
	}

	const recurrence = this.state.recurrence;
	if (formObjectType === 'recurrence') {
		recurrence[field] = event.target.value;
	} else {
		recurrence.transaction[field] = event.target.value;
	}

	let datesTied;
	if (this.state.datesTied) {
		if (field === 'date') {
			recurrence.recordingDate = event.target.value;
		}

		datesTied = recurrence.recordingDate === recurrence.transaction.date;
	}
	this.setState({
		datesTied: datesTied,
		recurrence: recurrence,
	});

	if (field === 'accountId') {
		getAccountGroupIdFromAccountId(this.state.recurrence.transaction.accountId).then((accountGroupId) => {
			getCategoryOptions(accountGroupId).then((categoryOptions) => {
				this.setState({
					categoryOptions,
				});
				if (this.state.categoryOptions.findIndex((option) => option.value === this.state.recurrence.transaction.categoryId) === -1) {
					this.handleFieldChange({ target: { value: '' } }, 'categoryId');
				} else {
					this.handleFieldChange({ target: { event: this.state.recurrence.transaction.categoryId } }, 'categoryId');
				}
			});
		});
	}
}

function handleTransactionTypeChange(event) {
	const transactionType = event.target.value;

	this.setState({
		transactionType: transactionType,
	});
}

function saveRecurrence(event) {
	const recurrence = copy(this.state.recurrence);

	if (!isNullOrEmptyString(recurrence.transaction.amount)) {
		recurrence.transaction.amount = Math.abs(recurrence.transaction.amount);
		if (this.state.transactionType === transactionTypes.debit) {
			recurrence.transaction.amount = -1 * recurrence.transaction.amount;
		}
	}
	recurrence.transaction.explodedAmount = blowUpNumber(recurrence.transaction.amount);
	delete recurrence.transaction.amount;
	delete recurrence.transaction._id;

	this.props.onSubmit(event, recurrence);
}

RecurrenceEditComponent.propTypes = {
	formErrors: PropTypes.object.isRequired,
	isSaving: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	recurrence: PropTypes.object.isRequired,
};

export default RecurrenceEditComponent;
