import { copy, isNullOrEmptyObject, isNullOrUndefined } from '@chris-crap-packages/utilities';
import { categoryFields } from '@xenomite-packages/cash-flow-globals';
import { validateCategory } from '@xenomite-packages/cash-flow-validator';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getAccountGroups } from '../../../../services/AccountGroupService';
import { addCategory, associateAccountGroup, disassociateAccountGroup, getCategories, updateCategory } from '../../../../services/CategoryService';
import { messageType, setMessages } from '../../../../services/FeedbackMessageService';
import { selectAppUser } from '../../../../state/userSlice';
import EditableListComponent from '../../../common/EditableListComponent';
import WaitingMessageComponent from '../../../common/WaitingMessageComponent';
import { listTypes } from '../UserFormsSwitcherComponent';

const CategoriesComponent = () => {
	const [accountGroups, setAccountGroups] = useState([]);
	const [categories, setCategories] = useState([]);
	const [editingCategory, setEditingCategory] = useState('');
	const [selectedCategory, setSelectedCategory] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const user = useSelector(selectAppUser);

	const handleToggleAccountGroup = (accountGroupId, categoryId) => {
		if (!isNullOrUndefined(categoryId)) {
			const category = categories.find((c) => c._id === categoryId);

			const accountGroupIndex = category.accountGroups.findIndex((ag) => ag._id === accountGroupId);
			if (accountGroupIndex === -1) {
				associateAccountGroup(accountGroupId, categoryId).then((updatedCategories) => {
					updateCategories(updatedCategories);
				});
			} else {
				disassociateAccountGroup(accountGroupId, categoryId).then((updatedCategories) => {
					updateCategories(updatedCategories);
				});
			}
			return;
		}

		const category = copy(editingCategory);
		const accountGroupIndex = category.accountGroups.findIndex((ag) => ag._id === accountGroupId);
		if (accountGroupIndex === -1) {
			const accountGroup = accountGroups.find((ag) => ag._id === accountGroupId);
			category.accountGroups.push(copy(accountGroup));
		} else {
			category.accountGroups.splice(accountGroupIndex, 1);
		}
		setEditingCategory(category);
	};

	const handleDeleteCategory = (categoryId) => {
		const categoryToDelete = categories.find((c) => c._id === categoryId);
		const allPromises = [];
		categoryToDelete.accountGroups.forEach((accountGroup) => {
			allPromises.push(disassociateAccountGroup(accountGroup._id, categoryId));
		});
		Promise.all(allPromises).then(() => {
			getCategories(categoryFields.userCategories).then((c) => {
				c = sortBy(c, ['name']);
				updateCategories(c);
			});
		});
	};

	const handleAddCategory = (category, onFinish) => {
		const errors = validateCategory(category, user, false, true);
		setErrors(errors);
		if (isNullOrEmptyObject(errors)) {
			addCategory(categoryFields.userCategories, { category }, false).then(() => {
				getCategories(categoryFields.userCategories).then((c) => {
					c = sortBy(c, ['name']);
					setCategories(c);
					selectCategory(c.find((c) => c.name === category.name));
					onFinish();
				});
			});
		}
	};

	const handleUpdateCategory = (category) => {
		delete category.oldName;
		const errors = validateCategory(category, user, false, false);
		setErrors(errors);
		if (isNullOrEmptyObject(errors)) {
			updateCategory(categoryFields.userCategories, { category }, false).then(() => {
				getCategories(categoryFields.userCategories).then((c) => {
					c = sortBy(c, ['name']);
					setCategories(c);
					setEditingCategory('');
					selectCategory(c.find((c) => c.name === category.name));
				});
			});
		}
	};

	const updateCategories = (updatedCategories) => {
		const updatedSelectedCategory = updatedCategories.find((c) => c._id === selectedCategory._id) || updatedCategories[0];
		setCategories(updatedCategories);
		selectCategory(updatedSelectedCategory);
	};

	const setErrors = (errors) => {
		setMessages(errors, messageType.error);
	};

	const selectCategory = (item) => {
		setSelectedCategory(item ? item._id : '');
	};

	useEffect(() => {
		const allPromises = [];
		allPromises.push(
			getCategories(categoryFields.userCategories).then((c) => {
				c = sortBy(c, ['name']);
				setCategories(c);
			})
		);
		allPromises.push(
			getAccountGroups().then((ag) => {
				setAccountGroups(ag || []);
			})
		);
		Promise.all(allPromises).then(() => {
			setTimeout(() => {
				setIsLoading(false);
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		selectCategory(categories[0]);
	}, [categories]);

	if (isLoading) {
		return <WaitingMessageComponent message="Gathering categories..." />;
	}

	return (
		<div className="row">
			<div className="col-xs-12">
				<div className="panel panel-primary">
					<div className="panel-heading">Edit Categories</div>
					<div className="panel-body">
						<EditableListComponent
							listType={listTypes.category}
							items={categories}
							accountGroups={accountGroups}
							isSortable={false}
							selectedItem={selectedCategory}
							onSelectItem={selectCategory}
							onAddItem={handleAddCategory}
							onEditItem={setEditingCategory}
							onUpdateItem={handleUpdateCategory}
							onDeleteItem={handleDeleteCategory}
							editing={editingCategory}
							onToggleAccountGroup={handleToggleAccountGroup}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CategoriesComponent;
