import React from 'react';

const WhyRivulet = () => {
	return (
		<div>
			<h1>Why would someone use Rivulet Finance?</h1>
			<p>Ever find yourself having to choose between two mandatory expenses, like putting food on the table or putting gas in the car?</p>
			<p>Ever forget an outstanding transaction and get slapped with enough NSF fees that it took a month to recover from the lost money?</p>
			<p>Ever received financial advice like this and felt it wasn&apos;t helpful?</p>
			<ul>
				<li>Live within your means.</li>
				<li>Keep $500 in your account that you never spend.</li>
				<li>Follow the 10/10/10 plan; 10% of your income to savings, 10% to investing, and 10% to charity.</li>
				<li>You need a budget you can follow.</li>
			</ul>
			<p>If you answered no to those questions, Rivulet Finance probably isn&apos;t for you. If you answered yes to any of them, read on.</p>
			<p>
				It took a long time and a lot of financial mistakes to realize that those ideas are aspirations, not actionable financial advice. The
				"secret" to achieving those aspirations is understanding two things:
			</p>
			<ol>
				<li>You can never, ever spend more than you have, even accidentally.</li>
				<li>Your lifestyle will always grow to meet or exceed your current income.</li>
			</ol>
			<p>
				There's not a lot you can do about the second secret. Even if you maintain your current lifestyle, most people get about 2% raises
				annually while inflation sits around 3%. Therefore, inflation will slowly erode your lifestyle. If you really want to solve the second
				secret, you have to constantly look for ways to cut expenses and/or increase your income.
			</p>
			<p>
				You can, and should, make the first secret a priority. There are many tools on the market, but few help with solving this secret. Some
				are designed for business and are intended for accounting rather than cash flow. Some try to make your life too easy and only show
				transactions that have already cleared the bank. Rivulet Finance was designed specifically to make it easy to track outstanding
				transactions. Maybe it's a check that has been written and subsequently forgotten. Maybe it's a recurring expense that isn't front of
				mind. Maybe it's making sure that you have covered all the bills that are due before your next paycheck. Rivulet Finance can help you
				see how much of your money it is safe to spend.
			</p>
			<p>
				The purpose of Rivulet Finance is simple. To make it as easy as possible to never, ever, ever spend money that you don't have. It's
				about eliminating the excuses for practicing poor personal finance hygiene:
			</p>
			<ul>
				<li>It is incredibly fast to record a transaction, oftentimes faster than paying the transaction.</li>
				<li>It allows you to create recurring transactions, which makes it very easy to manage your bills.</li>
				<li>We use it every day and are always adding features that make it easier to manage our financial lives.</li>
			</ul>
		</div>
	);
};

export default WhyRivulet;
