import React from 'react';

const AccountGroupTabsAccountsTransaction = () => {
	return (
		<div>
			<h1>Transaction Form</h1>
			<p>Use the transaction form to add or edit transactions in your accounts. The form contains the following fields:</p>
			<ul>
				<li>Transaction type - Whether you are withdrawing from or depositing to the account</li>
				<li>Date - the date of this transaction</li>
				<li>Payee - the other party to this transaction</li>
				<li>Amount - the amount of the transaction</li>
				<li>Description - an optional note to help you remember what the transaction was for</li>
				<li>Account - which account group / account this transaction belongs to</li>
				<li>Category - the budget category this transaction belongs to</li>
			</ul>
		</div>
	);
};

export default AccountGroupTabsAccountsTransaction;
