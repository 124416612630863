import { blowUpNumber, copy, implodeNumber, isNullOrEmptyObject } from '@chris-crap-packages/utilities';
import { calculateBankBalance, calculateSpendableBalance, generateNewTransaction, newTxId, permissionLevels, sortTransactions } from '@xenomite-packages/cash-flow-globals';
import { validateTransaction } from '@xenomite-packages/cash-flow-validator';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { accountSectionHeaderTypes } from '../../Globals';
import { withLogin } from '../../hoc/withLogin';
import { getAccountGroupPermissionLevel, getAccountGroups } from '../../services/AccountGroupService';
import { getAccountInfo } from '../../services/AccountService';
import { clearMessages, messageType, setMessages } from '../../services/FeedbackMessageService';
import { navigationOccurred, navigationTo } from '../../services/NavigationService';
import { addTransaction, deleteTransaction, getClearedTransactions, getOutstandingTransactions, updateTransaction } from '../../services/TransactionService';
import { accountActivated, accountGroupActivated, selectActiveAccountGroupId, selectActiveAccountId } from '../../state/activeItemSlice';
import { selectAppUser } from '../../state/userSlice';
import ConfirmComponent from '../common/ConfirmComponent';
import WaitingMessageComponent from '../common/WaitingMessageComponent';
import PageTemplate from '../main/PageTemplate/PageTemplate.js';
import AccountSectionHeaderComponent from './AccountSectionHeaderComponent';
import AddTransactionButtonsComponent from './AddTransactionButtonsComponent';
import TransactionDetailComponent from './TransactionDetailComponent';
import TransactionEditComponent from './TransactionEditComponent';

function AccountContainer() {
	const { accountId: paramAccountId, accountGroupId: paramAccountGroupId } = useParams();
	const [accountInfo, setAccountInfo] = useState({});
	const [bankBalance, setBankBalance] = useState(null);
	const [clearedTransactionPaging, setClearedTransactionPaging] = useState({ pageCount: 1, pageNumber: 1 });
	const [clearedTransactions, setClearedTransactions] = useState([]);
	const [clearedTransactionsLoading, setClearedTransactionsLoading] = useState(false);
	const [deleteTransactionId, setDeleteTransactionId] = useState();
	const [editingTransaction, setEditingTransaction] = useState({});
	const [editingTransactionErrors, setEditingTransactionErrors] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isSavingTransaction, setIsSavingTransaction] = useState(false);
	const [isSearchActive, setIsSearchActive] = useState({ cleared: false, outstanding: false });
	const [outstandingTransactionPaging, setOutstandingTransactionPaging] = useState({ pageCount: 1, pageNumber: 1 });
	const [outstandingTransactions, setOutstandingTransactions] = useState([]);
	const [outstandingTransactionsLoading, setOutstandingTransactionsLoading] = useState(false);
	const [permissionLevel, setPermissionLevel] = useState(0);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [spendableBalance, setSpendableBalance] = useState(0);
	const user = useSelector(selectAppUser);
	const dispatch = useDispatch();
	const accountGroupId = useSelector(selectActiveAccountGroupId);
	const accountId = useSelector(selectActiveAccountId);

	useEffect(() => {
		dispatch(accountGroupActivated(paramAccountGroupId));
		dispatch(accountActivated(paramAccountId));
		navigationOccurred({
			selectedNavId: paramAccountGroupId,
			selectedSubNavId: paramAccountId,
			to: navigationTo.account,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paramAccountGroupId, paramAccountId]);

	useEffect(() => {
		if (!accountGroupId || !accountId || !user) {
			return;
		}

		setIsLoading(true);
		const allPromises = [];
		let currentAccountGroupInfo;

		allPromises.push(
			getAccountGroups().then((accountGroups) => {
				currentAccountGroupInfo = accountGroups.find((ag) => ag._id === accountGroupId);
			})
		);
		allPromises.push(
			getAccountInfo(accountGroupId, accountId).then((retrievedAccountInfo) => {
				setAccountInfo(retrievedAccountInfo);
			})
		);
		allPromises.push(
			getOutstandingTransactions(accountId).then((retrievedTransactions) => {
				retrievedTransactions.transactions.sort(sortTransactions);
				setOutstandingTransactions(retrievedTransactions.transactions);
				setOutstandingTransactionPaging({ pageCount: retrievedTransactions.pageCount, pageNumber: retrievedTransactions.pageNumber });
			})
		);
		allPromises.push(
			getClearedTransactions(accountId, 25, 1).then((retrievedTransactions) => {
				retrievedTransactions.transactions.sort(sortTransactions);
				setClearedTransactions(retrievedTransactions.transactions);
				setClearedTransactionPaging({ pageCount: retrievedTransactions.pageCount, pageNumber: retrievedTransactions.pageNumber });
			})
		);
		Promise.all(allPromises).then(() => {
			getAccountGroupPermissionLevel(currentAccountGroupInfo._id).then((pl) => {
				setPermissionLevel(pl);

				setEditingTransaction({});
				setIsSavingTransaction(false);
				setShowConfirmation(false);
				setIsLoading(false);
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountGroupId, accountId, user]);

	useEffect(() => {
		const newSpendableBalance = calculateSpendableBalance(outstandingTransactions, Number(implodeNumber(accountInfo.explodedBalance)));
		setSpendableBalance(newSpendableBalance);
		setBankBalance(calculateBankBalance(outstandingTransactions, newSpendableBalance));
	}, [accountInfo, outstandingTransactions]);

	const handleAddTransaction = () => {
		const newTransaction = generateNewTransaction();
		newTransaction.accountId = accountId;
		setEditingTransaction(newTransaction);
	};

	const handleCancelTransactionEdit = (event) => {
		event.preventDefault();
		setEditingTransaction({});
	};

	const handleSubmitTransactionEdit = (event, transactionToSave) => {
		event.preventDefault();
		clearMessages();
		setIsSavingTransaction(true);

		const errors = validateTransaction(transactionToSave, transactionToSave._id === newTxId);

		setEditingTransactionErrors(errors);

		if (!isNullOrEmptyObject(errors)) {
			setIsSavingTransaction(false);
			setMessages(errors, messageType.error, 'Please fix the following:');
			return;
		}

		delete transactionToSave.amount;

		const newAccountInfo = copy(accountInfo);
		let balance = Number(implodeNumber(newAccountInfo.explodedBalance));
		const newClearedTransactions = copy(clearedTransactions);
		const newOutstandingTransactions = copy(outstandingTransactions);
		const promises = [];

		if (transactionToSave._id === newTxId) {
			promises.push(
				addTransaction(transactionToSave).then((responseData) => {
					if (responseData.tx.accountId === accountId) {
						balance = Number(implodeNumber(responseData.newBalance));
						responseData.tx.amount = Number(implodeNumber(responseData.tx.explodedAmount));
						newOutstandingTransactions.push(responseData.tx);
					}
				})
			);
		} else {
			promises.push(
				updateTransaction(transactionToSave).then((responseData) => {
					balance = Number(implodeNumber(responseData.newBalance));
					responseData.tx.amount = Number(implodeNumber(responseData.tx.explodedAmount));
					const clearedIndex = newClearedTransactions.findIndex((transaction) => transaction._id === transactionToSave._id);
					if (clearedIndex > -1) {
						if (responseData.tx.accountId !== newAccountInfo._id) {
							newClearedTransactions.splice(clearedIndex, 1);
						} else {
							newClearedTransactions[clearedIndex] = responseData.tx;
						}
					}
					const outstandingIndex = newOutstandingTransactions.findIndex((transaction) => transaction._id === transactionToSave._id);
					if (outstandingIndex > -1) {
						if (responseData.tx.accountId !== newAccountInfo._id) {
							newOutstandingTransactions.splice(outstandingIndex, 1);
						} else {
							newOutstandingTransactions[outstandingIndex] = responseData.tx;
						}
					}
				})
			);
		}

		Promise.all(promises).then(() => {
			newClearedTransactions.sort(sortTransactions);
			newOutstandingTransactions.sort(sortTransactions);
			newAccountInfo.explodedBalance = blowUpNumber(balance);
			setAccountInfo(newAccountInfo);
			const newSpendableBalance = calculateSpendableBalance(outstandingTransactions, Number(implodeNumber(accountInfo.explodedBalance)));
			setSpendableBalance(newSpendableBalance);
			setBankBalance(calculateBankBalance(newOutstandingTransactions, balance));
			setClearedTransactions(newClearedTransactions);
			setOutstandingTransactions(newOutstandingTransactions);
			setEditingTransaction({});
			setIsSavingTransaction(false);
		});
	};

	const navigateTransactionList = (transactionList, pageNumber) => {
		if (transactionList === accountSectionHeaderTypes.outstanding) {
			setOutstandingTransactionsLoading(true);
			getOutstandingTransactions(accountId).then((retrievedTransactions) => {
				setOutstandingTransactionPaging({
					pageCount: retrievedTransactions.pageCount,
					pageNumber: retrievedTransactions.pageNumber,
				});
				setOutstandingTransactions(retrievedTransactions.transactions);
				setOutstandingTransactionsLoading(false);
			});
		} else if (transactionList === accountSectionHeaderTypes.cleared) {
			setClearedTransactionsLoading(true);
			getClearedTransactions(accountId, 25, pageNumber).then((retrievedTransactions) => {
				setClearedTransactionPaging({
					pageCount: retrievedTransactions.pageCount,
					pageNumber: retrievedTransactions.pageNumber,
				});
				setClearedTransactions(retrievedTransactions.transactions);
				setClearedTransactionsLoading(false);
			});
		}
	};

	const searchTransactions = (transactionList, searchValue) => {
		if (transactionList === accountSectionHeaderTypes.outstanding) {
			setIsSearchActive({
				cleared: isSearchActive.cleared,
				outstanding: !isNullOrEmptyObject(searchValue),
			});
			setOutstandingTransactionsLoading(true);
			getOutstandingTransactions(accountId, { term: searchValue }).then((retrievedTransactions) => {
				setOutstandingTransactionPaging({
					pageCount: retrievedTransactions.pageCount,
					pageNumber: retrievedTransactions.pageNumber,
				});
				setOutstandingTransactions(retrievedTransactions.transactions);
				setOutstandingTransactionsLoading(false);
			});
		} else if (transactionList === accountSectionHeaderTypes.cleared) {
			setIsSearchActive({
				cleared: !isNullOrEmptyObject(searchValue),
				outstanding: isSearchActive.outstanding,
			});
			setClearedTransactionsLoading(true);
			getClearedTransactions(accountId, 25, 1, { term: searchValue }).then((retrievedTransactions) => {
				setClearedTransactionPaging({
					pageCount: retrievedTransactions.pageCount,
					pageNumber: retrievedTransactions.pageNumber,
				});
				setClearedTransactions(retrievedTransactions.transactions);
				setClearedTransactionsLoading(false);
			});
		}
	};

	const toggleCleared = (transactionId) => {
		if (permissionLevel >= permissionLevels.edit) {
			const transactionToSave = outstandingTransactions.concat(clearedTransactions).find((transaction) => transaction._id === transactionId);
			transactionToSave.cleared = !transactionToSave.cleared;
			updateTransaction(transactionToSave).then((responseData) => {
				const newClearedTransactions = copy(clearedTransactions);
				const newOutstandingTransactions = copy(outstandingTransactions);
				const savedTransaction = responseData.tx;
				savedTransaction.amount = Number(implodeNumber(savedTransaction.explodedAmount));
				if (savedTransaction.cleared) {
					savedTransaction.runningBalance = null;
					newOutstandingTransactions.splice(
						newOutstandingTransactions.findIndex((transaction) => transaction._id === savedTransaction._id),
						1
					);
					newClearedTransactions.push(savedTransaction);
				} else {
					newOutstandingTransactions.push(savedTransaction);
					newClearedTransactions.splice(
						newClearedTransactions.findIndex((transaction) => transaction._id === savedTransaction._id),
						1
					);
				}
				newOutstandingTransactions.sort(sortTransactions);
				newClearedTransactions.sort(sortTransactions);

				const newAccountInfo = copy(accountInfo);
				newAccountInfo.explodedBalance = responseData.newBalance;
				setAccountInfo(newAccountInfo);
				const newSpendableBalance = calculateSpendableBalance(outstandingTransactions, Number(implodeNumber(accountInfo.explodedBalance)));
				setSpendableBalance(newSpendableBalance);
				setBankBalance(calculateBankBalance(newOutstandingTransactions, Number(implodeNumber(newAccountInfo.explodedBalance))));
				setClearedTransactions(newClearedTransactions);
				setOutstandingTransactions(newOutstandingTransactions);
			});
		}
	};

	const handleEditTransaction = (transactionId) => {
		const transaction = outstandingTransactions.concat(clearedTransactions).find((transaction) => transaction._id === transactionId);
		setEditingTransaction(transaction);
	};

	const handleDeleteTransaction = (transactionId) => {
		setDeleteTransactionId(transactionId);
		setShowConfirmation(true);
	};

	const handleCancelConfirm = () => {
		setDeleteTransactionId(null);
		setShowConfirmation(false);
	};

	const handleConfirmDeleteTransaction = () => {
		deleteTransaction(deleteTransactionId).then((explodedBalance) => {
			const newClearedTransactions = copy(clearedTransactions);
			const clearedIndex = newClearedTransactions.findIndex((transaction) => transaction._id === deleteTransactionId);
			const newOutstandingTransactions = copy(outstandingTransactions);
			const outstandingIndex = newOutstandingTransactions.findIndex((transaction) => transaction._id === deleteTransactionId);
			if (outstandingIndex > -1) {
				newOutstandingTransactions.splice(outstandingIndex, 1);
				newOutstandingTransactions.sort(sortTransactions);
			}
			if (clearedIndex > -1) {
				newClearedTransactions.splice(clearedIndex, 1);
				newClearedTransactions.sort(sortTransactions);
			}

			const newAccountInfo = copy(accountInfo);
			newAccountInfo.explodedBalance = explodedBalance;
			setAccountInfo(newAccountInfo);
			const newSpendableBalance = calculateSpendableBalance(outstandingTransactions, Number(implodeNumber(accountInfo.explodedBalance)));
			setSpendableBalance(newSpendableBalance);
			setBankBalance(calculateBankBalance(newOutstandingTransactions, Number(implodeNumber(explodedBalance))));
			setClearedTransactions(newClearedTransactions);
			setDeleteTransactionId(null);
			setOutstandingTransactions(newOutstandingTransactions);
			setShowConfirmation(false);
		});
	};

	if (isLoading) {
		return (
			<PageTemplate>
				<WaitingMessageComponent message="Loading account information" />
			</PageTemplate>
		);
	}

	return (
		<PageTemplate>
			<>
				{isNullOrEmptyObject(editingTransaction) ? <AddTransactionButtonsComponent accountType={accountInfo.type} onAdd={handleAddTransaction} /> : ''}
				{editingTransaction._id === newTxId ? (
					<TransactionEditComponent
						transaction={editingTransaction}
						formErrors={editingTransactionErrors}
						onCancelTransactionEdit={handleCancelTransactionEdit}
						onSubmitTransactionEdit={handleSubmitTransactionEdit}
						isSaving={isSavingTransaction}
					/>
				) : (
					''
				)}
				<table className="table table-striped">
					<AccountSectionHeaderComponent
						headerType={accountSectionHeaderTypes.outstanding}
						balance={spendableBalance}
						pageNumber={outstandingTransactionPaging.pageNumber}
						pageCount={outstandingTransactionPaging.pageCount}
						onNavigate={(pageNumber) => navigateTransactionList(accountSectionHeaderTypes.outstanding, pageNumber)}
						onSearch={(searchValue) => searchTransactions(accountSectionHeaderTypes.outstanding, searchValue)}
						isSearchActive={isSearchActive.outstanding}
						hasRunningBalance={true}
					/>
					<tbody>
						{outstandingTransactionsLoading ? (
							<tr>
								<td>
									<WaitingMessageComponent message="Loading account information" />
								</td>
							</tr>
						) : (
							outstandingTransactions.map((transaction) => {
								if (editingTransaction._id === transaction._id) {
									return (
										<tr key={transaction._id}>
											<td>
												<TransactionEditComponent
													transaction={editingTransaction}
													formErrors={editingTransactionErrors}
													onCancelTransactionEdit={handleCancelTransactionEdit}
													onSubmitTransactionEdit={handleSubmitTransactionEdit}
													isSaving={isSavingTransaction}
												/>
											</td>
										</tr>
									);
								}

								return (
									<tr key={transaction._id}>
										<td>
											<TransactionDetailComponent
												transaction={transaction}
												permissionLevel={permissionLevel}
												onToggleCleared={toggleCleared}
												onEdit={handleEditTransaction}
												onDelete={handleDeleteTransaction}
											/>
										</td>
									</tr>
								);
							})
						)}
					</tbody>
				</table>
				<table className="table table-striped">
					<AccountSectionHeaderComponent
						headerType={accountSectionHeaderTypes.cleared}
						balance={bankBalance}
						pageNumber={clearedTransactionPaging.pageNumber}
						pageCount={clearedTransactionPaging.pageCount}
						onNavigate={(pageNumber) => navigateTransactionList(accountSectionHeaderTypes.cleared, pageNumber)}
						onSearch={(searchValue) => searchTransactions(accountSectionHeaderTypes.cleared, searchValue)}
						isSearchActive={isSearchActive.cleared}
						hasRunningBalance={false}
					/>
					<tbody>
						{clearedTransactionsLoading ? (
							<tr>
								<td>
									<WaitingMessageComponent message="Loading account information" />
								</td>
							</tr>
						) : (
							clearedTransactions.map((transaction) => {
								if (editingTransaction._id === transaction._id) {
									return (
										<tr key={transaction._id}>
											<td>
												<TransactionEditComponent
													transaction={editingTransaction}
													formErrors={editingTransactionErrors}
													onCancelTransactionEdit={handleCancelTransactionEdit}
													onSubmitTransactionEdit={handleSubmitTransactionEdit}
													isSaving={isSavingTransaction}
												/>
											</td>
										</tr>
									);
								}

								return (
									<tr key={transaction._id}>
										<td>
											<TransactionDetailComponent
												transaction={transaction}
												permissionLevel={permissionLevel}
												onToggleCleared={toggleCleared}
												onEdit={handleEditTransaction}
												onDelete={handleDeleteTransaction}
											/>
										</td>
									</tr>
								);
							})
						)}
					</tbody>
				</table>
				<ConfirmComponent
					isOpen={showConfirmation}
					title="Delete Transaction"
					confirmButtonText="Delete"
					confirmButtonClass="danger"
					cancelButtonText="Cancel"
					onCancel={handleCancelConfirm}
					onConfirm={handleConfirmDeleteTransaction}>
					<div>Are you sure you want to delete this transaction? This action cannot be undone.</div>
				</ConfirmComponent>
			</>
		</PageTemplate>
	);
}

export default withLogin(AccountContainer);
