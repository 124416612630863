import { isNullOrEmptyString } from '@chris-crap-packages/utilities';
import { transactionTypeOptions } from '@xenomite-packages/cash-flow-globals';
import { isArray, isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { cancelGetAutoCompleteResults, getAutoCompleteResults, getCurrentLocation } from '../../../../services/LocationService';
import TextWithIconComponent from '../../TextWithIconComponent';
import AutoSuggestComponent from '../AutoSuggestComponent';
import DateInputGroupComponent from '../DateInputGroupComponent';
import SelectInputGroupComponent from '../SelectInputGroupComponent';
import TextInputGroupComponent from '../TextInputGroupComponent';
import { textInputTypes } from '../TextInputGroupComponent/TextInputGroupComponent';

const formObjectType = 'transaction';

let currentLocation = {
	coords: {
		accuracy: null,
		latitude: null,
		longitude: null,
	},
};
let payeeSuggestionTimeout = null;

class TransactionInputsComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			payeeSuggestions: [],
		};

		this.clearPayeeSuggestions = clearPayeeSuggestions.bind(this);
		this.getPayeeSuggestions = getPayeeSuggestions.bind(this);
		this.handleAutoSuggestBlur = handleAutoSuggestBlur.bind(this);
		this.handleAutoSuggestSelection = handleAutoSuggestSelection.bind(this);
	}

	componentDidMount() {
		getCurrentLocation().then((geoData) => {
			currentLocation = geoData;
		});
	}

	render() {
		return (
			<div className="row">
				<div className="col-xs-12 col-md-8">
					<SelectInputGroupComponent
						title="Transaction Type:*"
						value={this.props.transactionType}
						isInvalid={!isNil(this.props.formErrors.transactionType)}
						options={transactionTypeOptions}
						onFieldChange={(event) => {
							this.props.onFieldChange(event, 'transactionType', formObjectType);
						}}
						testId="transaction-form-transaction-type"
					/>
				</div>
				<div className="col-xs-12 col-md-4">
					<DateInputGroupComponent
						title="Date:*"
						value={this.props.transaction.date}
						isInvalid={!isNil(this.props.formErrors.date)}
						onFieldChange={(event) => {
							this.props.onFieldChange(event, 'date', formObjectType);
						}}
					/>
				</div>
				<div className="col-xs-12 col-md-8">
					<AutoSuggestComponent
						title="Payee*"
						domId="edit-tx-payee"
						autoFocus={true}
						value={this.props.transaction.payee}
						isInvalid={!isNil(this.props.formErrors.payee)}
						onFieldChange={(event) => {
							clearTimeout(payeeSuggestionTimeout);
							this.props.onFieldChange(event, 'payee', formObjectType);
							payeeSuggestionTimeout = setTimeout(() => {
								this.getPayeeSuggestions(event.target.value);
							}, 250);
						}}
						suggestions={this.state.payeeSuggestions}
						renderItem={renderPayeeItem}
						onItemSelected={this.handleAutoSuggestSelection}
						onClearSuggestions={this.clearPayeeSuggestions}
						testId="transaction-form-payee"
						onBlur={this.handleAutoSuggestBlur}
					/>
				</div>
				<div className="col-xs-12 col-md-4">
					<TextInputGroupComponent
						title="Amount:*"
						type={textInputTypes.number}
						step={0.01}
						min={0}
						addonTextBefore={this.props.transactionType}
						value={this.props.transaction.amount}
						isInvalid={!isNil(this.props.formErrors.amount)}
						onFieldChange={(event) => {
							this.props.onFieldChange(event, 'amount');
						}}
						testId="transaction-form-amount"
					/>
				</div>
				<div className="col-xs-12">
					<TextInputGroupComponent
						title="Description:"
						type={textInputTypes.text}
						value={this.props.transaction.description}
						onFieldChange={(event) => {
							this.props.onFieldChange(event, 'description', formObjectType);
						}}
						testId="transaction-form-description"
					/>
				</div>
				<div className="col-xs-12">
					<SelectInputGroupComponent
						title="Account:*"
						value={this.props.transaction.accountId}
						isInvalid={!isNil(this.props.formErrors.accountId)}
						options={this.props.accountOptions}
						blankLabel="Please select account..."
						onFieldChange={(event) => {
							this.props.onFieldChange(event, 'accountId', formObjectType);
						}}
						testId="transaction-form-account-id"
					/>
				</div>
				<div className="col-xs-12">
					<SelectInputGroupComponent
						title="Category:*"
						value={this.props.transaction.categoryId}
						isInvalid={!isNil(this.props.formErrors.categoryId)}
						options={this.props.categoryOptions}
						blankLabel="Please select category..."
						onFieldChange={(event) => {
							this.props.onFieldChange(event, 'categoryId', formObjectType);
						}}
						testId="transaction-form-category-id"
					/>
				</div>
			</div>
		);
	}
}

function getPayeeSuggestions(payee) {
	if (isNullOrEmptyString(this.props.transaction.accountId)) {
		return;
	}

	const searchValue = payee.trim().toLowerCase();

	if (searchValue.length === 0) {
		this.clearPayeeSuggestions();
		return;
	}

	getAutoCompleteResults(this.props.transaction.accountId, searchValue, currentLocation.coords)
		.then((suggestions) => {
			if (!isArray(suggestions)) {
				suggestions = [];
			}
			this.setState({
				payeeSuggestions: suggestions,
			});
		})
		.catch(() => {
			this.setState({ payeeSuggestions: [] });
		});
}

function renderPayeeItem(suggestion) {
	return (
		<div>
			{suggestion.isLocation ? <TextWithIconComponent iconName="map-marker" /> : ''}
			&nbsp;
			{suggestion.name}
		</div>
	);
}

function clearPayeeSuggestions() {
	this.setState({
		payeeSuggestions: [],
	});
}

function handleAutoSuggestBlur() {
	cancelGetAutoCompleteResults();
	this.clearPayeeSuggestions();
}

function handleAutoSuggestSelection(selectedItem) {
	if (!isEmpty(selectedItem)) {
		this.props.onFieldChange({ target: { value: selectedItem.name } }, 'payee', formObjectType);
		this.clearPayeeSuggestions();
	}
}

TransactionInputsComponent.propTypes = {
	accountOptions: PropTypes.array.isRequired,
	categoryOptions: PropTypes.array.isRequired,
	formErrors: PropTypes.object.isRequired,
	onFieldChange: PropTypes.func.isRequired,
	transaction: PropTypes.object.isRequired,
	transactionType: PropTypes.string.isRequired,
};

export default TransactionInputsComponent;
