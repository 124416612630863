import { isNullOrEmptyString } from '@chris-crap-packages/utilities';
import { reportTypes } from '@xenomite-packages/cash-flow-globals';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getTransactionsSummary } from '../../../services/TransactionService';
import { selectActiveAccountGroupId } from '../../../state/activeItemSlice';
import { isoDatePartToLocalDatePart } from '../../../Utilities';
import DateInputGroupComponent from '../../common/forms/DateInputGroupComponent';
import SelectInputGroupComponent from '../../common/forms/SelectInputGroupComponent';
import WaitingMessageComponent from '../../common/WaitingMessageComponent';
import BasicReportSettings from '../BasicReportSettings';
import TransactionSummaryGroup from './TransactionSummaryGroup';

const dateRangeOptions = [
	{ label: 'Month to Date', value: 'mtd' },
	{ label: 'Year to Date', value: 'ytd' },
	{ label: 'Last Year', value: 'year' },
	{ label: 'Custom', value: 'custom' },
];

const TransactionSummary = ({ printView, start, end }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [dateRange, setDateRange] = useState(dateRangeOptions[1].value);
	const [dates, setDates] = useState({ end: '', start: '' });
	const [summary, setSummary] = useState([]);
	const accountGroupId = useSelector(selectActiveAccountGroupId);

	const handleUpdateDateRange = (range) => {
		setDateRange(range);
		if (!printView) {
			const now = new Date();
			switch (range) {
				case dateRangeOptions[0].value:
					setDates({
						end: `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`,
						start: `${now.getFullYear()}-${now.getMonth() + 1}-01`,
					});
					break;
				case dateRangeOptions[1].value:
					setDates({
						end: `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`,
						start: `${now.getFullYear()}-01-01`,
					});
					break;
				case dateRangeOptions[2].value:
					setDates({
						end: `${now.getFullYear() - 1}-12-31`,
						start: `${now.getFullYear() - 1}-01-01`,
					});
					break;
				case dateRangeOptions[3].value:
					setDates({ end: '', start: '' });
					break;
				default:
					break;
			}
		} else {
			setDates({ end, start });
		}
	};

	const fetchReport = () => {
		setIsLoading(true);
		getTransactionsSummary(accountGroupId, dates.start, dates.end).then((serverSummary) => {
			setIsLoading(false);
			setSummary(serverSummary);
		});
	};

	useEffect(() => {
		if (!!dates.start && !!dates.end) {
			fetchReport();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates]);

	useEffect(() => {
		if (!isNullOrEmptyString(accountGroupId)) {
			handleUpdateDateRange(dateRangeOptions[1].value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountGroupId]);

	if (isLoading) {
		return <WaitingMessageComponent />;
	}

	return (
		<>
			<BasicReportSettings reportType={reportTypes.transactionSummary} onSettingsChange={() => handleUpdateDateRange(dateRange)} />
			<div className="row">
				<div className="col-xs-12">
					{!printView && (
						<>
							<div className="row">
								<div className="col-xs-6"></div>
								<div className="col-xs-2">
									{dateRange === dateRangeOptions[3].value && (
										<DateInputGroupComponent
											title="Start Date:"
											value={dates.start}
											onFieldChange={(event) => {
												setDates({ ...dates, start: event.target.value });
											}}
										/>
									)}
								</div>
								<div className="col-xs-2">
									{dateRange === dateRangeOptions[3].value && (
										<DateInputGroupComponent
											title="End Date:"
											value={dates.end}
											onFieldChange={(event) => {
												setDates({ ...dates, end: event.target.value });
											}}
										/>
									)}
								</div>
								<div className="col-xs-2">
									<SelectInputGroupComponent
										title="Date Range"
										options={dateRangeOptions}
										value={dateRange}
										onFieldChange={(event) => handleUpdateDateRange(event.target.value)}
									/>
								</div>
							</div>
						</>
					)}
					<div className="row">
						<div className="col-xs-12 text-center">
							{dates.start && dates.end ? (
								<h3>
									Transaction summary for {new Date(isoDatePartToLocalDatePart(dates.start)).toLocaleDateString()} -{' '}
									{new Date(isoDatePartToLocalDatePart(dates.end)).toLocaleDateString()}
								</h3>
							) : (
								<h3>Choose your dates</h3>
							)}
						</div>
					</div>
					{summary.map((summaryGroup) => (
						<TransactionSummaryGroup group={summaryGroup} key={summaryGroup.name} printView={printView} />
					))}
				</div>
				{!printView && (
					<div className="col-xs-12 text-right top-buffer bottom-buffer">
						<Link to={`/printable-report/tx-summary/${accountGroupId}/${dates.start}/${dates.end}`} target="_blank" rel="noopener noreferrer">
							Printer Friendly Version
						</Link>
					</div>
				)}
			</div>
		</>
	);
};

TransactionSummary.propTypes = {
	end: PropTypes.string,
	printView: PropTypes.bool,
	start: PropTypes.string,
};

export default TransactionSummary;
