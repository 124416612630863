import { useState } from 'react';

import SettingsToggle from '../../SettingsToggle/SettingsToggle';
import LetterGrade from '../LetterGrade';
import NetWorth from '../NetWorth';
import ReportCardSettings from '../ReportCardSettings';

const ReportCard = () => {
	const [showSettings, setShowSettings] = useState(false);

	const handleToggleShowSettings = () => {
		setShowSettings(!showSettings);
	};

	return (
		<>
			<SettingsToggle onToggleShowSettings={handleToggleShowSettings} />
			{showSettings ? (
				<ReportCardSettings onToggleShowSettings={handleToggleShowSettings} />
			) : (
				<div className="row">
					<div className="col-xs-11">
						<NetWorth />
					</div>
					<div className="col-xs-1">
						<LetterGrade />
					</div>
				</div>
			)}
		</>
	);
};

export default ReportCard;
