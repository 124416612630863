import { copy, implodeNumber, isNullOrEmptyString, isNullOrUndefined } from '@chris-crap-packages/utilities';
import { accountTypes, assetTypes, categoryTypes, liabilityTypes, permissionLevels } from '@xenomite-packages/cash-flow-globals';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCategoryNameSuggestions, getNewCategory } from '../../../services/CategoryService';
import { selectAppUser } from '../../../state/userSlice';
import { createOptionArrayFromObject } from '../../../Utilities';
import AutoSuggestComponent from '../../common/forms/AutoSuggestComponent';
import ButtonComponent from '../../common/forms/ButtonComponent';
import CheckboxComponent from '../../common/forms/CheckboxComponent';
import SelectInputGroupComponent from '../../common/forms/SelectInputGroupComponent';
import TextInputGroupComponent from '../../common/forms/TextInputGroupComponent';

const ACCOUNT_SUBTYPES = [
	{
		name: assetTypes.illiquid,
		parent: accountTypes.asset,
	},
	{
		name: assetTypes.liquid,
		parent: accountTypes.asset,
	},
	{
		name: liabilityTypes.installment,
		parent: accountTypes.liability,
	},
	{
		name: liabilityTypes.revolving,
		parent: accountTypes.liability,
	},
];

const permissionLevelOptions = [
	{
		label: 'Read Only',
		value: permissionLevels.readOnly,
	},
	{
		label: 'Edit',
		value: permissionLevels.edit,
	},
	{
		label: 'Owner',
		value: permissionLevels.owner,
	},
];

export const listTypes = {
	account: 'Account',
	accountGroup: 'Account Group',
	category: 'Category',
	users: 'Users',
};

const UserFormsSwitcherComponent = ({ accountGroups, item, listType, onSaveItem, accountGroupsOptions, selectedAccountGroup }) => {
	const [accountSubtypeOptions, setAccountSubtypeOptions] = useState([]);
	const [categoryNameSuggestions, setCategoryNameSuggestions] = useState([]);
	const [workingItem, setWorkingItem] = useState({
		accountGroups: '',
		balance: '',
		creditLimit: '',
		emailAddress: '',
		isDefault: false,
		name: '',
		oldName: '',
		parent: '',
		permissionLevel: '',
		subType: '',
		type: '',
	});
	const [saveIcon, setSaveIcon] = useState('plus');
	const [saveText, setSaveText] = useState('Add');
	const [isShownOnMobile, setIsShownOnMobile] = useState(false);
	const user = useSelector(selectAppUser);

	const accountTypesOptions = createOptionArrayFromObject(accountTypes);
	const categoryTypeOptions = createOptionArrayFromObject(categoryTypes);

	const clearNewItem = () => {
		setWorkingItem(getDefaultItem());
	};

	const getAccountSubtypeOptions = (accountType) => {
		const accountSubtypeOptions = [];

		ACCOUNT_SUBTYPES.forEach((subType) => {
			if (subType.parent === accountType) {
				accountSubtypeOptions.push({
					label: subType.name,
					value: subType.name,
				});
			}
		});

		return accountSubtypeOptions;
	};

	const getCategorySuggestions = async (categoryName) => {
		const searchValue = categoryName.trim().toLowerCase();

		if (searchValue.length === 0) {
			clearCategorySuggestions();
			return;
		}

		const suggestions = await getCategoryNameSuggestions(searchValue);
		setCategoryNameSuggestions(suggestions);
	};

	const clearCategorySuggestions = () => {
		setCategoryNameSuggestions([]);
	};

	const handleCategorySuggestSelection = (selectedItem) => {
		if (!isEmpty(selectedItem)) {
			handleNewItemChanged({ target: { value: selectedItem.name } }, 'name');
			clearCategorySuggestions();
		}
	};

	const handleNewItemChanged = (event, field) => {
		const newerItem = copy(workingItem);

		if (field === 'type') {
			if (newerItem.type !== event.target.value) {
				newerItem.subType = '';
			}
		}

		newerItem[field] = event.target.value;

		setAccountSubtypeOptions(getAccountSubtypeOptions(newerItem.type));
		setWorkingItem(newerItem);
	};

	const getDefaultItem = () => {
		switch (listType) {
			case listTypes.account:
				return {
					accountGroupId: selectedAccountGroup,
					balance: '',
					creditLimit: '',
					name: '',
					oldName: '',
					subType: '',
					type: '',
				};
			case listTypes.accountGroup:
				return { name: '' };
			case listTypes.category:
				return getNewCategory(accountGroups);
			case listTypes.users:
				return { emailAddress: '', permissionLevel: 0 };
			default:
				return {};
		}
	};

	const renderNameItem = (suggestion) => {
		return <div>{suggestion.name}</div>;
	};

	const determineIsShownOnMobile = async () => {
		if (listType === listTypes.account) {
			const showOnMobile = isNullOrUndefined(workingItem.showOnMobile) ? [] : copy(workingItem.showOnMobile);
			const newIsShownOnMobile = showOnMobile.findIndex((userId) => userId === user._id) !== -1;
			setIsShownOnMobile(newIsShownOnMobile);
		}
	};

	const handleToggleAccountShowOnMobile = async () => {
		const newItem = copy(workingItem);
		newItem.showOnMobile = newItem.showOnMobile || [];
		if (isShownOnMobile) {
			const userIndex = newItem.showOnMobile.findIndex((userId) => userId === user._id);
			newItem.showOnMobile.splice(userIndex, 1);
		} else {
			newItem.showOnMobile.push(user._id);
		}
		setWorkingItem(newItem);
	};

	useEffect(() => {
		let tempAccountSubtypeOptionss = [];
		let tempItem;

		if (!isEmpty(item)) {
			setSaveIcon('save');
			setSaveText('Save');
			tempItem = item;
			tempItem.oldName = tempItem.name;
			if (listType === listTypes.account) {
				tempItem.balance = implodeNumber(tempItem.explodedBalance);
				tempItem.creditLimit = implodeNumber(tempItem.explodedCreditLimit);
				tempItem.oldAccountGroupId = tempItem.accountGroupId;
			}
			tempAccountSubtypeOptionss = getAccountSubtypeOptions(tempItem.type);
		} else {
			tempItem = getDefaultItem();
		}

		setAccountSubtypeOptions(tempAccountSubtypeOptionss);
		setWorkingItem(tempItem);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item, listType]);

	useEffect(() => {
		determineIsShownOnMobile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workingItem]);

	if (listType === listTypes.account) {
		return (
			<form
				onSubmit={(event) => {
					event.preventDefault();
					onSaveItem(workingItem, clearNewItem);
				}}>
				{isNullOrEmptyString(workingItem._id) ? (
					<div className="row">
						<div className="col-xs-12">
							<strong>Create New Account</strong>
						</div>
					</div>
				) : (
					''
				)}
				<div className="row">
					<div className="col-xs-10">
						<div className="row">
							<div className="col-xs-6">
								<SelectInputGroupComponent
									onFieldChange={(event) => {
										handleNewItemChanged(event, 'accountGroupId');
									}}
									options={accountGroupsOptions}
									title="Account Group..."
									titleAsPlaceholder={true}
									value={workingItem.accountGroupId}
								/>
							</div>
							<div className="col-xs-6"></div>
						</div>
						<div className="row">
							<div className="col-xs-6">
								<TextInputGroupComponent
									onFieldChange={(event) => {
										handleNewItemChanged(event, 'name');
									}}
									title="Name"
									type="text"
									titleAsPlaceholder={true}
									value={workingItem.name}
									testId="new-account-name"
								/>
							</div>
							<div className="col-xs-6">
								<TextInputGroupComponent
									onFieldChange={(event) => {
										handleNewItemChanged(event, 'balance');
									}}
									title="Balance"
									type="number"
									step={0.01}
									titleAsPlaceholder={true}
									value={workingItem.balance}
									testId="new-account-balance"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-xs-4">
								<SelectInputGroupComponent
									onFieldChange={(event) => {
										handleNewItemChanged(event, 'type');
									}}
									options={accountTypesOptions}
									title="Account Type..."
									titleAsPlaceholder={true}
									value={workingItem.type}
								/>
							</div>
							<div className="col-xs-4">
								<SelectInputGroupComponent
									onFieldChange={(event) => {
										handleNewItemChanged(event, 'subType');
									}}
									options={accountSubtypeOptions}
									title={isNullOrEmptyString(workingItem.type) ? 'Select Account Type First...' : `${workingItem.type} Type...`}
									titleAsPlaceholder={true}
									value={workingItem.subType}
								/>
							</div>
							{workingItem.subType === liabilityTypes.revolving ? (
								<div className="col-xs-4">
									<TextInputGroupComponent
										onFieldChange={(event) => {
											handleNewItemChanged(event, 'creditLimit');
										}}
										title="Credit Limit"
										type="number"
										step={0.01}
										titleAsPlaceholder={true}
										value={workingItem.creditLimit}
										testId="new-account-credit-limit"
									/>
								</div>
							) : (
								''
							)}
						</div>
						<div className="row">
							<div className="col-xs-4">
								<CheckboxComponent onClick={handleToggleAccountShowOnMobile} isChecked={isShownOnMobile}>
									Show on mobile app?
								</CheckboxComponent>
							</div>
						</div>
					</div>
					<div className="col-xs-2">
						<ButtonComponent colorClass="primary" type="submit" iconName={saveIcon} text={saveText} testId="new-account-save" />
					</div>
				</div>
			</form>
		);
	}

	if (listType === listTypes.users) {
		return (
			<form
				onSubmit={(event) => {
					event.preventDefault();
					onSaveItem(workingItem, clearNewItem);
				}}>
				{isNullOrEmptyString(workingItem._id) ? (
					<div className="row">
						<div className="col-xs-12">
							<strong>New Account Group User:</strong>
						</div>
					</div>
				) : (
					''
				)}
				<div className="row">
					<div className="col-xs-5">
						<TextInputGroupComponent
							onFieldChange={(event) => {
								handleNewItemChanged(event, 'emailAddress');
							}}
							title="Email Address"
							type="email"
							titleAsPlaceholder={true}
							value={workingItem.emailAddress}
							testId="new-account-group-user-email-address"
						/>
					</div>
					<div className="col-xs-5">
						<SelectInputGroupComponent
							onFieldChange={(event) => {
								handleNewItemChanged(event, 'permissionLevel');
							}}
							options={permissionLevelOptions}
							title="Permission Level..."
							titleAsPlaceholder={true}
							value={workingItem.permissionLevel}
						/>
					</div>
					<div className="col-xs-2">
						<ButtonComponent
							colorClass="primary"
							iconName={saveIcon}
							text={saveText}
							onClick={() => {
								onSaveItem(workingItem, clearNewItem);
							}}
							testId="new-account-group-user-save"
						/>
					</div>
				</div>
			</form>
		);
	}

	if (listType === listTypes.category) {
		return (
			<form
				onSubmit={(event) => {
					event.preventDefault();
					onSaveItem(workingItem, clearNewItem);
				}}>
				<div className="row">
					<div className="col-xs-6">
						<AutoSuggestComponent
							title={`New ${listType}`}
							titleAsPlaceholder={true}
							autoFocus={false}
							value={workingItem.name}
							onFieldChange={(event) => {
								handleNewItemChanged(event, 'name');
								getCategorySuggestions(event.target.value);
							}}
							suggestions={categoryNameSuggestions}
							renderItem={renderNameItem}
							onItemSelected={handleCategorySuggestSelection}
							onClearSuggestions={clearCategorySuggestions}
							domId={`${listType}-form-name`}
							testId={`${listType}-form-name`}
						/>
					</div>
					<div className="col-xs-4">
						<SelectInputGroupComponent
							onFieldChange={(event) => {
								handleNewItemChanged(event, 'type');
							}}
							options={categoryTypeOptions}
							title="Category Type..."
							titleAsPlaceholder={true}
							value={workingItem.type}
						/>
					</div>
					<div className="col-xs-2">
						<ButtonComponent
							colorClass="primary"
							iconName={saveIcon}
							text={saveText}
							onClick={() => {
								onSaveItem(workingItem, clearNewItem);
							}}
							testId={`${listType}-save`}
						/>
					</div>
				</div>
			</form>
		);
	}

	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();
				onSaveItem(workingItem, clearNewItem);
			}}>
			<div className="row">
				<div className="col-xs-10">
					<TextInputGroupComponent
						onFieldChange={(event) => {
							handleNewItemChanged(event, 'name');
						}}
						title={`New ${listType}`}
						type="text"
						titleAsPlaceholder={true}
						value={workingItem.name}
						testId="new-account-group-name"
					/>
				</div>
				<div className="col-xs-2">
					<ButtonComponent
						colorClass="primary"
						iconName={saveIcon}
						text={saveText}
						onClick={() => {
							onSaveItem(workingItem, clearNewItem);
						}}
						testId="new-account-group-save"
					/>
				</div>
			</div>
		</form>
	);
};

UserFormsSwitcherComponent.propTypes = {
	accountGroups: PropTypes.array,
	accountGroupsOptions: PropTypes.array,
	item: PropTypes.object,
	listType: PropTypes.string.isRequired,
	onSaveItem: PropTypes.func.isRequired,
	selectedAccountGroup: PropTypes.string,
};

export default UserFormsSwitcherComponent;
