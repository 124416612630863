import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AccountContainer from '../account/AccountContainer';
import AdminContainer from '../admin/AdminContainer';
import DashboardContainer from '../dashboard/DashboardContainer';
import PrivacyPolicy from '../legal/PrivacyPolicy';
import TermsOfUse from '../legal/TermsOfUse';
import NoSmallScreens from '../main/NoSmallScreens';
import PrintableReport from '../printable-report/PrintableReport';
import SettingsContainer from '../user/settings/SettingsContainer';
import IndexComponent from './IndexComponent';
import LoginSuccess from './LoginSuccess';
import Logout from './Logout';

function AppRoutes() {
	const [isSmallScreen, setIsSmallScreen] = useState(false);

	const handleResize = () => {
		const width = window.innerWidth;
		setIsSmallScreen(width < 1035);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.addEventListener('resize', null);
		};
	}, []);

	if (isSmallScreen) {
		return (
			<BrowserRouter>
				<NoSmallScreens />
			</BrowserRouter>
		);
	}

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/account-group/:accountGroupId/account/:accountId" element={<AccountContainer />} />
				<Route path="/account-group/:accountGroupId/dashboard" element={<DashboardContainer />} />
				<Route path="/printable-report/:report/:accountGroupId/:start/:end" element={<PrintableReport hidePageTemplate={true} />} />

				<Route path="/settings/:detailArea" element={<SettingsContainer />} />

				<Route path="/admin/:detailArea" element={<AdminContainer />} />

				<Route path="/terms-of-use" element={<TermsOfUse />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route exact path="/login-success" element={<LoginSuccess />} />
				<Route exact path="/logout" element={<Logout />} />
				<Route exact path="/" element={<IndexComponent />} />
			</Routes>
		</BrowserRouter>
	);
}

export default AppRoutes;
