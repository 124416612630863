import React from 'react';

const Profile = () => {
	return (
		<div>
			<h1>Profile</h1>
			<p>Your profile contains information that lets Rivulet Finance know who you are:</p>
			<ul>
				<li>
					First Name, Last Name: the name you would like to be referred to when working with Rivulet Finance; should be some form of your
					legal name
				</li>
				<li>Email Address: the email address you will use to log in to the site; also used if Rivulet Finance needs to contact you</li>
			</ul>
		</div>
	);
};

export default Profile;
