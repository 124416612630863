import React from 'react';

const MobileApp = () => {
	return (
		<>
			<p>
				The primary purpose of Rivulet Finance is to remove the excuses that prevent you from practicing good personal finance hygiene, like
				recording your everyday transactions. With that in mind, we built a mobile app to accompany this website. The mobile app is, and
				always will be, focused on quickly recording transactions while you are on the go.
			</p>
			<p className="text-center">
				<a href="https://play.google.com/store/apps/details?id=finance.rivulet.mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
					<img alt="Get it on Google Play" src="/images/google-play-badge.png" style={{ height: '6em' }} />
				</a>
			</p>
		</>
	);
};

export default MobileApp;
