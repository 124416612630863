import { implodeNumber } from '@chris-crap-packages/utilities';
import { accountTypes, liabilityTypes, permissionLevels } from '@xenomite-packages/cash-flow-globals';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import { selectAppUser } from '../../../state/userSlice';
import CheckboxComponent from '../../common/forms/CheckboxComponent';
import TextWithIconComponent from '../../common/TextWithIconComponent';
import { listTypes } from './UserFormsSwitcherComponent';

const UserDisplaySwitcherComponent = ({ accountGroups, item, listType, onToggleAccountGroup }) => {
	const [isShownOnMobile, setIsShownOnMobile] = useState(false);
	const user = useSelector(selectAppUser);

	useEffect(() => {
		if (listType === listTypes.account) {
			setIsShownOnMobile(item.showOnMobile.findIndex((userId) => userId === user._id) !== -1);
		}
	}, [listType, item.showOnMobile, user]);

	if (listType === listTypes.account) {
		return (
			<>
				<div className="row">
					<div className="col-xs-6">{item.name}</div>
					<div className="col-xs-6">
						Balance:
						<NumericFormat
							value={Number(implodeNumber(item.explodedBalance))}
							thousandSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix="$"
							displayType="text"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-6">
						{item.type}: {item.subType}
					</div>
					{item.type === accountTypes.liability && item.subType === liabilityTypes.revolving ? (
						<div className="col-xs-6">
							Credit Limit:
							<NumericFormat
								value={implodeNumber(item.explodedCreditLimit)}
								thousandSeparator=","
								decimalScale={2}
								fixedDecimalScale={true}
								prefix="$"
								displayType="text"
							/>
						</div>
					) : (
						''
					)}
				</div>
				{isShownOnMobile ? (
					<div className="row">
						<div className="col-xs-4">
							<TextWithIconComponent iconName="mobile-alt" />
						</div>
					</div>
				) : (
					''
				)}
			</>
		);
	}

	if (listType === listTypes.users) {
		return (
			<div className="row">
				<div className="col-xs-10">
					{item.firstName} {item.lastName}
					<br />
					{item.emailAddress}
				</div>
				<div className="col-xs-2">{item.permissionLevel === permissionLevels.owner ? 'Owner' : item.permissionLevel === permissionLevels.edit ? 'Edit' : 'Read Only'}</div>
			</div>
		);
	}

	if (listType === listTypes.category) {
		return (
			<div>
				{item.name}
				<div className="text-right">
					<em className="pull-left">{item.type}</em>{' '}
					{!isNil(accountGroups) ? (
						<>
							{accountGroups.map((accountGroup) => {
								return (
									<CheckboxComponent
										key={accountGroup._id}
										isChecked={item.accountGroups.findIndex((ag) => ag._id === accountGroup._id) !== -1}
										onClick={() => {
											onToggleAccountGroup(accountGroup._id, item._id);
										}}>
										{accountGroup.name}
									</CheckboxComponent>
								);
							})}
						</>
					) : (
						''
					)}
				</div>
			</div>
		);
	}

	return <div>{item.name}</div>;
};

UserDisplaySwitcherComponent.propTypes = {
	accountGroups: PropTypes.array,
	item: PropTypes.object.isRequired,
	listType: PropTypes.string.isRequired,
	onToggleAccountGroup: PropTypes.func,
};

export default UserDisplaySwitcherComponent;
