import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
	name: 'user',
	initialState: { appUser: null, firebaseUserIsLoggedIn: false },
	reducers: {
		appUserUpdated(state, action) {
			state.appUser = action.payload;
		},
	},
});

export const selectAppUser = (state) => {
	return state.user.appUser;
};

export const { appUserUpdated } = userSlice.actions;

export const userReducer = userSlice.reducer;
