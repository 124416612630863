import { blowUpNumber, copy, implodeNumber, isNullOrEmptyString } from '@chris-crap-packages/utilities';
import { newTxId, transactionTypes } from '@xenomite-packages/cash-flow-globals';
import PropTypes from 'prop-types';
import React from 'react';

import { getAccountGroupIdFromAccountId, getAccountOptions } from '../../services/AccountGroupService';
import { getCategoryOptions } from '../../services/CategoryService';
import FormActionButtonsComponent from '../common/forms/FormActionButtonsComponent';
import TransactionInputsComponent from '../common/forms/TransactionInputsComponent';
import WaitingMessageComponent from '../common/WaitingMessageComponent';

class TransactionEditComponent extends React.Component {
	constructor(props) {
		super(props);

		const transaction = copy(props.transaction);
		transaction.amount = implodeNumber(transaction.explodedAmount);
		this.state = {
			accountOptions: [],
			categoryOptions: [],
			transaction: transaction,
			transactionType: transactionTypes.debit,
		};

		this.handleFieldChange = handleFieldChange.bind(this);
		this.handleTransactionTypeChange = handleTransactionTypeChange.bind(this);
		this.saveTransaction = saveTransaction.bind(this);
	}

	componentDidMount() {
		getAccountOptions().then((accountOptions) => {
			getAccountGroupIdFromAccountId(this.state.transaction.accountId).then((accountGroupId) => {
				getCategoryOptions(accountGroupId).then((categoryOptions) => {
					this.setState({
						categoryOptions,
					});
				});
			});
			this.setState({
				accountOptions,
			});
		});
		if (this.state.transaction.amount) {
			let txType = transactionTypes.debit;
			const tx = copy(this.state.transaction);
			if (tx.amount < 0) {
				tx.amount = Math.abs(tx.amount);
			} else {
				txType = transactionTypes.credit;
			}
			this.setState({
				transaction: tx,
				transactionType: txType,
			});
		}
	}

	render() {
		return (
			<form onSubmit={this.saveTransaction} id="transaction-form">
				<div className="row">
					<div className="col-xs-12 col-md-6 col-md-offset-3">
						<div className={`panel panel-info text-info`}>
							<div className="panel-heading">{this.props.transaction._id === newTxId ? 'Add Transaction' : 'Edit Transaction'}</div>
							<div className="panel-body">
								{this.props.isSaving ? (
									<WaitingMessageComponent message="Saving..." />
								) : (
									<TransactionInputsComponent
										transaction={this.state.transaction}
										transactionType={this.state.transactionType}
										accountOptions={this.state.accountOptions}
										categoryOptions={this.state.categoryOptions}
										formErrors={this.props.formErrors}
										onFieldChange={this.handleFieldChange}
									/>
								)}
							</div>
							<div className="panel-footer text-right">
								{this.props.isSaving ? '' : <FormActionButtonsComponent formName="transaction" onCancel={this.props.onCancelTransactionEdit} />}
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

function handleFieldChange(event, field) {
	if (field === 'transactionType') {
		return this.handleTransactionTypeChange(event);
	}

	const transaction = this.state.transaction;
	transaction[field] = event.target.value;
	this.setState({
		transaction: transaction,
	});

	if (field === 'accountId') {
		getAccountGroupIdFromAccountId(this.state.transaction.accountId).then((accountGroupId) => {
			getCategoryOptions(accountGroupId).then((categoryOptions) => {
				this.setState({
					categoryOptions,
				});
				if (categoryOptions.findIndex((option) => option.value === this.state.transaction.categoryId) === -1) {
					this.handleFieldChange({ target: { value: '' } }, 'categoryId');
				} else {
					this.handleFieldChange({ target: { value: this.state.transaction.categoryId } }, 'categoryId');
				}
			});
		});
	}
}

function handleTransactionTypeChange(event) {
	const transactionType = event.target.value;

	this.setState({
		transactionType: transactionType,
	});
}

function saveTransaction(event) {
	const transaction = copy(this.state.transaction);

	if (!isNullOrEmptyString(transaction.amount)) {
		transaction.amount = Math.abs(transaction.amount);
		if (this.state.transactionType === transactionTypes.debit) {
			transaction.amount = -1 * transaction.amount;
		}
	}
	transaction.explodedAmount = blowUpNumber(transaction.amount);
	delete transaction.amount;

	this.props.onSubmitTransactionEdit(event, transaction);
}

TransactionEditComponent.propTypes = {
	formErrors: PropTypes.object.isRequired,
	isSaving: PropTypes.bool.isRequired,
	onCancelTransactionEdit: PropTypes.func.isRequired,
	onSubmitTransactionEdit: PropTypes.func.isRequired,
	transaction: PropTypes.object.isRequired,
};

export default TransactionEditComponent;
