import PropTypes from 'prop-types';
import React from 'react';

import { articleSlugs } from '../TableOfContents/TableOfContents';

const AccountGroupTabs = ({ onClickArticle }) => {
	return (
		<div>
			<h1>Account Group Tabs</h1>
			<p>
				When you create an account group, a tab for it is added to the navigation bar. In each tab, you will find a subnavigation bar with
				links to:
			</p>
			<ul>
				{/* TODO: turn this into a link when there is a help article for the dashboard */}
				<li>The account group dashboard</li>
				<li>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							onClickArticle(articleSlugs.accountGroupTabsAccounts);
						}}
					>
						A register for each account in the account group
					</a>
				</li>
			</ul>
		</div>
	);
};

AccountGroupTabs.propTypes = {
	onClickArticle: PropTypes.func.isRequired,
};

export default AccountGroupTabs;
