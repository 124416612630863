import React from 'react';
import { Link } from 'react-router-dom';

import PageTemplate from '../../main/PageTemplate/PageTemplate';

const TermsOfUse = () => {
	return (
		<PageTemplate>
			<>
				<p>This page sould be full of legalese, but this app is still in beta, so here's what you need to know for now:</p>
				<ol>
					<li>
						By using this website, you agree to be bound by these terms and the <Link to="/privacy-policy">privacy policy</Link>
					</li>
					<li>
						These terms can change at any time and you agree to be bound by the new terms, with or with out notice. Simply put, we are
						planning to update the terms when we are ready to start charging for premium features.
					</li>
					<li>
						You understand that this is pre-release software and may have bugs introduced from time to time that render the software
						unusable. It may also have features removed without warning.
					</li>
					<li>You will hold the company and any individuals associated with it harmless for injuries resulting from use of the website.</li>
				</ol>
				<p>
					That's it for now. If you agree, please{' '}
					<Link to="/account-group/default/dashboard" data-test-id="log-in-link">
						enter the app.
					</Link>
				</p>
			</>
		</PageTemplate>
	);
};

export default TermsOfUse;
