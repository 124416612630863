import { isNullOrEmptyObject } from '@chris-crap-packages/utilities';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getAccountGroups } from '../../../services/AccountGroupService';
import { selectAppUser } from '../../../state/userSlice';
import WaitingMessageComponent from '../../common/WaitingMessageComponent';
import PageTemplate from '../PageTemplate/';

const LoginSuccess = () => {
	const navigate = useNavigate();
	const user = useSelector(selectAppUser);

	useEffect(() => {
		(async () => {
			if (!isNullOrEmptyObject(user)) {
				if (!user.infoUpdated) {
					navigate('/settings/profile');
				} else {
					const accountGroups = await getAccountGroups();
					if (accountGroups.length > 0) {
						navigate(`/account-group/${accountGroups[0]._id}/dashboard`);
					} else {
						navigate('/settings/account-groups');
					}
				}
			}
		})();
	}, [user, navigate]);

	return (
		<PageTemplate>
			<WaitingMessageComponent message="Gathering your info" />
		</PageTemplate>
	);
};

export default LoginSuccess;
