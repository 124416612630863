import { blowUpNumber, copy, isNullOrEmptyObject, isNullOrEmptyString, isNullOrUndefined } from '@chris-crap-packages/utilities';
import { validateAccount, validateAccountGroup, validateAccountGroupList, validateAccountGroupToUser, validateAccountList } from '@xenomite-packages/cash-flow-validator';
import React, { useEffect, useState } from 'react';

import { addAccountGroup, deleteAccountGroup, deleteAccountGroupUser, getAccountGroups, getAccountGroupUsers, saveAccountGroupUser, updateAccountGroup, updateAccountGroups } from '../../../../services/AccountGroupService';
import { addAccount, deleteAccount, getAccounts, updateAccount, updateAccounts } from '../../../../services/AccountService';
import { clearMessages, messageType, setMessages } from '../../../../services/FeedbackMessageService';
import { createOptionArray } from '../../../../Utilities';
import ConfirmComponent from '../../../common/ConfirmComponent';
import EditableListComponent from '../../../common/EditableListComponent';
import { listTypes } from '../UserFormsSwitcherComponent';

const children = {
	accounts: 'accounts',
	users: 'users',
};
const titleCase = {
	account: 'Account',
	accountGroup: 'Account Group',
	empty: '',
};

const AccountGroupsComponent = () => {
	const [accountGroups, setAccountGroups] = useState([]);
	const [accounts, setAccounts] = useState([]);
	const [childType, setChildType] = useState('');
	const [deleteObject, setDeleteObject] = useState({});
	const [deleteObjectTitle, setDeleteObjectTitle] = useState(titleCase.empty);
	const [editingAccount, setEditingAcount] = useState('');
	const [editingAccountGroup, setEditingAcountGroup] = useState('');
	const [editingUser, setEditingUser] = useState('');
	const [selectedAccount, setSelectedAccount] = useState('');
	const [selectedAccountGroup, setSelectedAccountGroup] = useState('');
	const [selectedUser, setSelectedUser] = useState('');
	const [accountGroupUsers, setAccountGroupUsers] = useState([]);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [accountGroupsOptions, setAccountGroupsOptions] = useState([]);

	const handleAddAccountGroup = (newItem, onFinish) => {
		const accountGroup = {
			name: newItem.name,
			order: accountGroups.length,
		};

		if (validate(validateAccountGroup, accountGroup, true)) {
			addAccountGroup(accountGroup).then((newAccountGroups) => {
				setAccountGroups(newAccountGroups);
				onFinish();
			});
		}
	};

	const handleDeleteAccountGroup = (accountGroupId) => {
		const accountGroupToDelete = accountGroups.find((ag) => ag._id === accountGroupId);
		setDeleteObject(accountGroupToDelete);
		setDeleteObjectTitle(titleCase.accountGroup);
		setShowConfirmation(true);
	};

	const editAccountGroup = (accountGroupId) => {
		setEditingAcount('');
		setEditingAcountGroup(accountGroupId);
		setEditingUser('');
	};

	const saveAccountGroups = (accountGroups) => {
		if (validate(validateAccountGroupList, accountGroups)) {
			updateAccountGroups(accountGroups).then((updatedAccountGroups) => {
				setAccountGroups(updatedAccountGroups);
			});
		}
	};

	const selectAccountGroup = (accountGroup, childType) => {
		getAccounts(accountGroup._id).then((accountsFromService) => {
			setAccounts(accountsFromService);
			setChildType(childType || children.accounts);
			setSelectedAccount(accountsFromService[0]?._id);
			setSelectedAccountGroup(accountGroup);
		});
	};

	const handleUpdateAccountGroup = (accountGroup, onFinish) => {
		if (validate(validateAccountGroup, accountGroup, false)) {
			updateAccountGroup(accountGroup).then((accountGroups) => {
				const accountGroupWithChanges = accountGroups.find((ag) => ag._id === accountGroup._id);
				setAccountGroups(accountGroups);
				setEditingAcount('');
				setEditingAcountGroup('');
				setEditingUser('');
				selectAccountGroup(accountGroupWithChanges);

				if (!isNullOrUndefined(onFinish)) {
					onFinish();
				}
			});
		}
	};

	const handleAddAccount = (account, onFinish) => {
		adjustAccountFields(account);
		account.accountGroupId = selectedAccountGroup._id;
		account.order = accounts.length;

		if (validate(validateAccount, account, true)) {
			addAccount(account).then((savedAccounts) => {
				setAccounts(savedAccounts);
				onFinish();
			});
		}
	};

	const handleDeleteAccount = (accountId) => {
		const accountToDelete = accounts.find((a) => a._id === accountId);
		setDeleteObject(accountToDelete);
		setDeleteObjectTitle(titleCase.account);
		setShowConfirmation(true);
	};

	const hideConfirmModal = () => {
		setShowConfirmation(false);
		setDeleteObject(null);
		setDeleteObjectTitle(titleCase.empty);
	};

	const handleConfirmDelete = () => {
		if (deleteObjectTitle === titleCase.account) {
			deleteAccount(deleteObject).then((updatedAccounts) => {
				setAccounts(updatedAccounts);
				hideConfirmModal();
			});
		} else if (deleteObjectTitle === titleCase.accountGroup) {
			deleteAccountGroup(deleteObject).then((updatedAccountGroups) => {
				setAccountGroups(updatedAccountGroups);
				selectAccountGroup(updatedAccountGroups[0]);
				hideConfirmModal();
			});
		}
	};

	const editAccount = (accountId) => {
		setEditingAcount(accountId);
		setEditingAcountGroup('');
		setEditingUser('');
	};

	const saveAccounts = (accounts) => {
		accounts.forEach((account) => {
			adjustAccountFields(account);
		});
		if (validate(validateAccountList, accounts)) {
			updateAccounts(accounts).then((updatedAccounts) => {
				setAccounts(updatedAccounts);
			});
		}
	};

	const handleUpdateAccount = (account) => {
		adjustAccountFields(account);
		if (validate(validateAccount, account, false)) {
			updateAccount(account).then((updatedAccounts) => {
				setAccounts(updatedAccounts);
				setEditingAcount('');
			});
		}
	};

	const adjustAccountFields = (account) => {
		if (!isNullOrEmptyString(account.balance)) {
			account.explodedBalance = blowUpNumber(account.balance);
		}
		delete account.balance;

		if (!isNullOrEmptyString(account.creditLimit)) {
			account.explodedCreditLimit = blowUpNumber(account.creditLimit);
		}
		delete account.creditLimit;
	};

	const displayUsers = (accountGroupId) => {
		const accountGroup = accountGroups.find((ag) => ag._id === accountGroupId);
		selectAccountGroup(accountGroup, children.users);
		getAccountGroupUsers(accountGroup._id).then((agu) => {
			setSelectedUser(agu[0]._id);
			setAccountGroupUsers(agu);
		});
	};

	const handleSaveAccountGroupUser = (accountGroupUser, onFinish) => {
		clearMessages();

		accountGroupUser.accountGroupId = selectedAccountGroup._id;
		const errors = validateAccountGroupToUser(accountGroupUser, isNullOrUndefined(accountGroupUser._id));
		if (!isNullOrEmptyObject(errors)) {
			setMessages(errors, messageType.error, 'Please fix the following:');
			return;
		}

		const hasExistingUser = accountGroupUsers.findIndex((u) => u.emailAddress === accountGroupUser.emailAddress && u._id !== accountGroupUser._id) !== -1;
		if (hasExistingUser) {
			setMessages('There is already a user with that email address associated with this account group.', messageType.error);
			return;
		}

		saveAccountGroupUser(accountGroupUser).then((agu) => {
			const users = copy(accountGroupUsers);
			const userIndex = users.findIndex((u) => u._id === agu._id);
			if (userIndex === -1) {
				users.push(agu);
			} else {
				users[userIndex] = agu;
			}

			setAccountGroupUsers(users);
			setSelectedUser('');
		});
		onFinish();
	};

	const handleDeleteAccountGroupUser = (accountGroupUserId) => {
		deleteAccountGroupUser(selectedAccountGroup._id, accountGroupUserId).then(() => {
			const users = accountGroupUsers.filter((u) => u._id !== accountGroupUserId);
			setAccountGroupUsers(users);
		});
	};

	const editUser = (accountGroupUserId) => {
		setEditingAcount('');
		setEditingAcountGroup('');
		setEditingUser(accountGroupUserId);
	};

	const validate = (validationFunction, data, isNew) => {
		clearMessages();
		const errors = validationFunction(data, isNew);

		if (!isNullOrEmptyObject(errors)) {
			setMessages(errors, messageType.error);
			return;
		}

		return true;
	};

	useEffect(() => {
		getAccountGroups().then((newAccountGroups) => {
			setAccountGroups(newAccountGroups);
			if (newAccountGroups.length > 0) {
				selectAccountGroup(newAccountGroups[0]);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const agOpts = createOptionArray(accountGroups, 'name', '_id');
		setAccountGroupsOptions(agOpts);
	}, [accountGroups]);

	return (
		<div className="row">
			<div className="col-xs-6">
				<div className="panel panel-primary">
					<div className="panel-heading">Edit Account Groups</div>
					<div className="panel-body">
						<EditableListComponent
							items={accountGroups}
							isSortable={true}
							selectedItem={selectedAccountGroup._id}
							onListChanged={saveAccountGroups}
							onSelectItem={selectAccountGroup}
							onAddItem={handleAddAccountGroup}
							onDeleteItem={handleDeleteAccountGroup}
							onEditItem={editAccountGroup}
							onUpdateItem={handleUpdateAccountGroup}
							editing={editingAccountGroup}
							listType={listTypes.accountGroup}
							onDisplayUsers={displayUsers}
						/>
					</div>
				</div>
			</div>
			{isNullOrEmptyString(selectedAccountGroup._id) ? (
				''
			) : (
				<div className="col-xs-6">
					{childType === children.accounts ? (
						<div className="panel panel-primary">
							<div className="panel-heading">Edit {accountGroups.find((ag) => ag._id === selectedAccountGroup._id)?.name} Accounts</div>
							<div className="panel-body">
								<EditableListComponent
									items={accounts}
									isSortable={true}
									selectedItem={selectedAccount}
									onListChanged={saveAccounts}
									onSelectItem={setSelectedAccount}
									onAddItem={handleAddAccount}
									onDeleteItem={handleDeleteAccount}
									onEditItem={editAccount}
									onUpdateItem={handleUpdateAccount}
									editing={editingAccount}
									listType={listTypes.account}
									accountGroupsOptions={accountGroupsOptions}
									selectedAccountGroup={selectedAccountGroup._id}
								/>
							</div>
						</div>
					) : (
						''
					)}
					{childType === children.users ? (
						<div className="panel panel-primary">
							<div className="panel-heading">{selectedAccountGroup.name} Account Group Users</div>
							<div className="panel-body">
								<EditableListComponent
									items={accountGroupUsers}
									selectedItem={selectedUser}
									onSelectItem={setSelectedUser}
									onAddItem={handleSaveAccountGroupUser}
									onDeleteItem={handleDeleteAccountGroupUser}
									onEditItem={editUser}
									onUpdateItem={handleSaveAccountGroupUser}
									editing={editingUser}
									listType={listTypes.users}
								/>
							</div>
						</div>
					) : (
						''
					)}
				</div>
			)}
			<ConfirmComponent
				isOpen={showConfirmation}
				title={`Delete ${deleteObjectTitle}`}
				confirmButtonText="Delete"
				confirmButtonClass="danger"
				cancelButtonText="Cancel"
				onCancel={hideConfirmModal}
				onConfirm={handleConfirmDelete}>
				<div>
					Are you sure you want to delete {deleteObjectTitle.toLowerCase()}: {deleteObject?.name}? This action cannot be undone.
				</div>
			</ConfirmComponent>
		</div>
	);
};

export default AccountGroupsComponent;
