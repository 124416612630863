import { isNullOrEmptyString } from '@chris-crap-packages/utilities';
import PropTypes from 'prop-types';
import React from 'react';
import { NumericFormat } from 'react-number-format';

import { getAccountGroups } from '../../../../services/AccountGroupService';
import { getAccounts } from '../../../../services/AccountService';
import DateDisplayComponent from '../../../common/DateDisplayComponent';
import CheckboxComponent from '../../../common/forms/CheckboxComponent';
import TextWithIconComponent from '../../../common/TextWithIconComponent';
import RecurrenceEditComponent from './RecurrenceEditComponent';

class RecurringTransactionsListComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			accounts: {},
		};

		this.setAccounts = setAccounts.bind(this);
	}

	componentDidMount() {
		this.setAccounts();
	}

	render() {
		return (
			<table className='table table-striped'>
				<thead>
					<tr>
						<th className='bg-primary'>
							<div className='row'>
								<div className='col-xs-6 col-md-2'>Recording Date</div>
								<div className='col-xs-6 col-md-4'>Record Every</div>
								<div className='col-xs-12 col-md-6'>Transaction</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{this.props.recurrences.map((recurrence) => {
						return (
							<tr key={recurrence._id}>
								<td>
									{this.props.editingId === recurrence._id ? (
										<RecurrenceEditComponent
											recurrence={recurrence}
											formErrors={this.props.formErrors}
											onCancel={this.props.onCancel}
											onSubmit={this.props.onSubmit}
											isSaving={this.props.isSaving}
										/>
									) : (
										<div className='row'>
											<div className='col-xs-12 text-right'>
												<CheckboxComponent
													onClick={() => {
														this.props.onToggleRecordToday(recurrence);
													}}
													isChecked={recurrence.recordToday}>
													Record Today:
												</CheckboxComponent>
												<a
													href='/'
													onClick={(e) => {
														e.preventDefault();
														this.props.onEdit(recurrence._id);
													}}>
													<TextWithIconComponent iconName='pencil-alt' />
												</a>
												<a
													href='/'
													onClick={(e) => {
														e.preventDefault();
														this.props.onDelete(recurrence._id);
													}}
													className='text-danger'>
													<TextWithIconComponent iconName='trash' />
												</a>
											</div>
											<div className='col-xs-6 col-md-2'>
												<DateDisplayComponent dateString={recurrence.recordingDate} />
											</div>
											<div className='col-xs-6 col-md-4'>
												{recurrence.periodCount} {recurrence.period}
											</div>
											<div className='col-xs-12 col-md-6'>
												<em>Date:</em> <DateDisplayComponent dateString={recurrence.transaction.date} />
												<br />
												<em>Amount:</em>{' '}
												<NumericFormat
													value={recurrence.transaction.amount}
													thousandSeparator=','
													decimalScale={2}
													fixedDecimalScale={true}
													prefix='$'
													displayType='text'
												/>
												<br />
												<em>Payee:</em> {recurrence.transaction.payee}
												<br />
												{isNullOrEmptyString(recurrence.transaction.description) ? (
													''
												) : (
													<span>
														<em>Description:</em> {recurrence.transaction.description}
														<br />
													</span>
												)}
												<em>From Account:</em> {this.state.accounts[recurrence.transaction.accountId]}
												<br />
												<em>Category:</em> {recurrence.transaction.categoryName}
											</div>
										</div>
									)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}
}

function setAccounts() {
	const accountList = {};
	getAccountGroups().then((accountGroups) => {
		accountGroups.forEach((accountGroup) => {
			getAccounts(accountGroup._id).then((accounts) => {
				accounts.forEach((account) => {
					accountList[account._id] = `${accountGroup.name} / ${account.name}`;
					this.setState({
						accounts: accountList,
					});
				});
			});
		});
	});
}

RecurringTransactionsListComponent.propTypes = {
	editingId: PropTypes.string,
	formErrors: PropTypes.object,
	isSaving: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onToggleRecordToday: PropTypes.func.isRequired,
	recurrences: PropTypes.array.isRequired,
};

export default RecurringTransactionsListComponent;
