import { isNil } from 'lodash';
import * as Uuid from 'uuid';

const watchers = {};

// TODO: could this entire service be eliminated with context?
export function baseFireWatchers(group) {
	const forgotten = [];

	if (!isNil(watchers[group])) {
		for (const key in watchers[group]) {
			if (Object.prototype.hasOwnProperty.call(watchers[group], key)) {
				if (!isNil(watchers[group][key])) {
					watchers[group][key]();
				} else {
					forgotten.push(key);
				}
			}
		}
	}

	forgotten.forEach((orphan) => {
		delete watchers[group][orphan];
	});
}

export function baseAddWatcher(group, watcherFunction) {
	const key = Uuid.v4();
	watchers[group] = watchers[group] || {};
	watchers[group][key] = watcherFunction;

	return key;
}

export function baseRemoveWatcher(group, key) {
	delete watchers[group][key];
}
