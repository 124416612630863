import PropTypes from 'prop-types';
import React, { useState } from 'react';

import TextWithIconComponent from '../../common/TextWithIconComponent';

const ReportPanel = ({ title, children, startCollapsed = true }) => {
	const [isCollapsed, setIsCollapsed] = useState(startCollapsed);

	return (
		<div className="row">
			<div className="col-xs-12">
				<div className="panel panel-primary">
					<div className="panel-heading clickable" onClick={() => setIsCollapsed(!isCollapsed)}>
						<TextWithIconComponent iconName={isCollapsed ? 'angle-right' : 'angle-down'} />
						{title}
					</div>
					<div className="panel-body">{!isCollapsed && children}</div>
				</div>
			</div>
		</div>
	);
};

ReportPanel.propTypes = {
	children: PropTypes.object.isRequired,
	startCollapsed: PropTypes.bool,
	title: PropTypes.string.isRequired,
};

export default ReportPanel;
