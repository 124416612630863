import CurrentSubNavComponent from './CurrentSubNavComponent';
import SubNavLinksComponent from './SubNavLinksComponent';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import PropTypes from 'prop-types';

const SubNavComponent = ({ subNavList, activeItemId, showSubNav, toggleClick }) => {
	const { logout } = useKindeAuth();

	const handleLogout = (e) => {
		e.preventDefault();
		logout();
	};

	if (subNavList.length > 4) {
		return (
			<nav className="sub-nav">
				<a href="/" onClick={handleLogout} className="pull-right" data-test-id="log-out-link">
					Log Out
				</a>
				<CurrentSubNavComponent activeItemId={activeItemId} subNavList={subNavList} iconName={showSubNav ? 'minus-square' : 'plus-square'} onClick={toggleClick} />
				<SubNavLinksComponent activeItemId={activeItemId} containerClass="sub-nav-links" isVisible={showSubNav} subNavList={subNavList} onClick={toggleClick} />
			</nav>
		);
	}

	return (
		<nav className="sub-nav">
			<a href="/" onClick={handleLogout} className="pull-right" data-test-id="log-out-link">
				Log Out
			</a>
			<SubNavLinksComponent activeItemId={activeItemId} isVisible={true} subNavList={subNavList} />
		</nav>
	);
};

SubNavComponent.propTypes = {
	activeItemId: PropTypes.string,
	showSubNav: PropTypes.bool,
	subNavList: PropTypes.array.isRequired,
	toggleClick: PropTypes.func.isRequired,
};

export default SubNavComponent;
