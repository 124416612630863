import PropTypes from 'prop-types';
import React from 'react';

import FeedbackMessageComponent from '../FeedbackMessageComponent';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import NavigationContainer from '../navigation/NavigationContainer';

const PageTemplate = ({ children }) => {
	return (
		<div className='container-fluid'>
			<HeaderComponent />
			<NavigationContainer />
			<FeedbackMessageComponent />
			<main>{children}</main>
			<FooterComponent />
		</div>
	);
};

PageTemplate.propTypes = {
	children: PropTypes.element.isRequired,
};

export default PageTemplate;
