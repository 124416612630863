import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { withLogin } from '../../hoc/withLogin';
import { navigationOccurred, navigationTo } from '../../services/NavigationService';
import PageTemplate from '../main/PageTemplate/PageTemplate';
import AdminCategoriesComponent from './AdminCategoriesComponent';
import AdminDashboard from './AdminDashboard';
import AdminReportCard from './AdminReportCard';

function AdminContainer() {
	const params = useParams();

	useEffect(() => {
		navigationOccurred({
			selectedNavId: 'admin',
			selectedSubNavId: params.detailArea,
			to: navigationTo.admin,
		});
	}, [params]);

	return (
		<PageTemplate>
			<PickSubComponent detailArea={params.detailArea} />
		</PageTemplate>
	);
}

function PickSubComponent({ detailArea }) {
	switch (detailArea) {
		case 'categories':
			return <AdminCategoriesComponent />;
		case 'dashboard':
			return <AdminDashboard />;
		case 'report-card':
			return <AdminReportCard />;
		default:
			return <div>I don&#39;t recognize the detailArea</div>;
	}
}

export default withLogin(AdminContainer);
