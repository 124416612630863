import PropTypes from 'prop-types';

import TextWithIconComponent from '../../TextWithIconComponent';
import ButtonComponent from '../ButtonComponent';

const FormActionButtonsComponent = ({ formName, onCancel }) => {
	return (
		<div>
			<ButtonComponent
				type={ButtonComponent.buttonTypes.submit}
				colorClass="primary"
				iconName="save"
				iconStyle={TextWithIconComponent.iconStyles.regular}
				text="Save"
				testId={`${formName}-form-save`}
			/>
			&nbsp;
			<ButtonComponent
				type={ButtonComponent.buttonTypes.cancel}
				colorClass="default"
				iconName="undo"
				text="Cancel"
				onClick={onCancel}
				testId={`${formName}-form-cancel`}
			/>
		</div>
	);
};

FormActionButtonsComponent.propTypes = {
	formName: PropTypes.string.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default FormActionButtonsComponent;
