import PropTypes from 'prop-types';
import React from 'react';

import { articleSlugs } from '../TableOfContents/TableOfContents';

const Settings = ({ onClickArticle }) => {
	return (
		<div>
			<h1>Settings</h1>
			<p>The settings tab contains various items that help you taylor your experience on Rivulet Finance to your needs. It includes:</p>
			<ul>
				<li>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							onClickArticle(articleSlugs.profile);
						}}
					>
						Profile
					</a>
				</li>
			</ul>
		</div>
	);
};

Settings.propTypes = {
	onClickArticle: PropTypes.func.isRequired,
};

export default Settings;
