import { copy } from '@chris-crap-packages/utilities';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getReportSettings, saveReportSettings } from '../../../services/ReportService';
import { selectActiveAccountGroupId } from '../../../state/activeItemSlice';
import ButtonComponent from '../../common/forms/ButtonComponent';
import CheckboxComponent from '../../common/forms/CheckboxComponent';
import TextWithIconComponent from '../../common/TextWithIconComponent';
import WaitingMessageComponent from '../../common/WaitingMessageComponent';

const BasicReportSettings = ({ reportType, onSettingsChange }) => {
	const [showReportSettings, setShowReportSettings] = useState(false);
	const [reportSettings, setReportSettings] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const accountGroupId = useSelector(selectActiveAccountGroupId);
	const [includeAllAccounts, setIncludeAllAccounts] = useState(false);

	const toggleVisible = () => {
		const isVisible = !showReportSettings;
		if (isVisible) {
			setIsLoading(true);
			getReportSettings(accountGroupId, reportType).then((settings) => {
				setReportSettings(settings);
				setIsLoading(false);
				setIncludeAllAccounts(settings.reportSettings.accounts.findIndex((account) => !account.include) === -1);
			});
		}
		setShowReportSettings(isVisible);
	};

	const handleIncludeAllAccounts = () => {
		const shouldInclude = !includeAllAccounts;
		setIncludeAllAccounts(shouldInclude);
		reportSettings.reportSettings.accounts.forEach((account) => {
			account.include = shouldInclude;
		});
	};

	const updateSettings = () => {
		setIsLoading(true);
		saveReportSettings(reportSettings).then(() => {
			setShowReportSettings(false);
			setIsLoading(false);
			onSettingsChange();
		});
	};

	return (
		<div className="row">
			<div className="col-xs-11">
				{showReportSettings && (
					<WaitingMessageComponent isLoading={isLoading}>
						<div className="col-xs-8">
							<strong>Accounts to include:</strong>
							<CheckboxComponent isChecked={includeAllAccounts} onClick={handleIncludeAllAccounts}>
								All Accounts
							</CheckboxComponent>
							{reportSettings &&
								reportSettings.reportSettings.accounts.map((account, index) => (
									<CheckboxComponent
										key={account.accountId}
										isChecked={account.include}
										onClick={() => {
											const settings = copy(reportSettings);
											account.include = !account.include;
											settings.reportSettings.accounts[index] = account;
											setReportSettings(settings);
										}}>
										{account.name}
									</CheckboxComponent>
								))}
						</div>
						<div className="col-xs-2">
							<div>
								{reportSettings && (
									<CheckboxComponent
										isChecked={reportSettings.reportSettings.includeAllCategories}
										onClick={() => {
											const settings = copy(reportSettings);
											settings.reportSettings.includeAllCategories = !settings.reportSettings.includeAllCategories;
											setReportSettings(settings);
										}}>
										Include $0 categories
									</CheckboxComponent>
								)}
							</div>
						</div>
						<div className="col-xs-2 text-right">
							<ButtonComponent type={ButtonComponent.buttonTypes.button} colorClass="primary" iconName="save" iconStyle={TextWithIconComponent.iconStyles.regular} text="Save" onClick={updateSettings} />
						</div>
					</WaitingMessageComponent>
				)}
			</div>
			<div className="col-xs-1 text-right">
				<span className="clickable" onClick={() => toggleVisible(!showReportSettings)}>
					<TextWithIconComponent iconName="cogs" />
				</span>
			</div>
		</div>
	);
};

BasicReportSettings.propTypes = {
	onSettingsChange: PropTypes.func.isRequired,
	reportType: PropTypes.string.isRequired,
};

export default BasicReportSettings;
