import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { withLogin } from '../../../hoc/withLogin';
import { navigationOccurred, navigationTo } from '../../../services/NavigationService';
import PageTemplate from '../../main/PageTemplate/PageTemplate.js';
import AccountGroupsComponent from './account-groups/AccountGroupsComponent';
import CategoriesComponent from './categories/CategoriesComponent';
import ProfileComponent from './profile/ProfileComponent';
import RecurringTransactionsComponent from './recurring-transactions/RecurringTransactionsComponent';

function SettingsContainer() {
	const params = useParams();

	useEffect(() => {
		navigationOccurred({
			selectedNavId: 'settings',
			selectedSubNavId: params.detailArea,
			to: navigationTo.settings,
		});
	}, [params]);

	return (
		<PageTemplate>
			<PickSubComponent detailArea={params.detailArea} />
		</PageTemplate>
	);
}

function PickSubComponent({ detailArea }) {
	switch (detailArea) {
		case 'account-groups':
			return <AccountGroupsComponent />;
		case 'categories':
			return <CategoriesComponent />;
		case 'profile':
			return <ProfileComponent />;
		case 'recurring-transactions':
			return <RecurringTransactionsComponent />;
		default:
			return <div>I don&#39;t recognize the detailArea</div>;
	}
}

export default withLogin(SettingsContainer);
