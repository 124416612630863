import { isNullOrUndefined } from '@chris-crap-packages/utilities';
import { createSlice } from '@reduxjs/toolkit';

const reportsSlice = createSlice({
	name: 'reports',
	initialState: { netWorth: {}, reportCardWeights: null },
	reducers: {
		netWorthUpdated(state, action) {
			state.netWorth[action.payload.accountGroupId] = action.payload.netWorth;
		},
		reportCardWeightsUpdated(state, action) {
			state.reportCardWeights = action.payload;
		},
	},
});

export const selectReportCardWeights = (state) => {
	return state.reports.reportCardWeights;
};

export const selectNetWorth = (state) => {
	if (isNullOrUndefined(state.activeItems.accountGroupId)) {
		return null;
	}

	return state.reports.netWorth[state.activeItems.accountGroupId];
};

export const { netWorthUpdated, reportCardWeightsUpdated } = reportsSlice.actions;

export const reportsReducer = reportsSlice.reducer;
