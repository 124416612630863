import PropTypes from 'prop-types';
import React from 'react';

export const DateInputGroupComponent = ({ isInvalid, titleAsPlaceholder, value, testId, title, onFieldChange }) => {
	const invalidClass = isInvalid ? 'invalid' : '';

	if (titleAsPlaceholder) {
		return (
			<div className="form-group">
				<input type="date" value={value?.split('T')[0]} className={`form-control ${invalidClass}`} placeholder={title} onChange={onFieldChange} data-test-id={testId} />
			</div>
		);
	}

	return (
		<div className="form-group">
			<label>{title}</label>
			<input type="date" value={value?.split('T')[0]} className={`form-control ${invalidClass}`} onChange={onFieldChange} data-test-id={testId} />
		</div>
	);
};

DateInputGroupComponent.propTypes = {
	isInvalid: PropTypes.bool,
	onFieldChange: PropTypes.func.isRequired,
	testId: PropTypes.string,
	title: PropTypes.string.isRequired,
	titleAsPlaceholder: PropTypes.bool,
	value: PropTypes.any,
};

export default DateInputGroupComponent;
