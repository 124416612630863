import TextWithIconComponent from '../../common/TextWithIconComponent';
import PropTypes from 'prop-types';
import React from 'react';

const SettingsToggle = ({ onToggleShowSettings }) => {
	return (
		<div className="row">
			<div className="col-xs-12 text-right">
				<span className="clickable" onClick={onToggleShowSettings}>
					<TextWithIconComponent iconName="cogs" />
				</span>
			</div>
		</div>
	);
};

SettingsToggle.propTypes = {
	onToggleShowSettings: PropTypes.func.isRequired,
};

export default SettingsToggle;
