import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { domCookie } from 'cookie-muncher';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import WaitingMessageComponent from '../components/common/WaitingMessageComponent.js';
import PageTemplate from '../components/main/PageTemplate/PageTemplate.js';
import { setAuth } from '../services/AwCrudService.js';
import { retrieveUser } from '../services/UserService.js';
import { selectAppUser } from '../state/userSlice.js';

export const withLogin = (WrappedComponent) => {
	const HOC = (props) => {
		const appUser = useSelector(selectAppUser);
		const { isAuthenticated, isLoading, getToken } = useKindeAuth();
		const [token, setToken] = useState('');
		const navigate = useNavigate();
		const [authCookie, setAuthCookie] = useState(null);

		const handleUnauthenticated = useCallback(() => {
			navigate('/logout');
		}, [navigate]);

		useEffect(() => {
			const storedAuthToken = domCookie.get('authToken');
			if (storedAuthToken) {
				setAuthCookie(storedAuthToken);
				setToken(storedAuthToken.value);
			}
		}, []);

		useEffect(() => {
			if (!isLoading) {
				if (isAuthenticated) {
					getToken().then((accessToken) => {
						setToken(accessToken);
					});
				} else {
					if (!authCookie) {
						handleUnauthenticated();
					}
				}
			}
		}, [isAuthenticated, getToken, isLoading, handleUnauthenticated, authCookie]);

		useEffect(() => {
			if (token !== '') {
				setAuth(token);
				retrieveUser();
			}
		}, [token]);

		if (!appUser) {
			return (
				<PageTemplate>
					<WaitingMessageComponent />
				</PageTemplate>
			);
		}

		return <WrappedComponent {...props} onUnauthenticated={handleUnauthenticated} />;
	};
	HOC.displayName = `withLogin(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
	HOC.propTypes = {
		hidePageTemplate: PropTypes.bool,
	};
	return HOC;
};
