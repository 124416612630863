import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { withLogin } from '../../hoc/withLogin';
import { navigationOccurred, navigationTo } from '../../services/NavigationService';
import { accountGroupActivated, selectActiveAccountGroupId } from '../../state/activeItemSlice';
import PageTemplate from '../main/PageTemplate/PageTemplate.js';
import ReportCard from './reportCard/ReportCard';
import ReportPanel from './ReportPanel';
import TransactionSummary from './transactionSummary/TransactionSummary';

function DashboardContainer() {
	const { accountGroupId: paramAccountGroupId } = useParams();
	const accountGroupId = useSelector(selectActiveAccountGroupId);
	const dispatch = useDispatch();

	useEffect(() => {
		if (paramAccountGroupId !== 'default') {
			dispatch(accountGroupActivated(paramAccountGroupId));
		}
		// TODO: navigate away from "default" accountGroupId, after accountGroups are in global state
		navigationOccurred({
			selectedNavId: accountGroupId,
			selectedSubNavId: 'dashboard',
			to: navigationTo.account,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paramAccountGroupId, accountGroupId]);

	return (
		<PageTemplate>
			<>
				<ReportPanel title="Report Card" startCollapsed={false}>
					<ReportCard />
				</ReportPanel>
				<ReportPanel title="Transaction Summary">
					<TransactionSummary />
				</ReportPanel>
			</>
		</PageTemplate>
	);
}

export default withLogin(DashboardContainer);
