import PropTypes from 'prop-types';
import React from 'react';

import TextWithIconComponent from '../../TextWithIconComponent';

const CheckboxComponent = ({ children, isChecked, onClick, iconPosition = TextWithIconComponent.iconPositions.right }) => {
	return (
		<span className="clickable" onClick={onClick}>
			<TextWithIconComponent
				iconName={`${isChecked ? 'check-' : ''}square`}
				iconPosition={iconPosition}
				iconStyle={TextWithIconComponent.iconStyles.regular}
			>
				{children}
			</TextWithIconComponent>
		</span>
	);
};

CheckboxComponent.propTypes = {
	children: PropTypes.any.isRequired,
	iconPosition: PropTypes.number,
	isChecked: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};

export default CheckboxComponent;
