import PropTypes from 'prop-types';
import React from 'react';

import UserDisplaySwitcherComponent from '../user/settings/UserDisplaySwitcherComponent';
import { listTypes } from '../user/settings/UserFormsSwitcherComponent';
import TextWithIconComponent from './TextWithIconComponent';

const ACTIONS = [
	{
		handler: 'onDeleteItem',
		iconName: 'trash',
		listType: [listTypes.account, listTypes.accountGroup, listTypes.category, listTypes.users],
		title: 'Delete',
	},
	{
		handler: 'onEditItem',
		iconName: 'pencil-alt',
		listType: [listTypes.account, listTypes.accountGroup, listTypes.category, listTypes.users],
		title: 'Edit',
	},
	{
		handler: 'onSelectItem',
		iconName: 'list',
		listType: [listTypes.accountGroup],
		title: 'Accounts',
	},
	{
		handler: 'onDisplayUsers',
		iconName: 'users',
		listType: [listTypes.accountGroup],
		title: 'Users',
	},
];

const EditableListItemComponent = ({
	accountGroups,
	isSortable,
	item,
	listType,
	onDeleteItem,
	onDisplayUsers,
	onEditItem,
	onMoveDown,
	onMoveUp,
	onSelectItem,
	onToggleAccountGroup,
}) => {
	return (
		<div className="row">
			{!isSortable ? (
				''
			) : (
				<div className="col-xs-2">
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							onMoveUp(item);
						}}>
						<TextWithIconComponent iconName="arrow-circle-up" />
					</a>
					&nbsp;
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							onMoveDown(item);
						}}>
						<TextWithIconComponent iconName="arrow-circle-down" />
					</a>
				</div>
			)}
			<div
				className={`col-xs-${isSortable ? '7' : '9'}`}
				onClick={() => {
					onSelectItem(item._id);
				}}>
				<UserDisplaySwitcherComponent accountGroups={accountGroups} item={item} listType={listType} onToggleAccountGroup={onToggleAccountGroup} />
			</div>
			<div className="col-xs-3">
				{ACTIONS.map((action, index) => {
					if (action.listType.indexOf(listType) !== -1) {
						return (
							<span
								key={index}
								title={action.title}
								className="pull-right clickable"
								onClick={() => {
									// eslint-disable-next-line no-eval
									eval(`${action.handler}(item._id)`);
								}}>
								&nbsp;
								<TextWithIconComponent iconName={action.iconName} />
							</span>
						);
					}

					return null;
				})}
			</div>
		</div>
	);
};

EditableListItemComponent.propTypes = {
	accountGroups: PropTypes.array,
	isSortable: PropTypes.bool,
	item: PropTypes.object.isRequired,
	listType: PropTypes.string.isRequired,
	onDeleteItem: PropTypes.func,
	onDisplayUsers: PropTypes.func,
	onEditItem: PropTypes.func.isRequired,
	onMoveDown: PropTypes.func.isRequired,
	onMoveUp: PropTypes.func.isRequired,
	onSelectItem: PropTypes.func.isRequired,
	onToggleAccountGroup: PropTypes.func,
};

export default EditableListItemComponent;
