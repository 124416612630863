import { copy, isNullOrEmptyObject, isNullOrUndefined } from '@chris-crap-packages/utilities';
import { validateReportCardWeights } from '@xenomite-packages/cash-flow-validator';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { clearMessages, messageType, setMessages } from '../../../services/FeedbackMessageService';
import { getReportCardWeights, saveReportCardWeights } from '../../../services/ReportService';
import { selectReportCardWeights } from '../../../state/reportsSlice';
import FormActionButtonsComponent from '../../common/forms/FormActionButtonsComponent/FormActionButtonsComponent';
import TextInputGroupComponent, { textInputTypes } from '../../common/forms/TextInputGroupComponent/TextInputGroupComponent';
import WaitingMessageComponent from '../../common/WaitingMessageComponent';

const formName = 'report-card-weights';

const AdminReportCard = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [formReportCardWeights, setFormReportCardWeights] = useState({});
	const [totalWeight, setTotalWeight] = useState(0);
	const [formErrors, setFormErrors] = useState({});
	const reportCardWeights = useSelector(selectReportCardWeights);

	const handleFieldChange = (event, field) => {
		event.preventDefault();
		const newWeights = copy(formReportCardWeights);
		newWeights[field] = event.target.value;
		setFormReportCardWeights(newWeights);
	};

	const handleCancelEditWeights = (e) => {
		e.preventDefault();
		setFormReportCardWeights(reportCardWeights);
	};

	const handleSaveReportCardWeights = async (event) => {
		event.preventDefault();
		clearMessages();
		setIsSaving(true);

		const errors = validateReportCardWeights(formReportCardWeights);

		setFormErrors(errors);

		if (!isNullOrEmptyObject(errors)) {
			setIsSaving(false);
			setMessages(errors, messageType.error, 'Please fix the following:');
			return;
		}

		await saveReportCardWeights(formReportCardWeights);
		setIsSaving(false);
	};

	useEffect(() => {
		if (isNullOrUndefined(reportCardWeights)) {
			getReportCardWeights();
		} else {
			setFormReportCardWeights(reportCardWeights);
			setIsLoading(false);
		}
	}, [reportCardWeights]);

	useEffect(() => {
		const total = Object.keys(formReportCardWeights).reduce((accumulator, currentValue) => parseInt(accumulator) + parseInt(formReportCardWeights[currentValue]), 0);
		setTotalWeight(total);
	}, [formReportCardWeights]);

	if (isLoading) {
		return <WaitingMessageComponent message="Loading report card weights" />;
	}

	if (isSaving) {
		return <WaitingMessageComponent message="Saving" />;
	}

	return (
		<div className="row">
			<div className="col-xs-12">
				<div className="panel panel-primary">
					<div className="panel-heading">Edit Report Card Weights</div>
					<div className="panel-body">
						<form onSubmit={handleSaveReportCardWeights}>
							<div className="row">
								<div className="col-xs-3">
									<TextInputGroupComponent
										title="Net Worth:*"
										type={textInputTypes.number}
										step={1}
										min={0}
										max={100}
										value={formReportCardWeights.netWorth || 0}
										addonTextAfter="%"
										isInvalid={!isNullOrUndefined(formErrors.netWorth)}
										onFieldChange={(e) => handleFieldChange(e, 'netWorth')}
										testId={`${formName}-form-net-worth`}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-xs-3">
									<TextInputGroupComponent
										title="Total:*"
										type={textInputTypes.number}
										disabled={true}
										step={1}
										min={0}
										max={100}
										value={totalWeight}
										addonTextAfter="%"
										isInvalid={!isNullOrUndefined(formErrors.total)}
										onFieldChange={() => {}}
										testId={`${formName}-form-total`}
									/>
								</div>
								<div className="col-xs-12 text-right">
									<FormActionButtonsComponent formName={formName} onCancel={handleCancelEditWeights} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminReportCard;
