import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import TextWithIconComponent from '../../common/TextWithIconComponent';
import TransactionSummaryItem from './TransactionSummaryItem';

const TransactionSummaryGroup = ({ group, printView }) => {
	const [collapsed, setCollapsed] = useState(true);

	return (
		<table className='table table-striped'>
			<thead>
				<tr onClick={() => setCollapsed(!collapsed)}>
					<th className='col-xs-10'>
						{!printView && <TextWithIconComponent iconName={collapsed ? 'angle-right' : 'angle-down'} />}
						{group.name}
					</th>
					<th className='col-xs-2 text-right'>
						<NumericFormat
							value={group.amount}
							thousandSeparator=','
							decimalScale={2}
							fixedDecimalScale={true}
							prefix='$'
							displayType='text'
						/>
					</th>
				</tr>
			</thead>
			{(!collapsed || printView) && (
				<tbody>
					{group.categories.map((category) => (
						<TransactionSummaryItem item={category} key={category.name} />
					))}
				</tbody>
			)}
		</table>
	);
};

TransactionSummaryGroup.propTypes = {
	group: PropTypes.shape({
		amount: PropTypes.number.isRequired,
		categories: PropTypes.arrayOf(
			PropTypes.shape({
				amount: PropTypes.number.isRequired,
				name: PropTypes.string.isRequired,
			})
		).isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	printView: PropTypes.bool,
};

export default TransactionSummaryGroup;
