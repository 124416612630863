import { permissionLevels } from '@xenomite-packages/cash-flow-globals';
import PropTypes from 'prop-types';
import React from 'react';

import TextWithIconComponent from '../common/TextWithIconComponent';

class TransactionDetailActionComponent extends React.Component {
	render() {
		if (this.props.permissionLevel === permissionLevels.edit) {
			return;
		}

		return (
			<span>
				<a
					href="/"
					onClick={(e) => {
						e.preventDefault();
						this.props.onEdit(this.props.transactionId);
					}}>
					<TextWithIconComponent iconName="pencil-alt" />
				</a>
				<a
					href="/"
					onClick={(e) => {
						e.preventDefault();
						this.props.onDelete(this.props.transactionId);
					}}
					className="text-danger">
					<TextWithIconComponent iconName="trash" />
				</a>
			</span>
		);
	}
}

TransactionDetailActionComponent.propTypes = {
	onDelete: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	permissionLevel: PropTypes.number.isRequired,
	transactionId: PropTypes.string.isRequired,
};

export default TransactionDetailActionComponent;
