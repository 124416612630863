import { isNullOrUndefined } from '@chris-crap-packages/utilities';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import TextInputGroupComponent from '../TextInputGroupComponent';
import { textInputTypes } from '../TextInputGroupComponent/TextInputGroupComponent';

let preventBlur = false;

const AutoSuggestComponent = ({ autoFocus, domId, isInvalid, onBlur, onClearSuggestions, onFieldChange, onItemSelected, renderItem, suggestions, testId, title, titleAsPlaceholder, value }) => {
	const [selectedSuggestion, setSelectedSuggestion] = useState(null);

	const chooseSelected = (selectedItem) => {
		setSelectedSuggestion(selectedItem);
		preventBlur = false;
		document.getElementById(domId).focus();
		setTimeout(finalizeSelection);
	};

	function updateSelected(event) {
		const keys = {
			down: 40,
			escape: 27,
			up: 38,
		};
		let selectedItem = selectedSuggestion;

		if (event.which === keys.down) {
			if (isNullOrUndefined(selectedItem)) {
				selectedItem = 0;
			} else {
				selectedItem++;
			}
			if (selectedItem > suggestions.length - 1) {
				selectedItem = 0;
			}
		} else if (event.which === keys.up) {
			if (isNullOrUndefined(selectedItem)) {
				selectedItem = suggestions.length - 1;
			} else {
				selectedItem--;
			}
			if (selectedItem < 0) {
				selectedItem = suggestions.length - 1;
			}
		} else if (event.which === keys.escape) {
			selectedItem = null;
			onClearSuggestions();
		}

		setSelectedSuggestion(selectedItem);
	}

	function finalizeSelection() {
		if (!preventBlur) {
			const selectedItem = isNullOrUndefined(selectedSuggestion) ? null : suggestions[selectedSuggestion];
			onItemSelected(selectedItem);
		}
	}

	useEffect(() => {
		setSelectedSuggestion(null);
	}, [suggestions]);

	return (
		<div className={`auto-suggest${suggestions.length > 0 ? ' suggestions-visible' : ''}`} onBlur={finalizeSelection}>
			<TextInputGroupComponent
				title={title}
				titleAsPlaceholder={titleAsPlaceholder}
				domId={domId}
				autoFocus={autoFocus}
				type={textInputTypes.text}
				value={value}
				isInvalid={isInvalid}
				onFieldChange={onFieldChange}
				onKeyUp={updateSelected}
				testId={testId}
				onBlur={() => {
					setTimeout(onBlur, 100);
				}}
			/>
			<div className="result-list">
				{suggestions.map((suggestion, index) => {
					return (
						<div
							key={index}
							className={`clickable${selectedSuggestion === index ? ' selected' : ''}`}
							onMouseDown={() => {
								preventBlur = true;
							}}
							onClick={() => {
								chooseSelected(index);
							}}>
							{renderItem(suggestion)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

AutoSuggestComponent.propTypes = {
	autoFocus: PropTypes.bool.isRequired,
	domId: PropTypes.string.isRequired,
	isInvalid: PropTypes.bool,
	onBlur: PropTypes.func,
	onClearSuggestions: PropTypes.func.isRequired,
	onFieldChange: PropTypes.func.isRequired,
	onItemSelected: PropTypes.func.isRequired,
	renderItem: PropTypes.func.isRequired,
	suggestions: PropTypes.array.isRequired,
	testId: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	titleAsPlaceholder: PropTypes.bool,
	value: PropTypes.string,
};

export default AutoSuggestComponent;
