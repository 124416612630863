import PropTypes from 'prop-types';
import React from 'react';

import TextWithIconComponent from '../../common/TextWithIconComponent';

const CurrentSubNavComponent = ({ subNavList, activeItemId, onClick, iconName }) => {
	if (subNavList.length > 0) {
		const foundItem = subNavList.find((item) => {
			return item.id === activeItemId;
		});

		return (
			<a
				href="/"
				className="active-sub-nav"
				onClick={(e) => {
					e.preventDefault();
					onClick();
				}}
			>
				<TextWithIconComponent iconName={iconName}>{foundItem?.name}</TextWithIconComponent>
			</a>
		);
	}

	return null;
};

CurrentSubNavComponent.propTypes = {
	activeItemId: PropTypes.string.isRequired,
	iconName: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	subNavList: PropTypes.array,
};

export default CurrentSubNavComponent;
