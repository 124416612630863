import { isNullOrEmptyString } from '@chris-crap-packages/utilities';
import PropTypes from 'prop-types';
import React from 'react';
import { NumericFormat } from 'react-number-format';

import DateDisplayComponent from '../common/DateDisplayComponent';
import CheckboxComponent from '../common/forms/CheckboxComponent';
import TransactionDetailActionComponent from './TransactionDetailActionComponent';

export default function TransactionDetailComponent({ onEdit, onDelete, onToggleCleared, permissionLevel, transaction }) {
	return (
		<div className="row">
			<div className="col-xs-1">
				<DateDisplayComponent dateString={transaction.date} />
			</div>
			<div className="col-xs-3">
				{transaction.payee}
				{!isNullOrEmptyString(transaction.description) ? (
					<div>
						<em>{transaction.description}</em>
					</div>
				) : (
					''
				)}
			</div>
			<div className="col-xs-2 ">{transaction.categoryName}</div>
			<div className="col-xs-2">
				<CheckboxComponent
					onClick={() => {
						onToggleCleared(transaction._id);
					}}
					isChecked={transaction.cleared}
				>
					Cleared:
				</CheckboxComponent>
			</div>
			<div className="col-xs-1 text-right">
				<NumericFormat
					value={transaction.amount}
					thousandSeparator=","
					decimalScale={2}
					fixedDecimalScale={true}
					prefix="$"
					displayType="text"
				/>
			</div>
			<div className="col-xs-2 text-right">
				{isNullOrEmptyString(transaction.runningBalance) ? (
					''
				) : (
					<NumericFormat
						value={transaction.runningBalance}
						thousandSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix="$"
						displayType="text"
					/>
				)}
			</div>
			<div className="col-xs-1 text-right">
				<TransactionDetailActionComponent
					permissionLevel={permissionLevel}
					onEdit={onEdit}
					onDelete={onDelete}
					transactionId={transaction._id}
				/>
			</div>
		</div>
	);
}

TransactionDetailComponent.propTypes = {
	onDelete: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onToggleCleared: PropTypes.func.isRequired,
	permissionLevel: PropTypes.number.isRequired,
	transaction: PropTypes.object.isRequired,
};
