import React from 'react';

const SettingsAccounts = () => {
	return (
		<div>
			<h1>Accounts</h1>
			<p>
				Accounts form the bedrock of Rivulet Finance's financial management system. They allow you to track any number of things from bank
				accounts (checking, savings, credit cards, etc.) to cash to hard assets. You'll need at least one account to begin using Rivulet
				Finance.
			</p>
			<p>When creating or editing an account:</p>
			<ul>
				<li>Select the account group the account should be a part of (defaults to the current account group)</li>
				<li>Give the account a name</li>
				<li>Enter the current balance of the account</li>
				<li>
					Select the account type. Assets are any item that you have purchased, liabilities are anything that you owe someone else for. For
					example, if you finance the purchase of a car, the car would would be the asset and the loan would be the liability
				</li>
				<li>
					Select the account sub-type
					<ul>
						<li>
							For assets, you can choose illiquid - things that are hard to get the value out of like a car, house, or furniture - or
							liquid - things that are easy to get the value out of like a checking account or cash
						</li>
						<li>
							For liabilities, you can choose installment - where you make a single purchase and pay it off over time, like a car loan
							or a mortgage - or revolving - where you may make multiple purchases and your payment may change, based on your balance
							and current interest rate
						</li>
						<li>If this is a revolving liability, you will also need to enter your credit limit</li>
					</ul>
				</li>
				<li>
					Indicate if this is an account you would like to see on the mobile app; each user that an account group is shared with can choose
					which accounts they would like to see on the mobile app
				</li>
			</ul>
		</div>
	);
};

export default SettingsAccounts;
