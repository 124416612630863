import { Chart } from 'chart.js/auto';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getNetWorthSnapshots } from '../../../../services/ReportService.js';
import { selectActiveAccountGroupId } from '../../../../state/activeItemSlice.js';

// putting this here because it might become a setting. Also, magic numbers are bad
const snapshotCount = 24;

const NetWorthOverTime = () => {
	const accountGroupId = useSelector(selectActiveAccountGroupId);
	const [chartLabels, setChartLabels] = useState([]);
	const [chartAmounts, setChartAmounts] = useState([]);
	const [chartTargets, setChartTargets] = useState([]);
	const [chart, setChart] = useState();

	const drawChart = () => {
		if (chart) {
			chart.clear();
			chart.destroy();
		}

		if (chartAmounts.length > 0 && chartLabels.length > 0) {
			const ctx = document.getElementById('netWorthOverTimeCanvas');
			const lineChart = new Chart(ctx, {
				data: {
					datasets: [
						{
							label: 'Net Worth',
							data: chartAmounts,
							fill: false,
						},
						{
							label: 'Target',
							data: chartTargets,
							fill: false,
						},
					],
					labels: chartLabels,
				},
				type: 'line',
			});
			setChart(lineChart);
		}
	};

	useEffect(() => {
		getNetWorthSnapshots(accountGroupId, snapshotCount, true).then((snapshots) => {
			const labels = [];
			const amounts = [];
			const targets = [];

			snapshots.forEach((s) => {
				labels.push(s.month);
				amounts.push(s.netWorth.total);
				targets.push(s.netWorth.target);
			});

			setChartLabels(labels);
			setChartAmounts(amounts);
			setChartTargets(targets);
		});
	}, [accountGroupId]);

	useEffect(() => {
		drawChart();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartAmounts, chartLabels]);

	useEffect(() => {
		return () => {
			chart?.destroy();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div style={{ marginTop: 16, backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
			<h3 className="text-center">Net Worth Over Time</h3>
			<canvas id="netWorthOverTimeCanvas"></canvas>
		</div>
	);
};

export default NetWorthOverTime;
