import { copy } from '@chris-crap-packages/utilities';
import { categoryFields } from '@xenomite-packages/cash-flow-globals';

import { createOptionArray } from '../Utilities';
import { createOnServer, deleteFromServer, retrieveFromServer, updateOnServer } from './AwCrudService';

export function addCategory(scope, categoryInfo, isAdmin) {
	isAdmin = isAdmin || false;
	return createOnServer(`/categories/${isAdmin}/${scope}/`, categoryInfo);
}

export function associateAccountGroup(accountGroupId, categoryId) {
	return createOnServer('/categories/associate', { accountGroupId, categoryId }).then(() => {
		return getCategories(categoryFields.userCategories);
	});
}

export function deleteCategory(categoryId) {
	return deleteFromServer(`/categories/${categoryId}`);
}

export function disassociateAccountGroup(accountGroupId, categoryId) {
	return createOnServer('/categories/disassociate', { accountGroupId, categoryId }).then(() => {
		return getCategories(categoryFields.userCategories);
	});
}

export function getCategories(scope, id, isAdmin) {
	id = id || '';
	isAdmin = isAdmin || false;
	return retrieveFromServer(`/categories/${isAdmin}/${scope}/${id}`);
}

export function getCategoryNameSuggestions(term) {
	return retrieveFromServer(`/categories/suggest/${term}`);
}

export function getCategoryOptions(accountGroupId) {
	return new Promise((resolve) => {
		getCategories(categoryFields.accountGroup, accountGroupId).then((categories) => {
			const categoryOptions = createOptionArray(categories, 'name', '_id');
			resolve(categoryOptions);
		});
	});
}

export function getNewCategory(accountGroups) {
	return {
		accountGroups: copy(accountGroups),
		name: '',
	};
}

export function updateCategory(scope, categoryInfo, isAdmin) {
	isAdmin = isAdmin || false;
	return updateOnServer(`/categories/${isAdmin}/${scope}/`, categoryInfo);
}
