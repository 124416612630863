import { copy } from '@chris-crap-packages/utilities';
import { validateUserInfo } from '@xenomite-packages/cash-flow-validator';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { clearMessages, messageType, setMessages } from '../../../../services/FeedbackMessageService';
import { updateUser } from '../../../../services/UserService';
import { selectAppUser } from '../../../../state/userSlice';
import ButtonComponent from '../../../common/forms/ButtonComponent';
import DateInputGroupComponent from '../../../common/forms/DateInputGroupComponent';
import TextInputGroupComponent, { textInputTypes } from '../../../common/forms/TextInputGroupComponent';
import WaitingMessageComponent from '../../../common/WaitingMessageComponent';

const ProfileComponent = () => {
	const [submittingForm, setSubmittingForm] = useState(false);
	const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', emailAddress: '' });
	const [formErrors, setFormErrors] = useState({});
	const user = useSelector(selectAppUser);

	const saveChanges = (event) => {
		event.preventDefault();
		clearMessages();
		const errors = validateUserInfo(userInfo, user);

		if (!isEmpty(errors)) {
			setMessages(errors, messageType.error, 'Please correct the following:');
			setFormErrors(errors);
		} else {
			setFormErrors({});
			setSubmittingForm(true);
			updateUser({ ...userInfo, infoUpdated: true }).then(() => {
				setSubmittingForm(false);
			});
		}
	};

	const updateActiveUser = async () => {
		setUserInfo(user);
	};

	const updateUserField = (event, field) => {
		const updatedUserInfo = copy(userInfo);
		updatedUserInfo[field] = event.target.value;
		setUserInfo(updatedUserInfo);
	};

	const cancelChanges = async () => {
		await updateActiveUser();
	};

	useEffect(() => {
		updateActiveUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		(async () => {
			clearMessages();
			if (!userInfo.infoUpdated) {
				setMessages('Please confirm your profile information is correct, then click save to continue.', messageType.warning);
			}
		})();
	}, [userInfo]);

	if (submittingForm) {
		return <WaitingMessageComponent />;
	}

	return (
		<div className="row">
			<div className="col-xs-12 col-md-8 col-md-offset-2">
				<form onSubmit={saveChanges}>
					<div className="panel panel-primary">
						<div className="panel-heading">Edit My Info</div>
						<div className="panel-body">
							<div className="row">
								<div className="col-xs-12 col-md-6">
									<TextInputGroupComponent
										title="First Name:*"
										testId="user-first-name"
										type={textInputTypes.text}
										value={userInfo.firstName}
										isInvalid={!isNil(formErrors.firstName)}
										onFieldChange={(event) => {
											updateUserField(event, 'firstName');
										}}
									/>
								</div>
								<div className="col-xs-12 col-md-6">
									<TextInputGroupComponent
										title="Last Name:*"
										testId="user-last-name"
										type={textInputTypes.text}
										value={userInfo.lastName}
										isInvalid={!isNil(formErrors.lastName)}
										onFieldChange={(event) => {
											updateUserField(event, 'lastName');
										}}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-xs-6">
									<TextInputGroupComponent
										title="Email Address:*"
										testId="user-email-address"
										type={textInputTypes.email}
										value={userInfo.emailAddress}
										isInvalid={!isNil(formErrors.emailAddress)}
										onFieldChange={(event) => {
											updateUserField(event, 'emailAddress');
										}}
									/>
								</div>
								<div className="col-xs-6">
									<DateInputGroupComponent
										title="Birthdate:*"
										testId="user-birthdate"
										value={userInfo.dateOfBirth || ''}
										isInvalid={!isNil(formErrors.dateOfBirth)}
										onFieldChange={(event) => {
											updateUserField(event, 'dateOfBirth');
										}}
									/>
								</div>
							</div>
						</div>
						<div className="panel-footer text-right">
							<ButtonComponent colorClass="primary" iconName="check" text="Save" type={ButtonComponent.buttonTypes.submit} testId="submit-user-button" />
							&nbsp;
							<ButtonComponent colorClass="default" iconName="undo" text="Cancel" onClick={cancelChanges} testId="cancel-submit-user-button" />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ProfileComponent;
