import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const TransactionSummaryItem = ({ item }) => {
	return (
		<tr>
			<td className='col-xs-10'>{item.name}</td>
			<td className='col-xs-2 text-right'>
				<NumericFormat value={item.amount} thousandSeparator=',' decimalScale={2} fixedDecimalScale={true} prefix='$' displayType='text' />
			</td>
		</tr>
	);
};

TransactionSummaryItem.propTypes = {
	item: PropTypes.shape({
		amount: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
	}),
};

export default TransactionSummaryItem;
