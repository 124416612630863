import { copy } from '@chris-crap-packages/utilities';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const SelectInputGroupComponent = ({ blankLabel, isInvalid, onFieldChange, options, title, titleAsPlaceholder, value }) => {
	const invalidClass = isInvalid ? 'invalid' : '';
	const [optionsList, setOptionsList] = useState([]);

	useEffect(() => {
		if (options && options.length > 0) {
			let newOptionsList = copy(options);
			if (options.length > 1) {
				newOptionsList = [{ label: title, value: '' }, ...options];
			} else {
				// "select" the one and only option
				onFieldChange({ target: { value: options[0]?.value } });
			}
			setOptionsList(newOptionsList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options]);

	if (titleAsPlaceholder) {
		return (
			<div className="form-group">
				<select value={value} className={`form-control ${invalidClass}`} onChange={onFieldChange}>
					{optionsList.map((option, index) => {
						return (
							<option value={option.value} key={index}>
								{option.label}
							</option>
						);
					})}
				</select>
			</div>
		);
	}

	return (
		<div className="form-group">
			<label>{title}</label>
			<select value={value} className={`form-control ${invalidClass}`} onChange={onFieldChange}>
				{optionsList.map((option, index) => {
					return (
						<option value={option.value} key={index}>
							{option.label}
						</option>
					);
				})}
			</select>
		</div>
	);
};

SelectInputGroupComponent.propTypes = {
	blankLabel: PropTypes.string,
	isInvalid: PropTypes.bool,
	onFieldChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired,
	titleAsPlaceholder: PropTypes.bool,
	value: PropTypes.any,
};

export default SelectInputGroupComponent;
