import { orgCode } from '../../Globals.js';
import { navigationOccurred, navigationTo } from '../../services/NavigationService';
import ButtonComponent from '../common/forms/ButtonComponent/ButtonComponent';
import PageTemplate from './PageTemplate';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useEffect } from 'react';

const IndexComponent = () => {
	const { login } = useKindeAuth();

	useEffect(() => {
		navigationOccurred({
			to: navigationTo.index,
		});
	}, []);

	return (
		<PageTemplate>
			<div className="index-container">
				<div className="row">
					<div className="col-xs-12 text-right">
						<ButtonComponent
							text="Log In"
							colorClass="primary"
							iconName="sign-in-alt"
							iconPosition={ButtonComponent.iconPositions.right}
							onClick={() => {
								login({ org_code: orgCode });
							}}
							testId="log-in-button"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-6">
						<h1>Manage your money streams</h1>
						<p>
							The most important part of personal finance is cash flow management. Rivulet Finance was designed to simplify this task and help you gain control of
							your finances.
						</p>
					</div>
					<div className="col-xs-6 text-right">
						<img src="/images/woman-laptop-confidence.jpg" alt="Gain control of your finances" />
					</div>
				</div>
				<div className="row light-blue">
					<div className="col-xs-6">
						<img src="/images/register-screenshot.png" alt="Gain control of your finances" />
					</div>
					<div className="col-xs-6">
						<h1>Information at a glance</h1>
						<p>See how much spendable money you have in your account at any time. Quickly identify how each transaction affects your cash flow over time.</p>
					</div>
				</div>
				<div className="row">
					<div className="col-xs-6">
						<h1>Take Rivulet Finance with you</h1>
						<p>Record transactions quickly and easily on the go with our mobile app.</p>
					</div>
					<div className="col-xs-6 text-right">
						<img src="/images/mobile-register-screenshot.png" alt="Gain control of your finances" />
					</div>
				</div>
			</div>
		</PageTemplate>
	);
};

export default IndexComponent;
