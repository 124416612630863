import PropTypes from 'prop-types';
import React from 'react';

import { articleSlugs } from '../TableOfContents/TableOfContents';

const AccountGroupTabsAccounts = ({ onClickArticle }) => {
	return (
		<div>
			<h1>Account Register</h1>
			<p>
				The account register is the workhorse of Rivulet Finance. It is also where the magic of making sure you actually have money to spend
				happens, but <em>only if you ensure you record everything.</em>
			</p>
			<p>
				Above the register, there is a button that allows you to{' '}
				<a
					href="/"
					onClick={(e) => {
						e.preventDefault();
						onClickArticle(articleSlugs.accountGroupTabsAccountsTransaction);
					}}
				>
					add a new transaction
				</a>
			</p>
			<p>The register is split into two sections:</p>
			<ul>
				<li>
					Outstanding transactions (transactions that have not cleared the bank):
					<ol>
						<li>
							The header shows the "safe to spend balance." This balance includes deductions for all outstanding withdrawals, but not
							additions for outstanding deposits. This is because deposits like refunds sometimes take several days to be processed by
							the banks involved and you could receive NSF fees if you spend that money before it has been processed.
						</li>
						<li>
							The list of transactions contains the transaction details and is ordered by the date of each transaction, then the amount
							of the transaction. This is both to make it easier to find transactions and because this is typically how transactions
							will clear the bank. The outstanding transaction section also includes a running balance so that you can see how your cash
							flow is affected by each transaction. This can be useful in spotting cash flow problems and rearranging your transactions
							to avoid overdrawing your account. Each transaction also contains three action buttons:
							<ul>
								<li>A checkbox to toggle whether the transaction has cleared the bank or not</li>
								<li>
									A pencil icon to{' '}
									<a
										href="/"
										onClick={(e) => {
											e.preventDefault();
											onClickArticle(articleSlugs.accountGroupTabsAccountsTransaction);
										}}
									>
										edit the transaction details
									</a>
								</li>
								<li>A trash can icon to delete the transaction</li>
							</ul>
						</li>
					</ol>
				</li>
				<li>
					Cleared transactions (transactions that have cleared the bank)
					<ol>
						<li>
							The header shows the "bank balance." This is the balance the bank would show is in your account, whether you use online
							banking or paper statements. This never includes outstanding transactions, so is not an accurate representation of how
							much money you actually have at your disposal. There is also search functionality that can be accessed by pressing the
							magnifying glass icon.
						</li>
						<li>
							The list of transactions contains the transaction details and is ordered by the date of each transaction, then the amount
							of the transaction. This is both to make it easier to find transactions and because this is typically how transactions
							will clear the bank. Each transaction also contains three action buttons:
							<ul>
								<li>A checkbox to toggle whether the transaction has cleared the bank or not</li>
								<li>
									A pencil icon to{' '}
									<a
										href="/"
										onClick={(e) => {
											e.preventDefault();
											onClickArticle(articleSlugs.accountGroupTabsAccountsTransaction);
										}}
									>
										edit the transaction details
									</a>
								</li>
								<li>A trash can icon to delete the transaction</li>
							</ul>
						</li>
					</ol>
				</li>
			</ul>
		</div>
	);
};

AccountGroupTabsAccounts.propTypes = {
	onClickArticle: PropTypes.func.isRequired,
};

export default AccountGroupTabsAccounts;
