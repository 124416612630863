import { isNullOrEmptyString } from '@chris-crap-packages/utilities';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAppUser } from '../../state/userSlice.js';
import NotificationsComponent from './NotificationsComponent.js';

export const UserLinksComponent = () => {
	const [firstName, setFirstName] = useState('');
	const user = useSelector(selectAppUser);

	useEffect(() => {
		setFirstName(user?.firstName);
	}, [user]);

	if (!isNullOrEmptyString(firstName)) {
		return (
			<div className="user-links-container">
				<div className="text-right">
					<span className="greeting">Hello, {firstName}</span>
					<NotificationsComponent />
				</div>
			</div>
		);
	}

	return null;
};

export default UserLinksComponent;
