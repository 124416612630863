import { copy, implodeNumber } from '@chris-crap-packages/utilities';
import { newTxId } from '@xenomite-packages/cash-flow-globals';
import { validateRecurrence, validateTransaction } from '@xenomite-packages/cash-flow-validator';
import { isEmpty, orderBy } from 'lodash';
import React from 'react';

import { clearMessages, messageType, setMessages } from '../../../../services/FeedbackMessageService';
import { addRecurrence, deleteRecurrence, generateNewRecurrence, getRecurrences, updateRecurrence } from '../../../../services/TransactionService';
import ConfirmComponent from '../../../common/ConfirmComponent';
import ButtonComponent from '../../../common/forms/ButtonComponent';
import RecurrenceEditComponent from './RecurrenceEditComponent';
import RecurringTransactionsListComponent from './RecurringTransactionsListComponent';

const SORT_PROPERTIES = ['recordingDate', 'transaction.amount', 'transaction.payee'];
const SORT_ORDER = ['asc', 'desc', 'asc'];

class RecurringTransactionsComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			deleteId: '',
			editingRecurrence: {},
			editingRecurrenceErrors: {},
			isSaving: false,
			recurrences: [],
			showConfirmation: false,
		};

		this.handleAddRecurrence = handleAddRecurrence.bind(this);
		this.handleCancelConfirm = handleCancelConfirm.bind(this);
		this.handleCancelEdit = handleCancelEdit.bind(this);
		this.handleConfirmDelete = handleConfirmDelete.bind(this);
		this.handleDelete = handleDelete.bind(this);
		this.handleEdit = handleEdit.bind(this);
		this.handleSubmitEdit = handleSubmitEdit.bind(this);
		this.toggleRecordToday = toggleRecordToday.bind(this);
	}

	componentDidMount() {
		getRecurrences().then((recurrences) => {
			recurrences.forEach((recurrence) => {
				recurrence.transaction.amount = parseFloat(implodeNumber(recurrence.transaction.explodedAmount));
			});
			this.setState({
				recurrences: recurrences,
			});
		});
	}

	render() {
		return (
			<div>
				<div className="row">
					<div className="col-xs-12 text-right">
						<ButtonComponent
							colorClass="primary"
							iconName="plus"
							text="Add Recurring Transaction"
							onClick={this.handleAddRecurrence}
							testId="add-recurring-transaction-button"
						/>
					</div>
					{this.state.editingRecurrence._id === newTxId ? (
						<RecurrenceEditComponent
							recurrence={this.state.editingRecurrence}
							formErrors={this.state.editingRecurrenceErrors}
							onCancel={this.handleCancelEdit}
							onSubmit={this.handleSubmitEdit}
							isSaving={this.state.isSaving}
						/>
					) : (
						''
					)}
				</div>
				<br />
				<RecurringTransactionsListComponent
					recurrences={this.state.recurrences}
					editingId={this.state.editingRecurrence._id}
					formErrors={this.state.editingRecurrenceErrors}
					onEdit={this.handleEdit}
					onCancel={this.handleCancelEdit}
					onSubmit={this.handleSubmitEdit}
					onDelete={this.handleDelete}
					isSaving={this.state.isSaving}
					onToggleRecordToday={this.toggleRecordToday}
				/>
				<ConfirmComponent
					isOpen={this.state.showConfirmation}
					title="Delete Recurrence"
					confirmButtonText="Delete"
					confirmButtonClass="danger"
					cancelButtonText="Cancel"
					onCancel={this.handleCancelConfirm}
					onConfirm={this.handleConfirmDelete}>
					<div>Are you sure you want to delete this recurrence? This action cannot be undone.</div>
				</ConfirmComponent>
			</div>
		);
	}
}

function handleAddRecurrence() {
	const newRecurrence = generateNewRecurrence();
	this.setState({
		editingRecurrence: newRecurrence,
	});
}

function handleCancelEdit(event) {
	event.preventDefault();

	this.setState({
		editingRecurrence: {},
		editingTransaction: {},
	});
}

function handleSubmitEdit(event, recurrenceToSave) {
	event.preventDefault();
	clearMessages();
	this.setState({ isSaving: true });

	const recurrenceErrors = validateRecurrence(recurrenceToSave, recurrenceToSave._id === newTxId);
	const transactionErrors = validateTransaction(recurrenceToSave.transaction, true);
	const errors = { ...transactionErrors, ...recurrenceErrors };

	this.setState({ editingRecurrenceErrors: errors });

	if (!isEmpty(errors)) {
		this.setState({ isSaving: false });
		setMessages(errors, messageType.error, 'Please fix the following:');
		return;
	}

	const promises = [];
	const recurrences = this.state.recurrences;

	if (recurrenceToSave._id === newTxId) {
		promises.push(
			addRecurrence(recurrenceToSave).then((responseData) => {
				recurrences.push(responseData);
			})
		);
	} else {
		promises.push(
			updateRecurrence(recurrenceToSave).then((responseData) => {
				const index = recurrences.findIndex((r) => r._id === responseData._id);
				recurrences[index] = responseData;
			})
		);
	}

	Promise.all(promises).then(() => {
		orderBy(recurrences, SORT_PROPERTIES, SORT_ORDER);
		this.setState({
			editingRecurrence: {},
			isSaving: false,
			recurrences: recurrences,
		});
	});
}

function handleEdit(recurrenceId) {
	const editing = this.state.recurrences.find((recurrence) => recurrence._id === recurrenceId);
	this.setState({
		editingRecurrence: editing,
		editingRecurrenceErrors: {},
	});
}

function handleDelete(recurrenceId) {
	this.setState({ deleteId: recurrenceId, showConfirmation: true });
}

function handleCancelConfirm() {
	this.setState({ deleteId: null, showConfirmation: false });
}

function handleConfirmDelete() {
	this.setState({ showConfirmation: false });
	deleteRecurrence(this.state.deleteId).then(() => {
		const recurrences = copy(this.state.recurrences);
		const recurrenceIndex = recurrences.findIndex((r) => r._id === this.state.deleteId);
		recurrences.splice(recurrenceIndex, 1);
		orderBy(recurrences, SORT_PROPERTIES, SORT_ORDER);
		this.setState({
			deleteId: '',
			recurrences: recurrences,
		});
	});
}

function toggleRecordToday(recurrence) {
	recurrence.recordToday = !recurrence.recordToday;
	this.handleSubmitEdit({ preventDefault: () => {} }, recurrence);
}

export default RecurringTransactionsComponent;
