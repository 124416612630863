import { isNullOrEmptyString } from '@chris-crap-packages/utilities';

import { cancelFetch, retrieveFromServer } from './AwCrudService';

export function cancelGetAutoCompleteResults() {
	cancelFetch();
}

export function getCurrentLocation() {
	return new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(resolve, reject);
	});
}

export function getAutoCompleteResults(accountId, searchTerm, coordinates) {
	let uri = `/transaction/${accountId}/autoComplete/term/${searchTerm}`;

	if (!isNullOrEmptyString(coordinates.latitude) && !isNullOrEmptyString(coordinates.longitude) && !isNullOrEmptyString(coordinates.accuracy)) {
		uri += `/latitude/${coordinates.latitude}/longitude/${coordinates.longitude}/radius/${coordinates.accuracy}`;
	}

	return retrieveFromServer(uri, true);
}
