import { isNullOrEmptyObject } from '@chris-crap-packages/utilities';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { paulBunyan } from '../../../Globals';
import { selectAppUser } from '../../../state/userSlice';
import ButtonComponent from '../../common/forms/ButtonComponent/ButtonComponent';
import AccountGroupTabs from '../AccountGroupTabs';
import AccountGroupTabsAccounts from '../AccountGroupTabsAccounts';
import AccountGroupTabsAccountsTransaction from '../AccountGroupTabsAccountsTransaction';
import GettingStarted from '../GettingStarted';
import MobileApp from '../MobileApp';
import Profile from '../Profile';
import Settings from '../Settings';
import SettingsAccountGroups from '../SettingsAccountGroups';
import SettingsAccounts from '../SettingsAccounts';
import TableOfContents from '../TableOfContents';
import { articleSlugs } from '../TableOfContents/TableOfContents';
import WhyRivulet from '../WhyRivulet';

const HelpContainer = () => {
	const [showHelpModal, setShowHelpModal] = useState(false);
	const [selectedArticle, setSelectedArticle] = useState(articleSlugs.tableOfContents);
	const user = useSelector(selectAppUser);

	const handleHelpLinkClick = (event) => {
		event.preventDefault();
		setShowHelpModal(true);
	};

	const handleCloseHelpModal = () => {
		setShowHelpModal(false);
		setSelectedArticle(articleSlugs.tableOfContents);
	};

	useEffect(() => {
		if (!isNullOrEmptyObject(user)) {
			if (!user.hideGettingStarted) {
				setSelectedArticle(articleSlugs.gettingStarted);
				setShowHelpModal(true);
			}
		}
	}, [user]);

	const RenderHelpSection = () => {
		switch (selectedArticle) {
			case articleSlugs.tableOfContents:
				return <TableOfContents onClickArticle={setSelectedArticle} />;
			case articleSlugs.whyRivulet:
				return <WhyRivulet />;
			case articleSlugs.gettingStarted:
				return <GettingStarted onClickArticle={setSelectedArticle} onHideGettingStarted={handleCloseHelpModal} />;
			case articleSlugs.settings:
				return <Settings onClickArticle={setSelectedArticle} />;
			case articleSlugs.profile:
				return <Profile />;
			case articleSlugs.settingsAccountGroups:
				return <SettingsAccountGroups onClickArticle={setSelectedArticle} />;
			case articleSlugs.settingsAccounts:
				return <SettingsAccounts />;
			case articleSlugs.accountGroupTabs:
				return <AccountGroupTabs onClickArticle={setSelectedArticle} />;
			case articleSlugs.accountGroupTabsAccounts:
				return <AccountGroupTabsAccounts onClickArticle={setSelectedArticle} />;
			case articleSlugs.accountGroupTabsAccountsTransaction:
				return <AccountGroupTabsAccountsTransaction />;
			case articleSlugs.mobileApp:
				return <MobileApp />;
			default:
				paulBunyan.logWarning('Attempted to load unknown help article', { articleSlug: selectedArticle }, true);
				return (
					<div className="alert alert-warning">
						<span className="fa fa-shield-halved"></span>
						&nbsp;
						<span>
							The article you are trying to access does not appear to exist. Please return to the{' '}
							<a
								href="/"
								onClick={(e) => {
									e.preventDefault();
									setSelectedArticle(articleSlugs.tableOfContents);
								}}>
								Table of Contents
							</a>{' '}
							and try again.
						</span>
					</div>
				);
		}
	};

	return (
		<>
			<a href="/" onClick={handleHelpLinkClick}>
				Help
			</a>
			<Modal show={showHelpModal} onHide={handleCloseHelpModal} animation={false} size="lg">
				<Modal.Header>
					<Modal.Title>Rivulet Finance Help</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<RenderHelpSection />
					{selectedArticle !== articleSlugs.tableOfContents && (
						<div className="text-right">
							<a
								href="/"
								onClick={(e) => {
									e.preventDefault();
									setSelectedArticle(articleSlugs.tableOfContents);
								}}>
								Table of Contents
							</a>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					<ButtonComponent iconName="circle-xmark" colorClass="primary" onClick={handleCloseHelpModal} text="Close" testId="help-modal-close-button" />
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default HelpContainer;
