import { isNullOrUndefined } from '@chris-crap-packages/utilities';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { withLogin } from '../../hoc/withLogin';
import { accountGroupActivated } from '../../state/activeItemSlice';
import TransactionSummary from '../dashboard/transactionSummary/TransactionSummary';

const PrintableReport = () => {
	const { report, accountGroupId, start, end } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!isNullOrUndefined(accountGroupId)) {
			dispatch(accountGroupActivated(accountGroupId));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountGroupId]);

	const RenderReport = () => {
		if (report === 'tx-summary') {
			return <TransactionSummary printView={true} start={start} end={end} />;
		}

		return <div className="alert alert-danger h2 text-center">Unknown report</div>;
	};
	return (
		<div style={{ background: '#fff' }}>
			<RenderReport />
		</div>
	);
};

export default withLogin(PrintableReport);
