import { paulBunyan } from '../../Globals';
import { messageType } from '../../services/FeedbackMessageService';
import { isNullOrEmptyString } from '@chris-crap-packages/utilities';
import PropTypes from 'prop-types';
import React from 'react';

export default function MessageDivComponent(props) {
	if (!isNullOrEmptyString(props.message)) {
		let alertClass = '';
		let faClass = '';
		let testId = '';

		switch (props.type) {
			case messageType.error:
				alertClass = 'alert-danger';
				faClass = 'exclamation-triangle';
				testId = 'error-message-display';
				break;
			case messageType.success:
				alertClass = 'alert-success';
				faClass = 'check-circle';
				testId = 'success-message-display';
				break;
			case messageType.warning:
				alertClass = 'alert-warning';
				faClass = 'shield-halved';
				testId = 'warning-message-display';
				break;
			default:
				paulBunyan.logError('Message type is required when displaying message.');
		}

		return (
			<div className={`alert ${alertClass}`}>
				<span className={`fa fa-${faClass}`}></span>
				&nbsp;
				<span dangerouslySetInnerHTML={{ __html: props.message }} data-test-id={testId}></span>
			</div>
		);
	}

	return null;
}

MessageDivComponent.propTypes = {
	message: PropTypes.string.isRequired,
	type: PropTypes.number.isRequired,
};
