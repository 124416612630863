import { isNullOrEmptyObject, isNullOrUndefined } from '@chris-crap-packages/utilities';

import { paulBunyan } from '../Globals';
import { accountGroupActivated } from '../state/activeItemSlice';
import store from '../state/store';
import { selectAppUser } from '../state/userSlice';
import { getAccountGroups } from './AccountGroupService';
import { addWatcher as addAccountGroupWatcher } from './AccountGroupService';
import { getAccounts } from './AccountService';
import { addWatcher as addAccountWatcher } from './AccountService';
import { baseAddWatcher, baseFireWatchers, baseRemoveWatcher } from './WatcherService';

const watcherGroupKeys = {
	navigation: 'navigation',
	subNavigation: 'subNavigation',
};

export const navigationTo = {
	account: 'account',
	admin: 'admin',
	index: 'index',
	settings: 'settings',
};

let currentPageDetails = {};
let nav = [];
let subNav = [];

export function getNav() {
	return nav;
}

export function getSubNav() {
	return subNav;
}

export function updateNav() {
	const user = selectAppUser(store.getState());
	if (isNullOrEmptyObject(user)) {
		nav = [];
		subNav = [];
		baseFireWatchers(watcherGroupKeys.subNavigation);
		baseFireWatchers(watcherGroupKeys.navigation);
		return;
	}

	getAccountGroups().then((accountGroups) => {
		if (accountGroups.length > 0) {
			const accountPromises = [];
			accountGroups.forEach((accountGroup) => {
				accountPromises.push(
					getAccounts(accountGroup._id).then((accounts) => {
						accountGroup.accounts = accounts;
					})
				);
			});

			Promise.all(accountPromises).then(() => {
				nav = [];
				for (const accountGroup of accountGroups) {
					const accountGroupId = accountGroup._id;
					const navItem = {
						id: accountGroupId,
						link: `/account-group/${accountGroupId}/dashboard`,
						name: accountGroup.name,
						testId: `${accountGroup.name.replace(' ', '-').toLowerCase()}-link`,
					};
					nav.push(navItem);
				}

				addAlwaysThereNav(user);

				if (window.location.pathname.indexOf('/account-group/') > -1) {
					if (!isNullOrUndefined(currentPageDetails.selectedNavId) && currentPageDetails.selectedNavId !== 'default') {
						setSubNavForAccountGroup(currentPageDetails.selectedNavId);
					} else {
						store.dispatch(accountGroupActivated(accountGroups[0]._id));
						setSubNavForAccountGroup(accountGroups[0]._id);
					}
				}
				baseFireWatchers(watcherGroupKeys.navigation);
			});
		} else {
			nav = [];
			addAlwaysThereNav(user);
			baseFireWatchers(watcherGroupKeys.navigation);
		}
	});
}

const addAlwaysThereNav = (user) => {
	nav.push({
		id: 'settings',
		link: '/settings/recurring-transactions',
		name: 'Settings',
		testId: 'settings-link',
	});

	if (user.isAdmin) {
		nav.push({
			id: 'admin',
			link: '/admin/dashboard',
			name: 'Admin',
			testId: 'admin-link',
		});
	}
};

export function setSubNavForAccountGroup(accountGroupId) {
	getAccounts(accountGroupId).then((accounts) => {
		subNav = accounts.map((account) => {
			return {
				id: account._id,
				link: `/account-group/${accountGroupId}/account/${account._id}`,
				name: account.name,
			};
		});
		subNav.unshift({
			id: 'dashboard',
			link: `/account-group/${accountGroupId}/dashboard`,
			name: 'Dashboard',
		});

		baseFireWatchers(watcherGroupKeys.subNavigation);
	});
}

export function setSubNavForAdmin() {
	subNav = [
		{
			id: 'dashboard',
			link: '/admin/dashboard',
			name: 'Dashboard',
		},
		{
			id: 'categories',
			link: '/admin/categories',
			name: 'Categories',
		},
		{
			id: 'report-card',
			link: '/admin/report-card',
			name: 'Report Card',
		},
	];
}

export function setSubNavForSettings() {
	subNav = [
		{
			id: 'recurring-transactions',
			link: '/settings/recurring-transactions',
			name: 'Recurring Transactions',
		},
		{
			id: 'account-groups',
			link: '/settings/account-groups',
			name: 'Account Groups',
		},
		{
			id: 'categories',
			link: '/settings/categories',
			name: 'Categories',
		},
		{
			id: 'profile',
			link: '/settings/profile',
			name: 'Profile',
		},
	];
}

export function navigationOccurred(navigationDetails) {
	currentPageDetails = navigationDetails;
	switch (currentPageDetails.to) {
		case navigationTo.index:
			subNav = [];
			baseFireWatchers(watcherGroupKeys.subNavigation);
			break;
		case navigationTo.settings:
			setSubNavForSettings();
			baseFireWatchers(watcherGroupKeys.subNavigation);
			break;
		case navigationTo.account:
			setSubNavForAccountGroup(currentPageDetails.selectedNavId);
			break;
		case navigationTo.admin:
			setSubNavForAdmin();
			baseFireWatchers(watcherGroupKeys.subNavigation);
			break;
		default:
			paulBunyan.logWarning(`NavigationService is lost. It doesn't know where you are trying to go.`);
	}
}

export function addNavWatcher(watcherFunction) {
	return baseAddWatcher(watcherGroupKeys.navigation, watcherFunction);
}

export function removeNavWatcher(key) {
	baseRemoveWatcher(watcherGroupKeys.navigation, key);
}

export function addSubNavWatcher(watcherFunction) {
	return baseAddWatcher(watcherGroupKeys.subNavigation, watcherFunction);
}

export function removeSubNavWatcher(key) {
	baseRemoveWatcher(watcherGroupKeys.subNavigation, key);
}

export function getCurrentPageDetails() {
	return currentPageDetails;
}

addAccountGroupWatcher(updateNav);
addAccountWatcher(updateNav);
