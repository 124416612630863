import { forceLocalHost } from '@chris-crap-packages/utilities';
import { KindeProvider } from '@kinde-oss/kinde-auth-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import AppRoutes from './components/main/AppRoutes';
import { apiUri, setOrgCode } from './Globals.js';
import reportWebVitals from './reportWebVitals';
import { retrieveFromServer } from './services/AwCrudService.js';
import store from './state/store';

import './index.scss';

retrieveFromServer('/user/auth-config').then((config) => {
	const audience = forceLocalHost(apiUri);
	setOrgCode(config.orgCode);
	const root = createRoot(document.getElementById('root'));
	root.render(
		<React.StrictMode>
			<KindeProvider {...config} audience={audience}>
				<Provider store={store}>
					<AppRoutes />
				</Provider>
			</KindeProvider>
		</React.StrictMode>
	);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
