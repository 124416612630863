import { isNullOrEmptyString } from '@chris-crap-packages/utilities';
import PropTypes from 'prop-types';
import React from 'react';

import ActualAddonComponent from '../ActualAddonComponent';

const InputGroupAddonComponent = ({ addonTextAfter, addonTextBefore, children }) => {
	if (isNullOrEmptyString(addonTextBefore) && isNullOrEmptyString(addonTextAfter)) {
		return children;
	}

	return (
		<div className="input-group">
			<ActualAddonComponent text={addonTextBefore} />
			{children}
			<ActualAddonComponent text={addonTextAfter} />
		</div>
	);
};

InputGroupAddonComponent.propTypes = {
	addonTextAfter: PropTypes.string,
	addonTextBefore: PropTypes.string,
	children: PropTypes.any,
};

export default InputGroupAddonComponent;
