import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { addWatcher, dispositionNotification, getHasViewed, getNotifications, removeWatcher, retrieveNotifications, setHasViewed } from '../../services/NotificationService.js';
import { selectAppUser } from '../../state/userSlice.js';
import ButtonComponent from '../common/forms/ButtonComponent';
import TextWithIconComponent from '../common/TextWithIconComponent.js';

let notificationWatcher;

const NotificationsComponent = () => {
	const [notifications, setNotifications] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const user = useSelector(selectAppUser);

	const updateNotifications = () => {
		const updatedNotifications = getNotifications();
		setNotifications(updatedNotifications);
		if (!getHasViewed()) {
			if (updatedNotifications.findIndex((n) => n.isUrgent) !== -1) {
				handleNotificationClick(true);
			}
		}
	};

	const handleNotificationClick = () => {
		setHasViewed(true);
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleDispositionNotification = (notification, response) => {
		const disposition = {
			_id: notification._id,
			action: response,
			emailAddress: notification.emailAddress,
			otherData: notification.otherData,
		};

		dispositionNotification(notification.responseUrl, disposition).then(() => {
			retrieveNotifications();
		});
	};

	useEffect(() => {
		notificationWatcher = addWatcher(updateNotifications);
		retrieveNotifications();

		return () => {
			removeWatcher(notificationWatcher);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	return (
		<>
			<span className="notification badge-container" onClick={handleNotificationClick}>
				{notifications.length > 0 ? <span className="badge">{notifications.length}</span> : ''}
				<TextWithIconComponent iconName="bell" iconStyle={TextWithIconComponent.iconStyles.regular} />
			</span>
			<Modal size="lg" show={showModal} onHide={handleCloseModal} animation={false}>
				<Modal.Header>
					<Modal.Title>Notifications</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{ minHeight: 320 }}>
						{notifications.map((notification) => {
							const className = notification.isUrgent ? 'bg-danger' : '';
							return (
								<div key={notification._id} className={`notification-text row ${className}`}>
									<div className="col-xs-8">{notification.message}</div>
									<div className="col-xs-2">
										{notification.acceptTitle ? (
											<span
												className="clickable"
												onClick={() => {
													handleDispositionNotification(notification, notification.acceptAction);
												}}>
												<TextWithIconComponent iconName="check-square">{notification.acceptTitle}</TextWithIconComponent>
											</span>
										) : (
											''
										)}
									</div>
									<div className="col-xs-2 text-danger">
										{notification.declineTitle ? (
											<span
												className="clickable"
												onClick={() => {
													handleDispositionNotification(notification, notification.declineAction);
												}}>
												<TextWithIconComponent iconName="times">{notification.declineTitle}</TextWithIconComponent>
											</span>
										) : (
											''
										)}
									</div>
								</div>
							);
						})}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<ButtonComponent iconName="thumbs-up" colorClass="primary" onClick={handleCloseModal} text="Close" testId="notification-modal-close-button" />
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default NotificationsComponent;
