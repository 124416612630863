import React from 'react';
import { Link } from 'react-router-dom';

import PageTemplate from '../../main/PageTemplate/PageTemplate';

const PrivacyPolicy = () => {
	return (
		<PageTemplate>
			<>
				<p>This page sould be full of legalese, but this app is still in beta, so here's what you need to know for now:</p>
				<ol>
					<li>
						By using this website, you agree to be bound by this policy and the <Link to="/terms-of-use">terms of use.</Link>
					</li>
					<li>
						This policy can change at any time and you agree to be bound by the new policy, with or with out notice. Simply put, we are
						planning to update the privacy policy when we are ready to start charging for premium features.
					</li>
					<li>
						We store data associated with you in order to make the application work. This may include cookies or data in a database. We
						only use cookies that are required for the site to function. We believe that you are the owner of the data in the database and
						we are just the stewards of that data. If you would like us to do (or not do) anything with your data, including deleting your
						data altogether, please email <a href="mailto:support@rivulet.finance">support@rivulet.finance</a> and we'll see what we can
						do.
					</li>
					<li>
						We use third parties to help make the site function. Their terms of service and privacy policy may be different than ours.
					</li>
					<li>
						Please contact <a href="mailto:support@rivulet.finance">support@rivulet.finance</a> if you have any concerns.
					</li>
				</ol>
				<p>
					That's it for now. If you agree, please{' '}
					<Link to="/account-group/default/dashboard" data-test-id="log-in-link">
						enter the app.
					</Link>
				</p>
			</>
		</PageTemplate>
	);
};

export default PrivacyPolicy;
