import { copy } from '@chris-crap-packages/utilities';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { updateUser } from '../../../services/UserService';
import { selectAppUser } from '../../../state/userSlice';
import CheckboxComponent from '../../common/forms/CheckboxComponent';
import { articleSlugs } from '../TableOfContents/TableOfContents';

const GettingStarted = ({ onClickArticle, onHideGettingStarted }) => {
	const user = useSelector(selectAppUser);

	const handleToggleHideGettingStarted = async () => {
		const userToUpdate = copy(user);
		userToUpdate.hideGettingStarted = !userToUpdate.hideGettingStarted;
		await updateUser(userToUpdate);
		onHideGettingStarted();
	};

	return (
		<div>
			<h1>Welcome to Rivulet Finance!</h1>
			<p>To get going with managing your finances, simply:</p>
			<ol>
				<li>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							onClickArticle(articleSlugs.profile);
						}}>
						Confirm your profile information is correct
					</a>
				</li>
				<li>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							onClickArticle(articleSlugs.settingsAccountGroups);
						}}>
						Set up your first account group
					</a>
				</li>
				<li>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							onClickArticle(articleSlugs.settingsAccounts);
						}}>
						Set up your first account
					</a>
				</li>
				<li>
					<a
						href="/"
						onClick={(e) => {
							e.preventDefault();
							onClickArticle(articleSlugs.accountGroupTabsAccountsTransaction);
						}}>
						Start recording transactions
					</a>
				</li>
			</ol>
			<p>
				There are many other features in Rivulet Finance. Feel free to "poke around" to see what is out there. Also, you can return to this help section at any time by
				clicking the link in the footer.
			</p>
			<p>
				<CheckboxComponent isChecked={user.hideGettingStarted} onClick={handleToggleHideGettingStarted}>
					Do not automatically show this help page again.
				</CheckboxComponent>
			</p>
		</div>
	);
};

GettingStarted.propTypes = {
	onClickArticle: PropTypes.func.isRequired,
	onHideGettingStarted: PropTypes.func.isRequired,
};

export default GettingStarted;
