import { isNullOrEmptyObject } from '@chris-crap-packages/utilities';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAppUser } from '../../../state/userSlice';

export const articleSlugs = {
	accountGroupTabs: 'account-group-tabs',
	accountGroupTabsAccounts: 'account-group-tabs-accounts',
	accountGroupTabsAccountsTransaction: 'account-group-tabs-accounts-transaction',
	gettingStarted: 'getting-started',
	mobileApp: 'mobile-app',
	profile: 'profile',
	settings: 'settings',
	settingsAccountGroups: 'settings-account-groups',
	settingsAccounts: 'settings-accounts',
	tableOfContents: 'toc',
	whyRivulet: 'why-rivulet',
};

const articles = [{ slug: articleSlugs.whyRivulet, title: 'Why Bother with Rivulet Finance', subArticles: [] }];
const userArticles = [
	{ slug: articleSlugs.gettingStarted, title: 'Getting Started', subArticles: [] },
	{
		slug: articleSlugs.accountGroupTabs,
		title: 'Account Group Tabs',
		subArticles: [
			{
				slug: articleSlugs.accountGroupTabsAccounts,
				title: 'Account Register',
				subArticles: [{ slug: articleSlugs.accountGroupTabsAccountsTransaction, title: 'Transaction Form', subArticles: [] }],
			},
		],
	},
	{
		slug: articleSlugs.settings,
		title: 'Settings',
		subArticles: [
			{
				slug: articleSlugs.settingsAccountGroups,
				title: 'Account Groups',
				subArticles: [{ slug: articleSlugs.settingsAccounts, title: 'Accounts', subArticles: [] }],
			},
			{ slug: articleSlugs.profile, title: 'Profile', subArticles: [] },
		],
	},
	{ slug: articleSlugs.mobileApp, title: 'Mobile App', subArticles: [] },
];

const TableOfContents = ({ onClickArticle }) => {
	const [allArticles, setAllArticles] = useState([]);
	const user = useSelector(selectAppUser);

	useEffect(() => {
		(async () => {
			if (!isNullOrEmptyObject(user)) {
				setAllArticles([...articles, ...userArticles]);
			} else {
				setAllArticles(articles);
			}
		})();
	}, [user]);

	const RenderArticles = ({ articleList }) => {
		if (articleList.length === 0) {
			return '';
		}

		return (
			<ol>
				{articleList.map((article) => (
					<li key={article.slug}>
						<a
							href="/"
							onClick={(e) => {
								e.preventDefault();
								onClickArticle(article.slug);
							}}>
							{article.title}
						</a>
						<RenderArticles articleList={article.subArticles} />
					</li>
				))}
			</ol>
		);
	};

	return (
		<div>
			<h1>Table of Contents</h1>
			<RenderArticles articleList={allArticles} />
		</div>
	);
};

TableOfContents.propTypes = {
	onClickArticle: PropTypes.func.isRequired,
};

export default TableOfContents;
