import PropTypes from 'prop-types';
import React from 'react';

import ButtonComponent from '../common/forms/ButtonComponent';

class AddTransactionButtonsComponent extends React.Component {
	render() {
		return (
			<div className='row bottom-buffer'>
				<div className='col-xs-12 text-right'>
					<ButtonComponent colorClass='primary' iconName='plus' text='Add Transaction' onClick={() => this.props.onAdd()} testId='add-transaction-button' />
				</div>
			</div>
		);
	}
}

AddTransactionButtonsComponent.propTypes = {
	accountType: PropTypes.string.isRequired,
	onAdd: PropTypes.func.isRequired
};

export default AddTransactionButtonsComponent;
