import { getEnvironment } from '@chris-crap-packages/browser-utilities';
import { environmentNames } from '@chris-crap-packages/utilities';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { domCookie } from 'cookie-muncher';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { orgCode } from '../../../Globals.js';
import { appUserUpdated } from '../../../state/userSlice.js';
import ButtonComponent from '../../common/forms/ButtonComponent/ButtonComponent.js';
import WaitingMessageComponent from '../../common/WaitingMessageComponent.js';
import PageTemplate from '../PageTemplate/PageTemplate.js';

const Logout = () => {
	const dispatch = useDispatch();
	const [isLoggingOut, setIsLoggingOUt] = useState(true);
	const { login } = useKindeAuth();

	useEffect(() => {
		if (getEnvironment() === environmentNames.dev) {
			const authCookie = domCookie.get('authToken');
			if (authCookie) {
				domCookie.remove('authToken', { path: '/' });
			}
		}
		dispatch(appUserUpdated(null));
		setIsLoggingOUt(false);
	}, [dispatch]);

	if (isLoggingOut) {
		return (
			<PageTemplate>
				<WaitingMessageComponent message="Logging you out" />
			</PageTemplate>
		);
	}
	return (
		<PageTemplate>
			<div className="text-center">
				<p>You have been logged out. Please log in to continue.</p>
				<p>
					<ButtonComponent
						text="Log In"
						colorClass="primary"
						iconName="sign-in-alt"
						iconPosition={ButtonComponent.iconPositions.right}
						onClick={() => {
							login({ org_code: orgCode });
						}}
						testId="log-in-button"
					/>
				</p>
			</div>
		</PageTemplate>
	);
};

export default Logout;
