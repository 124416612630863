import React from 'react';
import { Link } from 'react-router-dom';

import UserLinksComponent from '../user/UserLinksComponent';

function HeaderContainer() {
	return (
		<header className="clearfix">
			<div className="pull-right">
				<UserLinksComponent />
			</div>
			<div className="pull-left logo">
				<Link to="/">
					<img src="/images/logo-64x64.png" alt="Rivulet Finance" />
				</Link>
			</div>
			<div className="pull-left title">
				<h1>
					<Link to="/">Rivulet Finance</Link>
				</h1>
				<h2>Rivulet Finance</h2>
				<h4>
					<Link to="/">Manage your money streams</Link>
				</h4>
			</div>
		</header>
	);
}

export default HeaderContainer;
