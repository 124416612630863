import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import ButtonComponent from '../forms/ButtonComponent';
import { isNullOrEmptyString } from '@chris-crap-packages/utilities';

const ConfirmComponent = ({ cancelButtonText, children, confirmButtonClass, confirmButtonText, isOpen, onCancel, onConfirm, title }) => {
	return (
		<Modal show={isOpen} onHide={onCancel} animation={false}>
			{!isNullOrEmptyString(title) ? (
				<Modal.Header>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
			) : null}
			<Modal.Body>{children}</Modal.Body>
			<Modal.Footer>
				<ButtonComponent
					iconName="thumbs-up"
					colorClass={confirmButtonClass || 'primary'}
					onClick={onConfirm}
					text={confirmButtonText || 'Confirm'}
					testId="confirm-modal-confirm-button"
				/>
				<ButtonComponent iconName="thumbs-down" colorClass="default" onClick={onCancel} text={cancelButtonText || 'Cancel'} testId="confirm-modal-cancel-button" />
			</Modal.Footer>
		</Modal>
	);
};

ConfirmComponent.propTypes = {
	cancelButtonText: PropTypes.string,
	children: PropTypes.any,
	confirmButtonClass: PropTypes.string,
	confirmButtonText: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	title: PropTypes.string,
};

export default ConfirmComponent;
