import React from 'react';
import { Link } from 'react-router-dom';

import HelpContainer from '../help/HelpContainer';

class FooterComponent extends React.Component {
	render() {
		return (
			<footer>
				<p>
					<Link to="/terms-of-use">Terms of Use</Link>
					<Link to="/privacy-policy">Privacy Policy</Link>
					<HelpContainer />
				</p>
			</footer>
		);
	}
}

export default FooterComponent;
