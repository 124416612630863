import PropTypes from 'prop-types';
import React from 'react';
import { pagingDirections } from '../../Globals';

import PagingButtonsComponent from './PagingButtonsComponent';
import SearchComponent from './SearchComponent';

class PagingComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			xsColumnSize: 6,
		};
	}

	render() {
		if (this.props.pageCount === 1 && !this.props.isSearchActive) {
			return null;
		}

		return (
			<div className="filters">
				<div className="hidden-xs col-sm-6 col-md-4">
					<SearchComponent onSearch={this.props.onSearch} />
				</div>
				<div className="col-xs-12 col-sm-6 col-md-8 text-right">
					<PagingButtonsComponent
						direction={pagingDirections.backward}
						pageNumber={this.props.pageNumber}
						pageCount={this.props.pageCount}
						onNavigate={this.props.onNavigate}
					/>
					<span>
						{' '}
						Page {this.props.pageNumber} of {this.props.pageCount}{' '}
					</span>
					<PagingButtonsComponent
						direction={pagingDirections.forward}
						pageNumber={this.props.pageNumber}
						pageCount={this.props.pageCount}
						onNavigate={this.props.onNavigate}
					/>
				</div>
			</div>
		);
	}
}

PagingComponent.propTypes = {
	isSearchActive: PropTypes.bool.isRequired,
	onNavigate: PropTypes.func.isRequired,
	onSearch: PropTypes.func.isRequired,
	pageCount: PropTypes.number.isRequired,
	pageNumber: PropTypes.number.isRequired,
};

export default PagingComponent;
