import PropTypes from 'prop-types';
import React from 'react';

const WaitingMessageComponent = ({ children, isLoading, message }) => {
	const displayMessage = message || 'Loading...';

	if (!children || isLoading) {
		return (
			<div className="text-center">
				<img src={`/images/loading.gif`} alt={displayMessage} />
				<br />
				<span>{displayMessage}</span>
			</div>
		);
	}

	return <>{children}</>;
};

WaitingMessageComponent.propTypes = {
	children: PropTypes.any,
	isLoading: PropTypes.bool,
	message: PropTypes.string,
};

export default WaitingMessageComponent;
