import { copy } from '@chris-crap-packages/utilities';
import { isEmpty, sortBy } from 'lodash';

import { paulBunyan } from '../Globals.js';
import store from '../state/store';
import { selectAppUser } from '../state/userSlice';
import { createNestedOptionArray } from '../Utilities';
import { createOnServer, deleteFromServer, retrieveFromServer, updateOnServer } from './AwCrudService';
import { baseAddWatcher, baseFireWatchers, baseRemoveWatcher } from './WatcherService';

const WATCHER_GROUP_KEY = 'accountGroups';

let _accountGroups = [];
let _serverHasNoAccountGroups = false;

function _updateAccountGroups(accountGroups) {
	const originalAccountGroups = copy(_accountGroups);
	accountGroups = sortBy(accountGroups, ['order', 'name']);
	_accountGroups = copy(accountGroups);

	if (JSON.stringify(originalAccountGroups) !== JSON.stringify(_accountGroups)) {
		paulBunyan.logInfo('Account groups changed, fire watchers', { _accountGroups, originalAccountGroups });
		baseFireWatchers(WATCHER_GROUP_KEY);
	}

	return _accountGroups;
}

export function addAccountGroup(accountGroup) {
	return createOnServer('/accountGroup', accountGroup).then((accountGroup) => {
		const newAccountGroups = copy(_accountGroups);
		newAccountGroups.push(accountGroup);

		return _updateAccountGroups(newAccountGroups);
	});
}

export function getAccountGroups(forceRefresh) {
	const user = selectAppUser(store.getState());
	if (isEmpty(user)) {
		_accountGroups = [];
		return Promise.resolve(_accountGroups);
	}

	if ((_accountGroups.length === 0 && !_serverHasNoAccountGroups) || forceRefresh) {
		return retrieveFromServer('/accountGroup').then((serverAccountGroups) => {
			_serverHasNoAccountGroups = serverAccountGroups.length === 0;
			return _updateAccountGroups(serverAccountGroups);
		});
	}

	return Promise.resolve(_accountGroups);
}

export const getOldestAccountGroupUserAge = (accountGroupId) => {
	return retrieveFromServer(`/accountGroup/${accountGroupId}/oldestUserAge`);
};

export const getAccountGroupPermissionLevel = (accountGroupId) => {
	return retrieveFromServer(`/accountGroup/${accountGroupId}/permissionLevel`);
};

export const getAccountGroup = async (accountGroupId) => {
	const accountGroups = await getAccountGroups();
	return accountGroups.find((ag) => ag._id === accountGroupId);
};

export const getAccountGroupUsers = (accountGroupId) => {
	return retrieveFromServer(`/accountGroup/${accountGroupId}/users`);
};

export const saveAccountGroupUser = (accountGroupUser) => {
	return createOnServer(`/accountGroup/${accountGroupUser.accountGroupId}/users`, accountGroupUser);
};

export const deleteAccountGroupUser = (accountGroupId, accountGroupToUserId) => {
	return deleteFromServer(`/accountGroup/${accountGroupId}/users/${accountGroupToUserId}`);
};

export function getAccountOptions() {
	return new Promise((resolve) => {
		getAccountGroups().then((accountGroups) => {
			const accountOptions = createNestedOptionArray(accountGroups, 'accounts', 'name', '_id');
			resolve(accountOptions);
		});
	});
}

export function getAccountGroupIdFromAccountId(accountId) {
	return new Promise((resolve) => {
		getAccountGroups().then((accountGroups) => {
			accountGroups.forEach((accountGroup) => {
				accountGroup.accounts.forEach((account) => {
					if (account._id === accountId) {
						resolve(accountGroup._id);
					}
				});
			});
		});
	});
}

export function updateAccountGroups(updatedAccountGroups) {
	return updateOnServer('/accountGroup/updateAll', { accountGroups: updatedAccountGroups }).then((savedAccountGroups) => {
		return _updateAccountGroups(savedAccountGroups);
	});
}

export function deleteAccountGroup(accountGroup) {
	return deleteFromServer(`/accountGroup/${accountGroup._id}`).then((accountGroupList) => {
		return _updateAccountGroups(accountGroupList);
	});
}

export function updateAccountGroup(accountGroup) {
	return updateOnServer(`/accountGroup/${accountGroup._id}`, accountGroup).then((accountGroupList) => {
		return _updateAccountGroups(accountGroupList);
	});
}

export function addWatcher(watcherFunction) {
	baseAddWatcher(WATCHER_GROUP_KEY, watcherFunction);
}

export function removeWatcher(key) {
	baseRemoveWatcher(WATCHER_GROUP_KEY, key);
}
