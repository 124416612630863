import React from 'react';
import PropTypes from 'prop-types';

const ActualAddonComponent = ({ text }) => {
	if (!text) {
		return null;
	}

	return <div className='input-group-addon'>{text}</div>;
};

ActualAddonComponent.propTypes = {
	text: PropTypes.string
};

export default ActualAddonComponent;
