import { copy, isNullOrEmptyString } from '@chris-crap-packages/utilities';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';

import UserFormsSwitcherComponent from '../../user/settings/UserFormsSwitcherComponent';
import EditableListItemComponent from '../EditableListItemComponent';

const EditableListComponent = function ({
	accountGroups,
	editing,
	isSortable,
	items,
	listType,
	onAddItem,
	onDeleteItem,
	onDisplayUsers,
	onEditItem,
	onListChanged,
	onSelectItem,
	onToggleAccountGroup,
	onUpdateItem,
	selectedItem,
	accountGroupsOptions,
	selectedAccountGroup,
}) {
	accountGroups = accountGroups || [];
	const moveItemDown = (item) => {
		const movedItems = moveItem(items, item, 1);
		onListChanged(movedItems);
	};

	const moveItemUp = (item) => {
		const movedItems = moveItem(items, item, -1);
		onListChanged(movedItems);
	};

	const isItemSelected = (currentItem, selectedItem) => {
		let className = '';

		if (!isNullOrEmptyString(selectedItem)) {
			if (selectedItem === currentItem._id) {
				className = 'alert-selected';
			}
		}

		return className;
	};

	const moveItem = (list, movedItem, direction) => {
		let adjustedList = copy(list);
		const newOrder = movedItem.order + direction;

		for (const adjustedItem of adjustedList) {
			if (adjustedItem._id === movedItem._id) {
				adjustedItem.order = newOrder;
			} else if (adjustedItem.order === newOrder) {
				adjustedItem.order -= direction;
			}
		}
		adjustedList = sortBy(adjustedList, ['order', 'name']);
		adjustedList.forEach((item, index) => {
			item.order = index;
		});

		return adjustedList;
	};

	return (
		<table className="table table-striped">
			<tbody>
				{items.map((item) => {
					return (
						<tr key={item._id}>
							<td className={`alert ${isItemSelected(item, selectedItem)}`}>
								{editing === item._id ? (
									<UserFormsSwitcherComponent
										item={item}
										onSaveItem={onUpdateItem}
										listType={listType}
										accountGroups={accountGroups}
										accountGroupsOptions={accountGroupsOptions}
										selectedAccountGroup={selectedAccountGroup}
									/>
								) : (
									<EditableListItemComponent
										accountGroups={accountGroups}
										item={item}
										isSortable={isSortable}
										onDeleteItem={onDeleteItem}
										onEditItem={onEditItem}
										onMoveDown={moveItemDown}
										onMoveUp={moveItemUp}
										onSelectItem={() => onSelectItem(item)}
										listType={listType}
										onDisplayUsers={onDisplayUsers}
										onToggleAccountGroup={onToggleAccountGroup}
									/>
								)}
							</td>
						</tr>
					);
				})}
				<tr>
					<td>
						{!isNullOrEmptyString(editing) ? (
							''
						) : (
							<UserFormsSwitcherComponent
								onSaveItem={onAddItem}
								listType={listType}
								accountGroups={accountGroups}
								accountGroupsOptions={accountGroupsOptions}
								selectedAccountGroup={selectedAccountGroup}
							/>
						)}
					</td>
				</tr>
			</tbody>
		</table>
	);
};

EditableListComponent.propTypes = {
	accountGroups: PropTypes.array,
	accountGroupsOptions: PropTypes.array,
	editing: PropTypes.string,
	isSortable: PropTypes.bool,
	items: PropTypes.array.isRequired,
	listType: PropTypes.string.isRequired,
	onAddItem: PropTypes.func.isRequired,
	onDeleteItem: PropTypes.func,
	onDisplayUsers: PropTypes.func,
	onEditItem: PropTypes.func.isRequired,
	onListChanged: PropTypes.func,
	onSelectItem: PropTypes.func.isRequired,
	onToggleAccountGroup: PropTypes.func,
	selectedAccountGroup: PropTypes.string,
	onUpdateItem: PropTypes.func.isRequired,
	selectedItem: PropTypes.string,
};

export default EditableListComponent;
