import store from '../state/store';
import { appUserUpdated } from '../state/userSlice';
import { retrieveFromServer, updateOnServer } from './AwCrudService';

export function retrieveUser() {
	return new Promise((resolve, reject) => {
		retrieveFromServer('/user')
			.then((user) => {
				store.dispatch(appUserUpdated(user));
				resolve(true);
			})
			.catch((error) => reject(error));
	});
}

export function updateUser(userInfo) {
	return new Promise((resolve) => {
		updateOnServer('/user', userInfo).then((updatedUser) => {
			store.dispatch(appUserUpdated(updatedUser));
			resolve(true);
		});
	});
}

export function retrieveUserStats() {
	return retrieveFromServer('/user/stats');
}
