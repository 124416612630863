import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectAppUser } from '../../../state/userSlice';

const SubNavLinksComponent = ({ isVisible, containerClass, subNavList, activeItemId, onClick }) => {
	const user = useSelector(selectAppUser);

	if (isVisible && !isEmpty(user)) {
		return (
			<div className={containerClass}>
				{subNavList.map((subNavItem) => {
					const cssClass = activeItemId === subNavItem.id ? 'active' : '';
					return (
						<Link to={subNavItem.link} onClick={onClick} key={subNavItem.link} className={cssClass}>
							{subNavItem.name}
						</Link>
					);
				})}
			</div>
		);
	}

	return null;
};

SubNavLinksComponent.propTypes = {
	activeItemId: PropTypes.string,
	containerClass: PropTypes.string,
	isVisible: PropTypes.bool,
	onClick: PropTypes.func,
	subNavList: PropTypes.array.isRequired,
};

export default SubNavLinksComponent;
