import { isNullOrUndefined } from '@chris-crap-packages/utilities';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getReportCardWeights, lookupReportCardAlertClass } from '../../../../services/ReportService';
import { selectNetWorth, selectReportCardWeights } from '../../../../state/reportsSlice';
import WaitingMessageComponent from '../../../common/WaitingMessageComponent';

const LetterGrade = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [letterGrade, setLetterGrade] = useState('');
	const [alertClass, setAlertClass] = useState('');
	const netWorth = useSelector(selectNetWorth);
	const reportCardWeights = useSelector(selectReportCardWeights);

	useEffect(() => {
		if (isNullOrUndefined(reportCardWeights)) {
			getReportCardWeights();
		} else {
			let weightedPercentageGrade = 0;

			if (!isNullOrUndefined(netWorth)) {
				const netWorthToTarget = Math.min(1, netWorth.total / netWorth.target);
				const weightedNetWorth = netWorthToTarget * (reportCardWeights.netWorth / 100);
				weightedPercentageGrade += weightedNetWorth;

				// eventually, the following should only be done once all all pieces to calculate the letter grade have been gathered
				setLetterGrade(lookUpLetterGrade(weightedPercentageGrade));
				setAlertClass(lookupReportCardAlertClass(weightedPercentageGrade));
				setIsLoading(false);
			}
		}
	}, [netWorth, reportCardWeights]);

	return (
		<WaitingMessageComponent message=" " isLoading={isLoading}>
			<div className={`alert text-center ${alertClass}`}>
				<h1 style={{ marginTop: 'auto', marginBottom: 'auto' }}>{letterGrade}</h1>
			</div>
		</WaitingMessageComponent>
	);
};

const lookUpLetterGrade = (percentageGrade) => {
	if (percentageGrade >= 0.93) {
		return 'A';
	}

	if (percentageGrade >= 0.9) {
		return 'A-';
	}

	if (percentageGrade >= 0.87) {
		return 'B+';
	}

	if (percentageGrade >= 0.83) {
		return 'B';
	}

	if (percentageGrade >= 0.8) {
		return 'B-';
	}

	if (percentageGrade >= 0.77) {
		return 'C+';
	}

	if (percentageGrade >= 0.73) {
		return 'C';
	}

	if (percentageGrade >= 0.7) {
		return 'C-';
	}

	if (percentageGrade >= 0.67) {
		return 'D+';
	}

	if (percentageGrade >= 0.63) {
		return 'D';
	}

	if (percentageGrade > 0.6) {
		return 'D-';
	}

	return 'F';
};

export default LetterGrade;
