import { copy, implodeNumber, isNullOrUndefined } from '@chris-crap-packages/utilities';
import { generateNewTransaction, newTxId, recurrencePeriods } from '@xenomite-packages/cash-flow-globals';
import { orderBy } from 'lodash';

import { createOnServer, deleteFromServer, retrieveFromServer, updateOnServer } from './AwCrudService';

export function addRecurrence(recurrenceDetails) {
	const recurrence = copy(recurrenceDetails);
	delete recurrence._id;
	return createOnServer('/transaction/recurring', recurrence);
}

export function addTransaction(transactionDetails) {
	const transaction = copy(transactionDetails);
	delete transaction._id;
	return createOnServer('/transaction', transaction);
}

export function deleteTransaction(transactionId) {
	return deleteFromServer(`/transaction/${transactionId}`);
}

export function deleteRecurrence(recurrenceId) {
	return deleteFromServer(`/transaction/recurring/${recurrenceId}`);
}

export async function getClearedTransactions(accountId, limit, pageNumber, search) {
	const offset = (pageNumber - 1) * limit;
	let transactionResults = [];
	if (isNullOrUndefined(search)) {
		transactionResults = await retrieveFromServer(`/transaction/account/${accountId}/cleared/true/limit/${limit}/offset/${offset}`);
	} else {
		transactionResults = await createOnServer(`/transaction/account/${accountId}/cleared/true/limit/${limit}/offset/${offset}`, search);
	}
	transactionResults.transactions.forEach((transaction) => {
		transaction.amount = Number(implodeNumber(transaction.explodedAmount));
	});

	return Promise.resolve(transactionResults);
}

export function generateNewRecurrence() {
	const tx = generateNewTransaction();
	delete tx._id;
	return {
		_id: newTxId,
		period: recurrencePeriods.months,
		periodCount: 1,
		recordToday: false,
		recordingDate: tx.date,
		transaction: tx,
	};
}

export async function getOutstandingTransactions(accountId, search) {
	let transactionResults = [];
	if (search === null) {
		transactionResults = await retrieveFromServer(`/transaction/account/${accountId}/cleared/false/limit/0/offset/0`);
	}
	transactionResults = await createOnServer(`/transaction/account/${accountId}/cleared/false/limit/0/offset/0`, search);

	transactionResults.transactions.forEach((transaction) => {
		const amount = Number(implodeNumber(transaction.explodedAmount));
		transaction.amount = amount;
	});

	return Promise.resolve(transactionResults);
}

export function getRecurrences() {
	return new Promise((resolve) => {
		retrieveFromServer('/transaction/recurring').then((recurrences) => {
			recurrences = orderBy(recurrences, ['recordingDate', 'transaction.amount', 'transaction.payee'], ['asc', 'desc', 'asc']);
			resolve(recurrences);
		});
	});
}

export function updateRecurrence(recurrenceDetails) {
	return updateOnServer('/transaction/recurring', recurrenceDetails);
}

export function updateTransaction(transactionDetails) {
	return updateOnServer('/transaction', transactionDetails);
}

export function getTransactionsSummary(accountGroupId, start, end) {
	return retrieveFromServer(`/transaction-summary/${accountGroupId}/${start}/${end}`);
}

export const retrieveTransactionStats = () => {
	return retrieveFromServer('/transaction-stats');
};
