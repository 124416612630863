import { copy, implodeNumber, isNullOrUndefined } from '@chris-crap-packages/utilities';
import { netWorthReportTargetTypes, reportTypes } from '@xenomite-packages/cash-flow-globals';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import { getAccountGroup, getAccountGroups, getOldestAccountGroupUserAge } from '../../../../services/AccountGroupService';
import { getReportSettings, saveReportSettings } from '../../../../services/ReportService';
import { selectActiveAccountGroupId } from '../../../../state/activeItemSlice';
import CheckboxComponent from '../../../common/forms/CheckboxComponent/CheckboxComponent';
import FormActionButtonsComponent from '../../../common/forms/FormActionButtonsComponent';
import TextInputGroupComponent, { textInputTypes } from '../../../common/forms/TextInputGroupComponent';
import TextWithIconComponent from '../../../common/TextWithIconComponent';
import WaitingMessageComponent from '../../../common/WaitingMessageComponent';

const ReportCardSettings = ({ onToggleShowSettings }) => {
	const accountGroupId = useSelector(selectActiveAccountGroupId);
	const [reportCardSettings, setReportCardSettings] = useState(null);
	const [initialReportCardSettings, setInitialReportCardSettings] = useState(null);
	const [calculatedIncome, setCalculatedIncome] = useState(0);
	const [userAge, setUserAge] = useState();
	const [additionalAccountGroups, setAdditionalAccountGroups] = useState([]);

	const updateReportCardSettings = async (reportType, field, value) => {
		const newSettings = copy(reportCardSettings);
		if (reportType === 'netWorth') {
			if (field === 'customTarget') {
				value = parseInt(value);
			}
			if (field === 'otherAccountGroups') {
				const otherAccountGroups = newSettings.reportSettings.netWorth.otherAccountGroups || [];
				const index = otherAccountGroups.findIndex((id) => id === value.id);
				if (value.include) {
					if (index === -1) {
						otherAccountGroups.push(value.id);
					}
				} else {
					if (index !== -1) {
						otherAccountGroups.splice(index, 1);
					}
				}
				value = otherAccountGroups;
			}
		}
		newSettings.reportSettings[reportType][field] = value;
		setReportCardSettings(newSettings);
	};

	const saveReportCardSettings = async (e) => {
		e.preventDefault();
		setReportCardSettings(null);
		await saveReportSettings(reportCardSettings);
		setReportCardSettings(reportCardSettings);
		onToggleShowSettings();
	};

	useEffect(() => {
		getReportSettings(accountGroupId, reportTypes.reportCard).then((reportSettings) => {
			setInitialReportCardSettings(reportSettings);
		});
		getAccountGroup(accountGroupId).then((ag) => {
			setCalculatedIncome(implodeNumber(ag.explodedIncome));
		});
		getOldestAccountGroupUserAge(accountGroupId).then((age) => setUserAge(age));
		getAccountGroups().then((accountGroups) => setAdditionalAccountGroups(accountGroups.filter((ag) => ag._id !== accountGroupId)));
	}, [accountGroupId]);

	useEffect(() => {
		setReportCardSettings(initialReportCardSettings);
	}, [initialReportCardSettings]);

	useEffect(() => {
		if (reportCardSettings && calculatedIncome > 0 && userAge > -1) {
			const calculatedTarget = Math.round(((calculatedIncome * userAge) / 10) * 100) / 100;
			if (calculatedTarget !== reportCardSettings.reportSettings.netWorth.calculatedTarget) {
				const newSettings = copy(reportCardSettings);
				newSettings.reportSettings.netWorth.calculatedTarget = calculatedTarget;
				setReportCardSettings(newSettings);
			}
		}
	}, [reportCardSettings, calculatedIncome, userAge]);

	if (isNullOrUndefined(reportCardSettings)) {
		return <WaitingMessageComponent />;
	}

	return (
		<form name="report-card-net-worth-settings-form" onSubmit={saveReportCardSettings}>
			<div className="row">
				<div className="col-xs-12">
					<div className="h3">Net Worth</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xs-3">
					<CheckboxComponent
						iconPosition={TextWithIconComponent.iconPositions.left}
						isChecked={reportCardSettings.reportSettings.netWorth.targetType === netWorthReportTargetTypes.calculated}
						onClick={() => {
							updateReportCardSettings('netWorth', 'targetType', netWorthReportTargetTypes.calculated);
						}}>
						Use calculated target
					</CheckboxComponent>
				</div>
				<div className="col-xs-3 text-center">Annual income</div>
				<div className="col-xs-1 text-center">*</div>
				<div className="col-xs-3 text-center">Oldest Account Owner Age / 10</div>
				<div className="col-xs-2 text-center">Calculated Target</div>
			</div>
			<div className="row">
				<div className="col-xs-3"></div>
				<div className="col-xs-3 text-center">
					<NumericFormat value={calculatedIncome} thousandSeparator="," decimalScale={2} fixedDecimalScale={true} prefix="$" displayType="text" />
				</div>
				<div className="col-xs-1 text-center">*</div>
				<div className="col-xs-3 text-center">{userAge > -1 ? userAge / 10 : <div className="alert alert-danger">No account owners have entered birthdates.</div>}</div>
				<div className="col-xs-2 text-center">
					<NumericFormat
						value={reportCardSettings.reportSettings.netWorth.calculatedTarget}
						thousandSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix="$"
						displayType="text"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-xs-3">
					<CheckboxComponent
						iconPosition={TextWithIconComponent.iconPositions.left}
						isChecked={reportCardSettings.reportSettings.netWorth.targetType === netWorthReportTargetTypes.custom}
						onClick={() => {
							updateReportCardSettings('netWorth', 'targetType', netWorthReportTargetTypes.custom);
						}}>
						Use custom target
					</CheckboxComponent>
				</div>
				<div className="col-xs-2">
					<TextInputGroupComponent
						addonTextBefore="$"
						title="Net Worth Target"
						testId="report-card-net-worth-custom-target-value"
						type={textInputTypes.number}
						min={0}
						step={100}
						titleAsPlaceholder={true}
						value={reportCardSettings.reportSettings.netWorth.customTarget}
						onFieldChange={(e) => {
							updateReportCardSettings('netWorth', 'customTarget', e.target.value);
						}}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-xs-3">Include other account groups:</div>
				<div className="col-xs-9">
					{additionalAccountGroups.map((accountGroup) => (
						<span key={accountGroup._id} style={{ marginRight: 16 }}>
							<CheckboxComponent
								iconPosition={TextWithIconComponent.iconPositions.left}
								isChecked={reportCardSettings.reportSettings.netWorth.otherAccountGroups.findIndex((id) => id === accountGroup._id) !== -1}
								onClick={() => {
									updateReportCardSettings('netWorth', 'otherAccountGroups', {
										id: accountGroup._id,
										include: reportCardSettings.reportSettings.netWorth.otherAccountGroups.findIndex((id) => id === accountGroup._id) === -1,
									});
								}}>
								{accountGroup.name}
							</CheckboxComponent>
						</span>
					))}
				</div>
			</div>
			<div className="row">
				<div className="col-xs-12 text-right">
					<FormActionButtonsComponent
						formName="report-card-net-worth-settings-form"
						onCancel={(e) => {
							e.preventDefault();
							setReportCardSettings(initialReportCardSettings);
							onToggleShowSettings();
						}}
					/>
				</div>
			</div>
		</form>
	);
};

ReportCardSettings.propTypes = {
	onToggleShowSettings: PropTypes.func.isRequired,
};

export default ReportCardSettings;
