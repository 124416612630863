import { recurrencePeriods } from '@xenomite-packages/cash-flow-globals';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import DateInputGroupComponent from '../DateInputGroupComponent';
import SelectInputGroupComponent from '../SelectInputGroupComponent';
import TextInputGroupComponent, { textInputTypes } from '../TextInputGroupComponent';

const FORM_OBJECT_TYPE = 'recurrence';
const PERIOD_COUNT_MAX = {
	Months: 11,
	Weeks: 51,
	Years: 10,
};
const RECURRENCE_PERIOD_OPTIONS = [
	{
		label: recurrencePeriods.weeks,
		value: recurrencePeriods.weeks,
	},
	{
		label: recurrencePeriods.months,
		value: recurrencePeriods.months,
	},
	{
		label: recurrencePeriods.years,
		value: recurrencePeriods.years,
	},
];

class RecurrenceInputsComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			periodCountMax: 51,
		};
	}

	render() {
		return (
			<div className="row">
				<div className="col-xs-12 col-md-4">
					<TextInputGroupComponent
						title="Repeat every:*"
						type={textInputTypes.number}
						step={1}
						min={1}
						max={PERIOD_COUNT_MAX[this.props.recurrence.period]}
						value={this.props.recurrence.periodCount}
						isInvalid={!isNil(this.props.formErrors.periodCount)}
						onFieldChange={(event) => {
							this.props.onFieldChange(event, 'periodCount', FORM_OBJECT_TYPE);
						}}
						testId="recurrence-form-period-count"
					/>
					<SelectInputGroupComponent
						title=""
						value={this.props.recurrence.period}
						isInvalid={!isNil(this.props.formErrors.period)}
						options={RECURRENCE_PERIOD_OPTIONS}
						onFieldChange={(event) => {
							this.props.onFieldChange(event, 'period', FORM_OBJECT_TYPE);
						}}
						testId="recurrence-form-period"
					/>
				</div>
				<div className="col-xs-12 col-md-4">
					<DateInputGroupComponent
						title="Recording Date:*"
						value={this.props.recurrence.recordingDate}
						isInvalid={!isNil(this.props.formErrors.recordingDate)}
						onFieldChange={(event) => {
							this.props.onFieldChange(event, 'recordingDate', FORM_OBJECT_TYPE);
						}}
					/>
				</div>
			</div>
		);
	}
}

RecurrenceInputsComponent.propTypes = {
	formErrors: PropTypes.object.isRequired,
	onFieldChange: PropTypes.func.isRequired,
	recurrence: PropTypes.object.isRequired,
};

export default RecurrenceInputsComponent;
