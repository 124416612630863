import { paulBunyan } from '../Globals';
import { baseAddWatcher, baseFireWatchers, baseRemoveWatcher } from './WatcherService';

export const messageType = {
	error: 2,
	success: 0,
	warning: 1,
};

const watcherGroupKey = 'feedbackMessage';

let errorMsg = '';
let successMsg = '';
let warningMsg = '';

export function clearMessages() {
	errorMsg = '';
	successMsg = '';
	warningMsg = '';

	baseFireWatchers(watcherGroupKey);
}

export function getMessages() {
	return {
		errorMessage: errorMsg,
		successMessage: successMsg,
		warningMessage: warningMsg,
	};
}

export function setMessages(messages, type, prefix) {
	let message = '';

	if (typeof messages === 'string') {
		message = messages;
	} else {
		if (prefix) {
			message = prefix + '<ul>';
			if (Array.isArray(messages)) {
				messages.forEach((msg) => {
					message += `<li>${msg}</li>`;
				});
			} else {
				for (const key in messages) {
					if (Object.prototype.hasOwnProperty.call(messages, key)) {
						message += `<li>${messages[key]}</li>`;
					}
				}
			}
			message += '</ul>';
		} else {
			if (Array.isArray(messages)) {
				message = messages.join('<br/>');
			} else {
				for (const key in messages) {
					if (Object.prototype.hasOwnProperty.call(messages, key)) {
						message += `${messages[key]}<br/>`;
					}
				}
			}
		}
	}

	switch (type) {
		case messageType.error:
			errorMsg = message;
			break;
		case messageType.success:
			successMsg = message;
			break;
		case messageType.warning:
			warningMsg = message;
			break;
		default:
			paulBunyan.logError('Message type is required when setting a feedback message.');
	}

	baseFireWatchers(watcherGroupKey);
}

export function addWatcher(watcherFunction) {
	baseAddWatcher(watcherGroupKey, watcherFunction);
}

export function removeWatcher(key) {
	baseRemoveWatcher(watcherGroupKey, key);
}
