import PropTyeps from 'prop-types';

import TextWithIconComponent from '../common/TextWithIconComponent';
import { useState } from 'react';

let searchInput;

const SearchComponent = ({ onSearch }) => {
	const [isSearching, setIsSearching] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const toggleSearch = (event) => {
		event.preventDefault();
		const searching = !isSearching;
		let value = searchValue;

		if (searching) {
			setTimeout(() => {
				searchInput.focus();
			});
		} else {
			value = '';
			setTimeout(() => {
				onSearch(value);
			});
		}

		setIsSearching(searching);
		setSearchValue(value);
	};

	const searchChanged = (event) => {
		setSearchValue(event.target.value);
	};

	const submitSearch = (event) => {
		event.preventDefault();
		onSearch(searchValue);
	};

	if (!isSearching) {
		return (
			<a href="/" onClick={toggleSearch}>
				<TextWithIconComponent iconName="search-plus" />
			</a>
		);
	}

	return (
		<form onSubmit={submitSearch}>
			<div className="input-group">
				<div className="input-group-addon" onClick={toggleSearch}>
					<TextWithIconComponent iconName="search-minus" />
				</div>
				<input
					type="text"
					className="form-control"
					placeholder="Search"
					value={searchValue}
					onChange={searchChanged}
					ref={(input) => (searchInput = input)}
				/>
				<div className="input-group-addon" onClick={submitSearch}>
					Go
				</div>
			</div>
		</form>
	);
};

SearchComponent.propTypes = {
	onSearch: PropTyeps.func.isRequired,
};

export default SearchComponent;
