import React from 'react';

import TransactionStats from '../TransactionStats';
import UserStats from '../UserStats';

const AdminDashboard = () => {
	return (
		<div className="row">
			<div className="col-xs-12 col-md-6">
				<div className="panel panel-primary">
					<div className="panel-heading">Users</div>
					<div className="panel-body">
						<UserStats />
					</div>
				</div>
			</div>
			<div className="col-xs-12 col-md-6">
				<div className="panel panel-primary">
					<div className="panel-heading">Transactions</div>
					<div className="panel-body">
						<TransactionStats />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminDashboard;
