import { isEmpty } from 'lodash';

import store from '../state/store';
import { selectAppUser } from '../state/userSlice';
import { retrieveFromServer, updateOnServer } from './AwCrudService';
import { baseAddWatcher, baseFireWatchers, baseRemoveWatcher } from './WatcherService';

const WATCHER_GROUP_KEY = 'notifications';

let _notifications = [];

let _hasViewed = false;

export const setHasViewed = (hasViewed) => {
	_hasViewed = hasViewed;
};

export const getHasViewed = () => {
	return _hasViewed;
};

export function getNotifications() {
	return _notifications;
}

export function dispositionNotification(uri, data) {
	return updateOnServer(uri, data).then((notifications) => {
		setNotifications(notifications);

		return _notifications;
	});
}

export function setNotifications(notifications) {
	_notifications = notifications;
	baseFireWatchers(WATCHER_GROUP_KEY);
}

export function retrieveNotifications() {
	const user = selectAppUser(store.getState());
	if (!isEmpty(user)) {
		return retrieveFromServer(`/notification/${user.emailAddress}`).then((notifications) => {
			setNotifications(notifications);

			return _notifications;
		});
	}
}

export function addWatcher(watcherFunction) {
	return baseAddWatcher(WATCHER_GROUP_KEY, watcherFunction);
}

export function removeWatcher(key) {
	baseRemoveWatcher(WATCHER_GROUP_KEY, key);
}

// addWatcher(retrieveNotifications);
