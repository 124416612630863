import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { addWatcher, clearMessages, getMessages, messageType, removeWatcher } from '../../services/FeedbackMessageService';
import MessageDivComponent from './MessageDivComponent';

class FeedbackMessageComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: '',
			successMessage: '',
			warningMessage: ''
		};

		this.updateMessages = updateMessages.bind(this);
	}

	componentDidMount() {
		this.messageWatcher = addWatcher(this.updateMessages);
	}

	componentDidUpdate(previousProps) {
		if (!isEmpty(previousProps.location) && isEmpty(this.props)) {
			clearMessages();
		}
	}

	componentWillUnmount() {
		removeWatcher(this.messageWatcher);
	}

	render() {
		return (
			<div id='messageContainer'>
				<MessageDivComponent type={messageType.error} message={this.state.errorMessage} />
				<MessageDivComponent type={messageType.warning} message={this.state.warningMessage} />
				<MessageDivComponent type={messageType.success} message={this.state.successMessage} />
			</div>
		);
	}
}

function updateMessages() {
	const messages = getMessages();

	this.setState({
		errorMessage: messages.errorMessage,
		successMessage: messages.successMessage,
		warningMessage: messages.warningMessage
	});
}

FeedbackMessageComponent.propTypes = {
	location: PropTypes.object
};

export default FeedbackMessageComponent;
