import React from 'react';
import PropTypes from 'prop-types';

class DateDisplayComponent extends React.Component {
	render() {
		const date = new Date(this.props.dateString);
		return <span>{date.toLocaleDateString(navigator.language, { timeZone: 'UTC' })}</span>;
	}
}

DateDisplayComponent.propTypes = {
	dateString: PropTypes.string.isRequired
};

export default DateDisplayComponent;
