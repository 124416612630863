import numbro from 'numbro';

export function createNestedOptionArray(inputArray, nestedArrayField, labelField, valueField) {
	const options = [];
	inputArray.forEach((item) => {
		item[nestedArrayField].forEach((nestedItem) => {
			options.push({
				label: `${item[labelField]} / ${nestedItem[labelField]}`,
				value: nestedItem[valueField]
			});
		});
	});
	return options;
}

export function createOptionArray(inputArray, labelField, valueField) {
	return inputArray.map((item) => ({ label: item[labelField], value: item[valueField] }));
}

export function createOptionArrayFromObject(inputObject) {
	const optionArray = [];
	for (const [, value] of Object.entries(inputObject)) {
		optionArray.push({ label: value, value: value });
	}
	return optionArray;
}

export function formatMoney(amount) {
	return numbro(amount).formatCurrency({ mantissa: 2 });
}

export function formatPercentage(amount, mantissa) {
	return numbro(amount).format({ mantissa, output: 'percent' });
}

export function isoDatePartToLocalDatePart(isoDatePart) {
	const splitIsoDate = isoDatePart.split('-');
	const splitLocalDate = [splitIsoDate[1], splitIsoDate[2], splitIsoDate[0]];
	return splitLocalDate.join('/');
}
