import { isNullOrEmptyObject, isNullOrUndefined } from '@chris-crap-packages/utilities';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import { getNetWorth, lookupReportCardAlertClass } from '../../../../services/ReportService';
import { selectActiveAccountGroupId } from '../../../../state/activeItemSlice';
import { selectNetWorth } from '../../../../state/reportsSlice';
import TextWithIconComponent from '../../../common/TextWithIconComponent';
import WaitingMessageComponent from '../../../common/WaitingMessageComponent';
import NetWorthOverTime from '../NetWorthOverTime';

const NetWorth = () => {
	const netWorth = useSelector(selectNetWorth);
	const accountGroupId = useSelector(selectActiveAccountGroupId);
	const [showDetails, setShowDetails] = useState(false);
	const [alertClass, setAlertClass] = useState('');

	const toggleShowDetails = () => {
		setShowDetails(!showDetails);
	};

	useEffect(() => {
		if (!isNullOrUndefined(accountGroupId)) {
			getNetWorth(accountGroupId);
		}
	}, [accountGroupId]);

	useEffect(() => {
		if (!isNullOrEmptyObject(netWorth)) {
			setAlertClass(lookupReportCardAlertClass(netWorth.total / netWorth.target));
		}
	}, [netWorth]);

	return (
		<WaitingMessageComponent message="Calculating Net Worth..." isLoading={isNullOrUndefined(netWorth)}>
			<div className={`alert ${alertClass}`}>
				<div className="row clickable" onClick={toggleShowDetails}>
					<div className="col-xs-3">
						<TextWithIconComponent iconName={showDetails ? 'minus-square' : 'plus-square'} /> Net Worth
					</div>
					<div className="col-xs-3">
						{!showDetails && <NumericFormat value={netWorth?.total} thousandSeparator="," decimalScale={0} fixedDecimalScale={true} prefix="$" displayType="text" />}
					</div>
					<div className="col-xs-3">
						{'(Target: '}
						<NumericFormat value={netWorth?.target} thousandSeparator="," decimalScale={0} fixedDecimalScale={true} prefix="$" displayType="text" />
						{')'}
					</div>
				</div>
				{showDetails && (
					<>
						<div className="row">
							<div className="col-xs-3">Assets:</div>
							<div className="col-xs-3">
								<NumericFormat value={netWorth?.assets} thousandSeparator="," decimalScale={0} fixedDecimalScale={true} prefix="$" displayType="text" />
							</div>
						</div>
						<div className="row">
							<div className="col-xs-3">Liabilities:</div>
							<div className="col-xs-3">
								<NumericFormat value={netWorth?.liabilities} thousandSeparator="," decimalScale={0} fixedDecimalScale={true} prefix="$" displayType="text" />
							</div>
						</div>
						{netWorth?.otherAccountGroups.map((ag) => (
							<div className="row" key={ag.name}>
								<div className="col-xs-3">{ag.name}:</div>
								<div className="col-xs-3">
									<NumericFormat value={ag.amount} thousandSeparator="," decimalScale={0} fixedDecimalScale={true} prefix="$" displayType="text" />
								</div>
							</div>
						))}
						<div className="row">
							<div className="col-xs-3">
								<strong>Total:</strong>
							</div>
							<div className="col-xs-3">
								<strong>
									<NumericFormat value={netWorth?.total} thousandSeparator="," decimalScale={0} fixedDecimalScale={true} prefix="$" displayType="text" />
								</strong>
							</div>
						</div>
						<div className="row">
							<div className="col-xs-12">
								<NetWorthOverTime />
							</div>
						</div>
					</>
				)}
			</div>
		</WaitingMessageComponent>
	);
};

export default NetWorth;
