import { copy } from '@chris-crap-packages/utilities';

import { netWorthUpdated, reportCardWeightsUpdated } from '../state/reportsSlice';
import store from '../state/store';
import { retrieveFromServer, updateOnServer } from './AwCrudService';

const _reportSettings = [];

export function getReportSettings(accountGroupId, reportType) {
	return new Promise((resolve) => {
		const settings = _reportSettings.find((rs) => rs.reportType === reportType && rs.accountGroupId === accountGroupId);
		if (settings) {
			resolve(copy(settings));
		} else {
			retrieveFromServer(`/report/settings/${accountGroupId}/${reportType}`).then((serverSettings) => {
				_reportSettings.push(serverSettings);
				resolve(copy(serverSettings));
			});
		}
	});
}

export function saveReportSettings(settings) {
	const reportSettings = copy(settings);
	return new Promise((resolve) => {
		updateOnServer(`/report/settings/${reportSettings.accountGroupId}/${reportSettings.reportType}`, reportSettings).then(() => {
			const settingsIndex = _reportSettings.findIndex((rs) => rs.reportType === reportSettings.reportType && rs.accountGroupId === reportSettings.accountGroupId);
			if (settingsIndex === -1) {
				_reportSettings.push(reportSettings);
			} else {
				_reportSettings[settingsIndex] = reportSettings;
			}
			resolve(true);
		});
	});
}

export const getReportCardWeights = async () => {
	const weights = await retrieveFromServer('/report-card-weights');
	store.dispatch(reportCardWeightsUpdated(weights));
};

export const saveReportCardWeights = async (weights) => {
	const savedWeights = await updateOnServer('/report-card-weights', weights);
	store.dispatch(reportCardWeightsUpdated(savedWeights));
	return Promise.resolve(true);
};

export const getNetWorth = async (accountGroupId) => {
	const netWorth = await retrieveFromServer(`/report/net-worth/${accountGroupId}`);
	const netWorthObj = { accountGroupId, netWorth };
	store.dispatch(netWorthUpdated(netWorthObj));
};

export const getNetWorthSnapshots = async (accountGroupId, count, includeEmpty) => {
	const dollarFields = ['assets', 'liabilities', 'target', 'total'];
	return retrieveFromServer(`/report/net-worth-snapshots/${accountGroupId}/${count}/${includeEmpty}`).then((snapshots) => {
		snapshots.forEach((s) => {
			dollarFields.forEach((f) => (s.netWorth[f] = s.netWorth[f].toFixed()));
		});

		return snapshots;
	});
};

export const lookupReportCardAlertClass = (percentageGrade) => {
	if (percentageGrade >= 0.87) {
		return 'alert-success';
	}

	if (percentageGrade >= 0.7) {
		return 'alert-warning';
	}

	return 'alert-danger';
};
