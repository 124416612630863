import PropTypes from 'prop-types';
import React from 'react';

import TextWithIconComponent from '../../TextWithIconComponent';

class ButtonComponent extends React.Component {
	static get buttonTypes() {
		return {
			button: 'button',
			cancel: 'cancel',
			submit: 'submit'
		};
	}

	static get iconPositions() {
		return TextWithIconComponent.iconPositions;
	}

	render() {
		const buttonType = this.props.type || 'button';

		return (
			<button type={buttonType} className={`btn btn-${this.props.colorClass}`} onClick={this.props.onClick} data-test-id={this.props.testId}>
				<TextWithIconComponent iconPosition={this.props.iconPosition} iconName={this.props.iconName} iconStyle={this.props.iconStyle}>
					{this.props.text}
				</TextWithIconComponent>
			</button>
		);
	}
}

ButtonComponent.propTypes = {
	colorClass: PropTypes.string.isRequired,
	iconName: PropTypes.string.isRequired,
	iconPosition: PropTypes.number,
	iconStyle: PropTypes.string,
	onClick: PropTypes.func,
	testId: PropTypes.string,
	text: PropTypes.string.isRequired,
	type: PropTypes.string
};

export default ButtonComponent;
