import React, { useEffect, useState } from 'react';

import { retrieveTransactionStats } from '../../../services/TransactionService';
import WaitingMessageComponent from '../../common/WaitingMessageComponent';

const TransactionStats = () => {
	const [txStats, setTxStats] = useState();

	useEffect(() => {
		retrieveTransactionStats().then((stats) => {
			setTxStats(stats);
		});
	}, []);

	if (!txStats) {
		return <WaitingMessageComponent />;
	}

	return (
		<table className="table table-striped">
			<tbody>
				<tr>
					<td>Less than 1 year:</td>
					<td>{txStats.oneYear}</td>
				</tr>
				<tr>
					<td>Between 1 and 2 years:</td>
					<td>{txStats.twoYears}</td>
				</tr>
				<tr>
					<td>Between 2 and 3 years:</td>
					<td>{txStats.threeYears}</td>
				</tr>
				<tr>
					<td>Between 3 and 4 years:</td>
					<td>{txStats.fourYears}</td>
				</tr>
				<tr>
					<td>Between 4 and 5 years:</td>
					<td>{txStats.fiveYears}</td>
				</tr>
				<tr>
					<td>Between 5 and 6 years:</td>
					<td>{txStats.sixYears}</td>
				</tr>
				<tr>
					<td>Between 6 and 7 years:</td>
					<td>{txStats.sevenYears}</td>
				</tr>
				<tr>
					<td>Greater than 7 years:</td>
					<td>{txStats.reallyOld}</td>
				</tr>
			</tbody>
		</table>
	);
};

export default TransactionStats;
