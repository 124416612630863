import PropTypes from 'prop-types';

import InputGroupAddonComponent from '../InputGroupAddonComponent';

export const textInputTypes = {
	email: 'email',
	number: 'number',
	password: 'password',
	text: 'text',
};

const TextInputGroupComponent = ({ addonTextAfter, addonTextBefore, autoFocus, disabled, domId, isInvalid, max, min, onBlur, onFieldChange, onKeyUp, step, testId, title, titleAsPlaceholder, type, value }) => {
	const invalidClass = isInvalid ? 'invalid' : '';

	if (titleAsPlaceholder) {
		return (
			<div className="form-group">
				<InputGroupAddonComponent addonTextBefore={addonTextBefore} addonTextAfter={addonTextAfter}>
					<input
						type={type}
						id={domId}
						autoFocus={autoFocus}
						value={value}
						className={`form-control ${invalidClass}`}
						onChange={(event) => onFieldChange(event)}
						placeholder={title}
						step={step}
						min={min}
						max={max}
						onKeyUp={onKeyUp}
						data-test-id={testId}
						onBlur={onBlur}
						disabled={disabled}
					/>
				</InputGroupAddonComponent>
			</div>
		);
	}

	return (
		<div className="form-group">
			<label>{title}</label>
			<InputGroupAddonComponent addonTextBefore={addonTextBefore} addonTextAfter={addonTextAfter}>
				<input type={type} id={domId} autoFocus={autoFocus} value={value} className={`form-control ${invalidClass}`} onChange={(event) => onFieldChange(event)} step={step} min={min} max={max} onKeyUp={onKeyUp} data-test-id={testId} onBlur={onBlur} disabled={disabled} />
			</InputGroupAddonComponent>
		</div>
	);
};

TextInputGroupComponent.propTypes = {
	addonTextAfter: PropTypes.string,
	addonTextBefore: PropTypes.string,
	autoFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	domId: PropTypes.string,
	isInvalid: PropTypes.bool,
	max: PropTypes.number,
	min: PropTypes.number,
	onBlur: PropTypes.func,
	onFieldChange: PropTypes.func.isRequired,
	onKeyUp: PropTypes.func,
	step: PropTypes.number,
	testId: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	titleAsPlaceholder: PropTypes.bool,
	type: PropTypes.string.isRequired,
	value: PropTypes.any,
};

export default TextInputGroupComponent;
