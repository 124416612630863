import { getDomain, getEnvironment } from '@chris-crap-packages/browser-utilities';
import { Lumberjack } from '@chris-crap-packages/logger';
import { getBaseUri } from '@chris-crap-packages/utilities';

export const appName = 'cash-flow-tool';

export const apiUri = getBaseUri(`${appName}-api`, getEnvironment(), getDomain());

export const tattleTellUri = getBaseUri('tattle-tell-api', getEnvironment(), getDomain());

export const paulBunyan = new Lumberjack(tattleTellUri, appName);

export const accountSectionHeaderTypes = {
	cleared: 'Cleared',
	outstanding: 'Outstanding',
};

export const componentReadyState = {
	ready: 0,
	waiting: 1,
};

export const pagingDirections = {
	backward: 'backward',
	forward: 'forward',
};

export let orgCode = '';
export const setOrgCode = (code) => {
	orgCode = code;
};
