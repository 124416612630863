import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { addNavWatcher, addSubNavWatcher, getCurrentPageDetails, getNav, getSubNav, removeNavWatcher, removeSubNavWatcher, updateNav } from '../../../services/NavigationService';
import SubNavComponent from './SubNavComponent';

const watcherKeys = {
	navigation: null,
	subNavigation: null,
};

const NavigationContainer = () => {
	const [nav, setNav] = useState(getNav());
	const [subNav, setSubNav] = useState(getSubNav());
	const [showSubNav, setShowSubNav] = useState(false);
	const [currentPageDetails, setCurrentPageDetails] = useState(getCurrentPageDetails());

	const updateNavigation = () => {
		setCurrentPageDetails(getCurrentPageDetails());
		setNav(getNav());
		setShowSubNav(false);
	};

	const updateSubNavigation = () => {
		setCurrentPageDetails(getCurrentPageDetails());
		setSubNav(getSubNav());
		setShowSubNav(false);
	};

	const toggleShowSubNav = () => {
		setShowSubNav(!showSubNav);
	};

	useEffect(() => {
		watcherKeys.navigation = addNavWatcher(updateNavigation);
		watcherKeys.subNavigation = addSubNavWatcher(updateSubNavigation);
		updateNav();

		return () => {
			removeNavWatcher(watcherKeys.navigation);
			removeSubNavWatcher(watcherKeys.subNavigation);
		};
	}, []);

	if (nav.length === 0 && subNav.length === 0) {
		return null;
	}

	return (
		<div>
			<nav className="main-nav">
				{nav.map((navItem) => {
					const cssClass = currentPageDetails.selectedNavId === navItem.id ? 'active' : '';
					return (
						<Link to={navItem.link} key={navItem.link} className={cssClass} data-test-id={navItem.testId}>
							{navItem.name}
						</Link>
					);
				})}
			</nav>
			<SubNavComponent activeItemId={currentPageDetails.selectedSubNavId} subNavList={subNav} showSubNav={showSubNav} toggleClick={toggleShowSubNav} />
		</div>
	);
};

export default NavigationContainer;
